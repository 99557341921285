// index.js - WEB
import React from "react";
import ReactDOM from "react-dom";
import App from "./App/App";
import { BrowserRouter as Router } from "react-router-dom";
import registerServiceWorker from "./App/registerServiceWorker";
import configureStore from './App/configureStore';
import { Provider } from 'react-redux';

import * as utils from './Common/utils/utils';

const store = configureStore();

// if (utils.isDev) {
//   sessionStorage.setItem("accountId", process.env.REACT_APP_ACCOUNT_ID);
//   sessionStorage.setItem("sessionId", process.env.REACT_APP_SESSION_ID);
// sessionStorage.setItem("accountId", "30240240");
// sessionStorage.setItem("sessionId", "42e3b1b9-d501-4e82-bfa4-38fdc89f1fb1");
// }

ReactDOM.render(
  <Provider store={store}>
    <Router>
      <App />
    </Router>
  </Provider>,
  document.getElementById("root")
);
registerServiceWorker();
