import React from 'react';
import { StyleSheet, Text, View, Image, Button, TouchableOpacity, Linking, ScrollView, StatusBar } from 'react-native';
import Background from '../../Common/components/Background';
import PlayerProfilePopup from '../components/PlayerProfilePopup';
import HeaderWithBack from '../../Common/components/HeaderWithBack';
import { connect } from 'react-redux';
import { getUserFullProfile } from '../actions/players';
import TabBar from '../../Common/components/TabBar';
import TopBar from '../../Common/components/TopBar';
import { getPlayerPointsColor } from "../../Common/utils/utils.js";
import { getDomainOperator } from '../../Common/utils/domainMapper';

class PlayerProfile extends React.Component {
  state = {
    popupShown: false,
    selectedRoundId: null
  }

  static navigationOptions = {
    title: 'Профил Играч',
  };

  componentDidMount() {
    this.props.getUserFullProfile(this.props.navigation.getParam('playerId'));
  }

  getTextColor = (points) => {
    if (points >= 4 && points <= 5) {
      return { color: design.textColorPrimary }
    }
    return {}
  }

  showPopup = roundId => {
    this.setState({
      popupShown: true,
      selectedRoundId: roundId
    });
  }

  hidePopup = () => {
    this.setState({
      popupShown: false,
      selectedRoundId: null
    });
  }

  render() {
    if (!this.props.playerProfile) return null;
    return (
      <ScrollView style={styles.scrollView} contentContainerStyle={styles.container}>
        <Background />
        <TopBar navigation={this.props.navigation} />
        <HeaderWithBack title={"Профил на играч"} navigation={this.props.navigation} />
        <PlayerProfilePopup isVisible={this.state.popupShown}
          onDismiss={this.hidePopup}
          playerId={this.props.playerProfile.playerId}
          roundId={this.state.selectedRoundId} />

        <View style={styles.header}>
          <Text style={styles.points}>{this.props.playerProfile.points}</Text>
          <Text style={styles.pointsText}>точки</Text>
        </View>

        <View style={styles.playerInfo}>
          <Image source={{ uri: this.props.playerProfile.image }} style={styles.playerImage} />
          <View style={{ justifyContent: 'space-between' }}>
            <Text style={styles.name}>{this.props.playerProfile.name}</Text>
            <Text style={styles.info}>{this.props.playerProfile.position}/{this.props.playerProfile.team}</Text>
            <Text style={styles.info}>Цена: <Text style={styles.bold}>{this.props.playerProfile.price}</Text></Text>
            <Text style={styles.info}>Избран от: <Text style={styles.bold}>{this.props.playerProfile.choosenPercent} %</Text></Text>
            <Text style={styles.info}>Форма: <Text style={styles.bold}>{this.props.playerProfile.form}</Text></Text>
            {
              //   <ProgressBar progress={this.props.playerProfile.form / 100}
              //  width={200} 
              //  color='#EA3223'
              //  unfilledColor="#CDCDCD" borderColor="#6F727E" />
            }
          </View>
        </View>

        <View style={styles.roundsContainer}>
          {this.props.playerProfile.rounds.map((round, i) => (
            <TouchableOpacity style={styles.roundContainer}
              key={"" + (round.id * i)}
              onPress={() => this.showPopup(round.id)}>
              <View style={styles.roundNumber}>
                <Text style={styles.roundIndex}>{round.roundNumber}</Text>
                <Text>кръг</Text>
              </View>
              {round.playerPoints !== undefined ?
                <React.Fragment>
                  <View style={styles.score}>
                    <Text style={styles.bold}>{round.hostName}</Text>
                    <Image source={{ uri: round.hostLogo }} style={styles.icon} />
                    <Text style={styles.bold}>{round.hostScore} : {round.guestScore}</Text>
                    <Image source={{ uri: round.guestLogo }} style={styles.icon} />
                    <Text style={styles.bold}>{round.guestName}</Text>
                  </View>
                  <View style={[styles.playerPoints, getPlayerPointsColor(round.playerPoints, true)]}>
                    <Text style={[styles.pointsText, this.getTextColor(round.playerPoints)]}>{round.playerPoints}</Text>
                  </View>
                </React.Fragment>
                :
                <React.Fragment>
                  <View style={styles.score}>
                    <Text style={styles.bold}>{round.hostName}</Text>
                    <Image source={{ uri: round.hostLogo }} style={styles.icon} />
                    <Text style={styles.bold}>-:-</Text>
                    <Image source={{ uri: round.guestLogo }} style={styles.icon} />
                    <Text style={styles.bold}>{round.guestName}</Text>
                  </View>
                  <View style={styles.playerPoints}>
                    <Text style={[styles.pointsText, { color: design.textColorPrimary }]}>{round.date}</Text>
                  </View>
                </React.Fragment>
              }
            </TouchableOpacity>
          ))}
        </View>
        <TabBar navigation={this.props.navigation} />
      </ScrollView>
    );
  }
}

const mapDispatchToProps = (dispatch, ownProps) => {
  return {
    getUserFullProfile: playerId => dispatch(getUserFullProfile(playerId)),
  }
}

const mapStateToProps = (state, ownProps) => {
  return {
    playerProfile: state.teamPlayerInfo.playerFullInfo,
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(PlayerProfile);

const operator = getDomainOperator();

const design = operator.design;

const styles = StyleSheet.create({
  scrollView: {
    flex: 1,
    width: '100%'
  },
  container: {
    width: '100%',
    alignItems: 'center',
    justifyContent: 'center'
  },
  header: {
    backgroundColor: '#090717',
    justifyContent: 'center',
    alignItems: 'center',
    borderRadius: 10,
    height: 80,
    width: '90%',
    marginTop: 20
  },
  points: {
    color: '#72E86C',
    fontWeight: 'bold',
    fontSize: 27
  },
  pointsText: {
    fontSize: 19,
    color: 'white'
  },
  playerInfo: {
    width: '100%',
    paddingHorizontal: 20,
    flexDirection: 'row',
    marginVertical: 50,
  },
  playerImage: {
    height: 180,
    width: 130,
    marginRight: 20,
    borderRadius: 20,
    borderWidth: 2,
    borderColor: '#828282'
  },
  name: {
    color: 'white',
    fontSize: 20,
    fontWeight: 'bold'
  },
  info: {
    color: 'white',
    fontSize: 15
  },
  roundIndex: {
    fontWeight: 'bold',
    fontSize: 17
  },
  bold: {
    fontWeight: 'bold',
    fontSize: 15
  },
  roundsContainer: {
    justifyContent: 'center',
    alignItems: 'center',
    width: '100%'
  },
  roundContainer: {
    flexDirection: 'row',
    borderRadius: 10,
    width: '90%',
    marginVertical: 7
  },
  roundNumber: {
    flex: 1,
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: '#EEEEEE',
    borderTopLeftRadius: 10,
    borderBottomLeftRadius: 10,
    paddingVertical: 7
  },
  score: {
    flex: 5,
    flexDirection: 'row',
    justifyContent: 'center',
    backgroundColor: 'white',
    alignItems: 'center'
  },
  playerPoints: {
    flex: 1,
    borderTopRightRadius: 10,
    borderBottomRightRadius: 10,
    alignItems: 'center',
    justifyContent: 'center',
    backgroundColor: 'white'
  },
  icon: {
    width: 20,
    height: 20,
    marginHorizontal: 10
  },
  pointsText: {
    color: 'white',
    fontWeight: 'bold',
    fontSize: 17,
    textAlign: 'center'
  }
});
