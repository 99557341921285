import React from 'react'
import { View, Text, StyleSheet, Image, TouchableOpacity, ScrollView, ActivityIndicator, Linking } from "react-native";
import { createStyles, maxWidth } from 'react-native-media-queries';
import * as translations from "../../Common/utils/translations"

export const PlayerInfoKeyStats = props => {
    const { player } = props;
    return (<View style={[styles.statsColumn, { width: "100%" }]}>
        <View style={styles.tableRow}>
            <View style={styles.bigColumn}>
                <Text style={styles.tableHeaderText}>{translations.tKey("str_player_info_general_stats")}</Text>
            </View>
            <View style={styles.smallColumn}>
                <Text style={styles.tableHeaderText}>{translations.tKey("str_player_info_total")}</Text>
            </View>
            <View style={styles.smallColumn}>
                <Text style={styles.tableHeaderText}>{translations.tKey("str_player_info_average")}</Text>
            </View>
        </View>
        {player.keyStats.map(stat => {
            return <View style={styles.tableRow}>
                <View style={styles.bigColumn}>
                    <Text>{stat.name}</Text>
                </View>
                <View style={styles.smallColumn}>
                    <Text>{stat.total == 0 ? "-" : stat.total}</Text>
                </View>
                <View style={styles.smallColumn}>
                    <Text>{stat.average == 0 ? "-" : stat.average.toFixed(2)}</Text>
                </View>
            </View>
        })}
    </View>)
}

const base = {
    statsColumn: {
        display: "flex",
        alignItems: 'center',
        justifyContent: 'center',
    },
    tableRow: {
        display: "flex",
        flexDirection: "row",
        alignItems: 'center',
        justifyContent: "center",
        marginVertical: 10,
        width: "90%"
    },
    bigColumn: {
        width: "50%",
        display: 'flex',
        alignItems: "flex-start"
    },
    tableHeaderText: {
        fontSize: 16,
        fontWeight: "bold"
    },
    smallColumn: {
        width: "25%",
        display: 'flex',
        alignItems: "flex-start"
    },
}

const styles = createStyles(
    base,
)