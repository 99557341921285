import React from 'react';
import { StyleSheet, Image, Text, View } from 'react-native';
import notLoggedBgImage from '../../assets/login-register-bg.png';
import loggedBgImage from '../../assets/pages-bg.png';
import livescoreBgImage from '../../assets/livescore-bg.png';
import { getDomainOperator} from '../utils/domainMapper';

const operator = getDomainOperator();

const background = (props) => {
  let image = loggedBgImage;
  if (props.notLogged) image = notLoggedBgImage;
  if (props.loading) {
    return (
      <View style={[styles.container, styles.loadingContainer]}>
        <Image style={styles.loading} source={operator.loading} />
      </View>
    )
  }
  if (props.livescore) image = livescoreBgImage;
  return (<View style={styles.container}>
    <Image style={[styles.image, props.style]} source={image} />
  </View>);

}


export default background;

const design = operator.design;

const styles = StyleSheet.create({
  container: {
    flex: 1,
    zIndex: 0,
    top: 0,
    left: 0,
    position: 'absolute',
    width: '100%',
    maxWidth: 700,
    height: '100%',
    backgroundColor: 'black'
  },
  loadingContainer: {
    display: "flex",
    alignItems: 'center',
    justifyContent: "center",
    backgroundColor: design.primaryColor,
  },
  loading: {
    position: "fixed",
    top: '35%',
    width: "300px",
    height: "20%",
    resizeMode: 'contain',
  },
  loadingText: {
    position: 'fixed',
    top: '55%',
    fontSize: '32px',
    color: 'white',
  },
  image: {
    width: '100%',
    minWidth: '100%',
    resizeMode: 'stretch',
    height: '100%',
    maxHeight: 1200
  }
});