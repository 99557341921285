const url = window.location.hostname;
const domains = url.split('.');

let URL = "https://";

let index;
if (domains[1] === 'local') {
    index = 1;
} else {
    index = 0;
}

URL += domains[0] == 'demo' ? 'demoapi' : 'api';
URL += '.' + domains[index + 1] + '.com';

export const BASE_URL = URL;