import { SET_CHAMPIONSHIP_INFO } from "./actionTypes";
import { BASE_URL } from '../../Common/config/config';
import { authGetToken } from '../../Common/actions/token';

export const getChampionshipInfo = () => {
  return dispatch => {
    dispatch(authGetToken())
    .catch(err => {
        console.log('No auth token exists!');        
    })
    .then(token => {
        return fetch(BASE_URL + '/championships', {
            credentials: 'include',
            headers: {
                "Content-Type": "application/json; charset=utf-8",
                "Authorization": token
            }
        });
    })
    .catch(err => console.log(err))
    .then(res => res.json())
    .then(res => dispatch(setChampionshipInfo(res.data)));
  };
}


export const setChampionshipInfo = info => {
  return {
    type: SET_CHAMPIONSHIP_INFO,
    info
  };
};