import React from "react";
import {
  StyleSheet,
  TouchableOpacity,
  Text,
  View,
  Image
} from "react-native";
import EStyleSheet from 'react-native-extended-stylesheet';
import PlayerView from '../../Common/components/PlayerView';
import PopupDialog from '../../Common/components/PopupDialog';
import setCaptainIcon from '../../assets/set-captain.svg.png';
import setViceCaptainIcon from '../../assets/set-vice-captain.svg.png';

import { connect } from 'react-redux';
import { getPlayerInfoWithChanges, setCaptain, setViceCaptain } from '../actions/players';

import { getPlayerPointsColor } from "../../Common/utils/utils.js";

class PlayerPopup extends React.Component {
    state = {
        statsShown: true,
    }

    componentDidMount() {
        if (this.props.id)  {
            this.props.getPlayerInfoWithChanges(this.props.id);
        }
    }

    showFullProfile = () => {
        this.props.navigation.navigate('PlayerProfile', {
            playerId : this.props.playerInfoWithChanges.realPlayerId
        });
    }

    setCaptain = () => {
        this.props.setCaptain(this.props.id);
        this.props.onDismiss();
    }

    setViceCaptain = () => {
        this.props.setViceCaptain(this.props.id);
        this.props.onDismiss();
    }

    toggleChange = () => {
        this.setState({ statsShown: false });
    }

    render() {
        if (!this.props.playerId || !this.props.playerInfoWithChanges) return null;

        return (
            <PopupDialog isVisible={true} onDismiss={this.props.onDismiss}>
                <View style={styles.headerContainer}>
                    <Image source={{uri:this.props.playerInfoWithChanges.image}} style={styles.playerImage} />
                    <View style={styles.playerInfoContainer}>
                        <Text style={styles.name}>{this.props.playerInfoWithChanges.name}</Text>
                        <Text>{this.props.playerInfoWithChanges.position} / {this.props.playerInfoWithChanges.team}</Text>
                        <Text style={styles.points}>{this.props.playerInfoWithChanges.totalPoints} Точки </Text>
                        <TouchableOpacity onPress={this.showFullProfile} 
                                        style={styles.fullProfileButton}>
                            <Text style={styles.profileButtonText}>Пълен Профил</Text>
                        </TouchableOpacity>
                    </View>
                </View>
                <View style={styles.programInfo}>
                    {this.state.statsShown && <View style={styles.captainContainer}>
                        <View style={styles.radioGroup}>
                            <Image style={styles.icon} source={setCaptainIcon}/>
                            <TouchableOpacity style={styles.radio} onPress={this.setCaptain}>
                                {this.props.isCaptain && <View style={styles.radioSelection} />}
                            </TouchableOpacity>
                            <Text style={styles.radioTitle}>Капитан</Text>
                        </View>
                        <View style={styles.radioGroup}>
                            <Image style={styles.icon} source={setViceCaptainIcon}/>
                            <TouchableOpacity style={styles.radio} onPress={this.setViceCaptain}>
                                {this.props.isViceCaptain && <View style={styles.radioSelection} />}
                            </TouchableOpacity>
                            <Text style={styles.radioTitle}>Вицекапитан</Text>
                        </View>
                    </View>}
                    {this.state.statsShown && 
                    <View style={styles.roundsContainer}>
                        <View style={[styles.roundRow, {borderBottomWidth: 1}]}>
                        {this.props.playerInfoWithChanges.previousMatches.length > 1 && 
                            <View style={styles.roundDetails}>
                                <Text>{this.props.playerInfoWithChanges.previousMatches[1].roundName}</Text>
                                <View style={styles.teamInfo}>
                                    <Text>{this.props.playerInfoWithChanges.previousMatches[1].teamShort}</Text>
                                    <Image style={styles.teamIcon} source={{uri:this.props.playerInfoWithChanges.previousMatches[1].teamLogo}} />
                                </View>
                                <View style={[styles.pointsContainer, getPlayerPointsColor(this.props.playerInfoWithChanges.previousMatches[1].points, true)]}>
                                    <Text>{this.props.playerInfoWithChanges.previousMatches[1].points} т.</Text>
                                </View>
                            </View>}
                            {this.props.playerInfoWithChanges.nextMatches.length > 0 && 
                            <View style={styles.roundDetails}>
                                <Text>{this.props.playerInfoWithChanges.nextMatches[0].roundName}</Text>
                                <View style={styles.teamInfo}>
                                    <Text>{this.props.playerInfoWithChanges.nextMatches[0].teamShort}</Text>
                                    <Image style={styles.teamIcon} source={{uri:this.props.playerInfoWithChanges.nextMatches[0].teamLogo}} />
                                    {//<Text>{this.props.playerInfoWithChanges.nextMatches[0].isHost ? "(Д)" : '(Г)'}</Text>
                                    }
                                </View>
                                <Text>{this.props.playerInfoWithChanges.nextMatches[0].date}</Text>
                            </View>
                            }
                        </View>
                        <View style={styles.roundRow}>
                            {this.props.playerInfoWithChanges.previousMatches.length > 0 && 
                            <View style={styles.roundDetails}>
                                <Text>{this.props.playerInfoWithChanges.previousMatches[0].roundName}</Text>
                                <View style={styles.teamInfo}>
                                    <Text>{this.props.playerInfoWithChanges.previousMatches[0].teamShort}</Text>
                                    <Image style={styles.teamIcon} source={{uri:this.props.playerInfoWithChanges.previousMatches[0].teamLogo}} />
                                </View>
                                <View style={[styles.pointsContainer, getPlayerPointsColor(this.props.playerInfoWithChanges.previousMatches[0].points, true)]}>
                                    <Text>{this.props.playerInfoWithChanges.previousMatches[0].points} т.</Text>
                                </View>
                            </View>}
                            {this.props.playerInfoWithChanges.nextMatches.length > 1 && 
                            <View style={styles.roundDetails}>
                                <Text>{this.props.playerInfoWithChanges.nextMatches[1].roundName}</Text>
                                <View style={styles.teamInfo}>
                                    <Text>{this.props.playerInfoWithChanges.nextMatches[1].teamShort}</Text>
                                    <Image style={styles.teamIcon} source={{uri:this.props.playerInfoWithChanges.nextMatches[1].teamLogo}} />
                                    {//<Text>{this.props.playerInfoWithChanges.nextMatches[1].isHost ? "(Д)" : '(Г)'}</Text>
                                    }
                                </View>
                                <Text>{this.props.playerInfoWithChanges.nextMatches[1].date}</Text>
                            </View>}
                        </View>
                    </View>}
                    {!this.state.statsShown && 
                    <View style={styles.changesContainer}>
                        {this.props.playerInfoWithChanges.possibleChanges.map(player => (
                            <PlayerView 
                                key={player.playerId}
                                containerStyle={{width: '16%'}}
                                teamStyle={{fontSize: 9}}
                                onPress={() => this.props.onSelectPlayerForChange(player)}
                                {...player} 
                                isReady={true}/>
                        ))}
                    </View>}
                    <TouchableOpacity 
                        style={styles.buttonContainer} 
                        onPress={this.toggleChange}>
                        <Text style={styles.buttonText}>
                            {this.state.statsShown ? "СМЕНИ" : "ВЪЗМОЖНИ СМЕНИ"}
                        </Text>
                    </TouchableOpacity>
                </View>
            </PopupDialog>
        );
    }
};

const mapDispatchToProps = (dispatch, ownProps) => {
  return {
    getPlayerInfoWithChanges: playerId => dispatch(getPlayerInfoWithChanges(playerId)),
    setCaptain: playerId => dispatch(setCaptain(playerId)),
    setViceCaptain: playerId => dispatch(setViceCaptain(playerId))
  }
}

const mapStateToProps = (state, ownProps) => {
  return {
    playerInfoWithChanges: state.teamPlayerInfo.playerShortInfo,
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(PlayerPopup);

const styles = EStyleSheet.create({
  playerImage: {
      height: '20rem',
      width: '20rem',
      borderRadius: 10
  },
  headerContainer: {
      flexDirection: 'row',
      width: '100%',
      padding: 10,
  },
  name: {
      fontWeight: 'bold',
      fontSize: '2.3rem',
      marginBottom: 5
  },
  points: {
      fontWeight: 'bold',
      color: '#2053A4',
      marginTop: 10,
      marginBottom: 2,
      fontSize: '2rem',
  },
  fullProfileButton: {
      alignItems: 'center',
      justifyContent: 'center',
      backgroundColor: '#2053A4',
      paddingVertical: 5,
      borderRadius: 5
  },
  profileButtonText: {
      color: 'white',
      fontSize: 18
  },
  playerInfoContainer: {
      marginLeft: 10
  },
  buttonContainer: {
      width: '100%',
      height: 50,
      paddingHorizontal: 5,
      alignItems: 'center',
      backgroundColor: 'black',
      justifyContent: 'center',
      borderBottomLeftRadius: 10,
      borderBottomRightRadius: 10
  }, 
  buttonText: {
    textAlign: 'center',
    color: 'white',
    fontSize: 15,
    fontWeight: 'bold'
  }, 
  icon: {
      width: 30,
      height: 30
  },
  captainContainer: {
      flexDirection: 'row',
      backgroundColor: '#D7DFF7',
      alignItems: 'center',
      justifyContent: 'space-between',
      paddingHorizontal: 10,
      paddingVertical: 5
  },
  radioGroup: {
      flexDirection: 'row',
      alignItems: 'center'
  },
  radio: {
      borderWidth: 2,
      borderColor: '#838384',
      borderRadius: 12,
      backgroundColor: 'white',
      width: 25,
      height: 25,
      marginHorizontal: 4,
      alignItems: 'center',
      justifyContent: 'center'
  },
  radioTitle: {
      fontWeight: 'bold',
      fontSize: '2.4rem'
  },
  radioSelection: {
      backgroundColor: '#F6C146',
      width: 15,
      height: 15,
      borderRadius: 10
  },
  roundsContainer: {
      backgroundColor: '#E5EAF4',
      width: '100%',
      padding: 10
  },
  roundRow: {
      flexDirection: 'row',
      paddingVertical: 6
  },
  roundDetails: {
      flexDirection: 'row',
      width: '50%',
      alignItems: 'center',
      justifyContent: 'space-between'
  },
  teamInfo: {
      flexDirection: 'row',
      marginHorizontal: 5,
      marginTop: 4
  },
  teamIcon: {
      width: 15,
      height: 15,
      marginHorizontal: 5,
      marginVertical: 3
  },
  pointsContainer: {
      backgroundColor: '#E4A647',
      alignItems: 'center',
      justifyContent: 'center',
      paddingHorizontal: 4,
      paddingVertical: 2,
      borderRadius: 3,
      borderWidth: 1,
      borderColor: 'white',
      marginRight: 10
  },
  changesContainer: {
      backgroundColor: '#1D2640',
      flexDirection: 'row',
      paddingVertical: 15,
      paddingHorizontal: 5,
      width: '100%'
  },
  iconStyle: {
    width: '100%',
    height: 90
  }
});
