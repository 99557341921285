import { TRY_AUTH, AUTH_SET_TOKEN, SET_HAS_ROOMS } from "./actionTypes";
import { BASE_URL } from '../../Common/config/config';
import { uiStartLoading, uiStopLoading } from "../../Common/actions/ui";
import { setError } from '../../Common/actions/errors';
import * as translations from '../../Common/utils/translations';
import { getDomainOperator } from "../../Common/utils/domainMapper";

export const loginOrRegister = authData => {
  return dispatch => {
    dispatch(uiStartLoading());
    fetch(BASE_URL + '/palms/auth', {
      credentials: 'include',
      method: "POST",
      body: JSON.stringify(authData),
      headers: {
        "Content-Type": "application/json"
      }
    })
    .catch(err => {
      console.log(err);
      dispatch(setError(translations.tKey('str_auth_error')));
      dispatch(uiStopLoading());
    })
    .then(res => {      
      dispatch(uiStopLoading());        
      if (res.has_error) {
        dispatch(setError(res.message));
        return;
      }

      if (!res || !res.headers.get('Authorization')) {
        dispatch(setError(translations.tKey('str_auth_error')));
      } else {
        dispatch(authSetToken(res.headers.get('Authorization')));
        localStorage.setItem('token', res.headers.get('Authorization'));
      }
      return res.json();
    })
    .then(res => {
      const operator = getDomainOperator();
      if(res.has_error) {
        dispatch(setError(res.message));
        if(operator.login == 'demo') {
          alert('Moving to demo');
          window.location.assign('http://demo.ffstars.com/?public_id=demo');
        } else if(operator.login == 'sp') {
          alert('Please login from SportsLocker');
          window.location.assign('https://v2.sportslocker24.com/users/ffstars/');
        }
        else {
          alert("Please login from your numbase account");
          window.location.assign('http://' + operator.fullLogin);
        }
      } else {
        if(res.data.cfg) {
          localStorage.setItem('logo', res.data.cfg["logo"] ? res.data.cfg['logo'] : '');
          localStorage.setItem('name', res.data.cfg["name"] ? res.data.cfg['name'] : '');
          localStorage.setItem('primaryColor', res.data.cfg["main-color"] ? res.data.cfg['main-color'] : '');
          localStorage.setItem('secondaryColor', res.data.cfg["accent-color"] ? res.data.cfg['accent-color'] : '');
        }
        localStorage.setItem('username', !res.data.username ? '' : res.data.username);
        if(!operator.title) {
          window.location.assign(window.location);
        }
        dispatch(setIsNew(res.data.hasRooms))
      }
    })
  };
};


export const authSetToken = token => {
  return {
    type: AUTH_SET_TOKEN,
    token
  };
};

export const setIsNew = hasRooms => {
  return {
    type: SET_HAS_ROOMS,
    hasRooms
  };
};