import React from 'react';
import { View, Text, Image, TouchableOpacity } from 'react-native';

import { createStyles, maxWidth } from 'react-native-media-queries';
import downArrow from '../../assets/images/down_arrow.svg';
import * as translations from '../../Common/utils/translations';
import { Icon } from '../../Common/components/Icon';
import { getDomainOperator } from '../../Common/utils/domainMapper';

class UserTeamsTable extends React.Component {

  state = {
    name: null,
    entries: null,
    sortCriterias: [
      {
        name: 'position',
        sort: 'desc'
      },
      {
        name: 'name',
        sort: 'desc'
      },
      {
        name: 'points',
        sort: 'desc'
      },
      {
        name: 'points_pp',
        sort: 'desc'
      },
      {
        name: 'pay_outs',
        sort: 'desc'
      }
    ]
  };

  componentDidMount() {
    this.setState({ teams: this.props.teams });
  }

  componentWillReceiveProps(nextProps) {

    if (nextProps.teams != this.props.teams) {
      if (!this.state.currentSort) {
        this.setState({ teams: nextProps.teams });
        return;
      }
      let sortCriteria = this.state.currentSort.name;
      this.setState({
        teams: this.state.teams.slice().sort((a, b) => {
          if (sortCriteria === 'name' ? (a[sortCriteria] > b[sortCriteria]) : (+a[sortCriteria] > +b[sortCriteria])) {
            return this.state.currentSort.sort === 'asc' ? 1 : -1;
          }
          if (sortCriteria === 'name' ? (a[sortCriteria] < b[sortCriteria]) : (+a[sortCriteria] < +b[sortCriteria])) {
            return this.state.currentSort.sort === 'asc' ? -1 : 1;
          }
          return 0;

        })
      });
    }
  }

  getRowComponent = (name, value) => {
    return <View style={styles.nameValueRow}>
      <View style={styles.nameRow}>
        <Text style={styles.textNormal}>{name}</Text>
      </View>
      <View style={styles.valueRow}>
        <Text style={styles.textNormal}>{value}</Text>
      </View>
    </View>;
  };

  sortBy = (sortCriteria, switchSort) => {
    this.setState({
      teams: this.state.teams.slice().sort((a, b) => {
        if (sortCriteria === 'name' ? (a[sortCriteria] > b[sortCriteria]) : (+a[sortCriteria] > +b[sortCriteria])) {
          return this.state.sortCriterias.find(t => t.name === sortCriteria).sort === 'asc' ? 1 : -1;
        }
        if (sortCriteria === 'name' ? (a[sortCriteria] < b[sortCriteria]) : (+a[sortCriteria] < +b[sortCriteria])) {
          return this.state.sortCriterias.find(t => t.name === sortCriteria).sort === 'asc' ? -1 : 1;
        }
        return 0;

      }),
      currentSort: !this.state.currentSort
        || (this.state.currentSort.name != sortCriteria || this.state.currentSort.sort != this.state.sortCriterias.find(t => t.name == sortCriteria).sort)
        ? { ...this.state.sortCriterias.find(t => t.name == sortCriteria) }
        : this.state.currentSort,
      sortCriterias: this.state.sortCriterias.map(t => {
        if (t.name === sortCriteria && switchSort) {
          if (t.sort === 'asc') {
            t.sort = 'desc';
          } else {
            t.sort = 'asc';
          }
        }
        return { ...t };
      })
    });
  };
  render() {
    const { teams } = this.state;

    if (!teams) return null;
    return (
      <View style={styles.container}>
        <View style={styles.allItemsTextContainer}>
          <Text style={styles.allItemsText}>{`${translations.tKey('str_dashboard_your_teams')}:`}</Text>
          <Text style={styles.allItemsText}>{teams.length}</Text>
        </View>

        <View style={styles.tableContainer}>
          <View style={styles.tableHeader}>
            <div style={styles.tableCellSmall} onClick={() => {
              this.sortBy('position', true);
            }}>
              <TouchableOpacity style={{ ...styles.headerButton, ...styles.spaceBetween }}>
                <Icon icon="number" size={14} />

                <Image style={styles.arrowImage} source={downArrow} />
              </TouchableOpacity>
            </div>
            <div style={styles.tableCellBig} onClick={() => {
              this.sortBy('name', true);
            }}>
              <TouchableOpacity style={{ ...styles.headerButton, ...styles.spaceBetween }}>
                <Icon icon="user" size={16} />

                <Image style={styles.arrowImage} source={downArrow} />
              </TouchableOpacity>
            </div>
            <div style={styles.tableCellMediumBig} onClick={() => {
              this.sortBy('pay_outs', true);
            }}>
              <TouchableOpacity style={{ ...styles.headerButton, ...styles.spaceBetween }}>
                <Icon icon="prizePool" size={16} />

                <Image style={styles.arrowImage} source={downArrow} />
              </TouchableOpacity>
            </div>

            <div style={styles.tableCellMedium} onClick={() => {
              this.sortBy('points_pp', true);
            }}>
              <TouchableOpacity style={{ ...styles.headerButton, ...styles.spaceBetween }}>
                <Icon icon="doublePoints" size={16} />

                <Image style={styles.arrowImage} source={downArrow} />
              </TouchableOpacity>
            </div>

            <div style={styles.tableCellMedium} onClick={() => {
              this.sortBy('points', true);
            }}>
              <TouchableOpacity style={{ ...styles.headerButton, ...styles.spaceBetween }}>
                <Icon icon="points" size={16} />

                <Image style={styles.arrowImage} source={downArrow} />
              </TouchableOpacity>
            </div>
          </View>
          {teams.map((entry, index) => (
            <div style={{ width: '100%' }} onClick={() => this.props.onTeamClicked(entry)} key={index}>
              <TouchableOpacity
                key={index}
                onPress={() => this.props.onTeamClicked(entry)}
                style={[styles.tableRow, this.props.selected && (entry.user_team_id == this.props.selected.user_team_id || entry.user_team_id == this.props.selected.teamId) ? styles.selectedRow : null]}
              >
                <Text style={[styles.tableCellSmall, styles.tableCellText]}>
                  {`${entry.position}.`}
                </Text>
                <Text style={[styles.tableCellBig, styles.tableCellText]}>
                  {entry.name}
                </Text>
                {!this.props.subgame.is_seasonal_game && <Text style={[styles.tableCellMediumBig, styles.tableCellText]}>
                  {`${entry.pay_outs.toFixed(2)}${translations.tKey('str_currency')}`}
                </Text>}
                <Text style={[styles.tableCellMedium, styles.tableCellText, styles.center]}>
                  {this.props.subgame.is_seasonal_game ? entry.points : entry.points_pp}
                </Text>
                <Text style={[styles.tableCellMedium, styles.tableCellText, styles.bold, styles.center]}>
                  {this.props.subgame.is_seasonal_game ? entry.total_points : entry.points}
                </Text>
              </TouchableOpacity>
            </div>
          ))}
        </View>
      </View>
    );
  }
};


export default UserTeamsTable;

const operator = getDomainOperator();

const design = operator.design;

const base = {
  bold: {
    fontWeight: 900
  },
  center: {
    display: 'flex',
    justifyContent: 'center'
  },
  spaceBetween: {
    justifyContent: 'space-between'
  },
  container: {
    width: '100%',
    display: 'flex',
    alignItems: 'center'
  },
  tableContainer: {
    marginTop: '20px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    width: '100%'
  },
  tableHeader: {
    display: 'flex',
    flexDirection: 'row',
    width: '92%',
    marginBottom: 15
  },
  tableHeaderText: {
    fontSize: 14,
    fontWeight: 'bold'
  },
  headerButton: {
    width: '100%',
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'flex-start'
  },
  tableRow: {
    display: 'flex',
    flexDirection: 'row',
    width: '100%',
    paddingVertical: '5px',
    paddingHorizontal: '4%'
  },
  selectedRow: {
    backgroundColor:  design.secondaryColor
  },
  tableCellText: {
    fontSize: 14
  },
  tableCellSmall: {
    flex: 1,
    display: 'flex',
    justifyContent: 'flex-start',
    alignItems: 'center',
    flexDirection: 'row'
  },
  tableCellMedium: {
    flex: 2,
    display: 'flex',
    justifyContent: 'flex-start',
    alignItems: 'center',
    flexDirection: 'row'
  },

  tableCellMediumBig: {
    flex: 3,
    display: 'flex',
    justifyContent: 'flex-start',
    alignItems: 'center',
    flexDirection: 'row'

  },
  tableCellBig: {
    flex: 5,
    display: 'flex',
    justifyContent: 'flex-start',
    alignItems: 'center',
    flexDirection: 'row'
  },
  searchImage: {
    width: '14.5px',
    height: '14.5px',
    resizeMode: 'contain',
    marginLeft: '10px',
    marginRight: '10px'
  },
  allItemsTextContainer: {
    width: '100%',
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'flex-start',
    justifyContent: 'space-between',
    paddingVertical: '10px',
    paddingHorizontal: '10px',
    backgroundColor: '#EDEDED',
    borderRadius: 3
  },
  allItemsText: {
    fontSize: 16,
    fontWeight: 'bold'
  },
  arrowImage: {
    width: '7px',
    height: '4.3px',
    resizeMode: 'contain',
    marginRight: '15px',
    marginTop: '2px'
  }
};

const styles = createStyles(base);

