import React from "react";
import { View, Text, StyleSheet, Image, TouchableOpacity, ScrollView } from "react-native";
import Background from '../../Common/components/Background';
import IosArrowDown from 'react-ionicons/lib/IosArrowDown';
import IosArrowUp from 'react-ionicons/lib/IosArrowUp';
import IosPersonOutline from 'react-ionicons/lib/IosPersonOutline';
import RoundsDialog from '../components/RoundsDialog';
import LoadingScreen from '../../Common/components/LoadingScreen';

import { connect } from 'react-redux';
import { getRounds, setCurrentRound } from '../../Common/actions/rounds';
import { closeRound, openRound } from '../actions/home';
import { getChampionshipInfo } from '../actions/championship';
import { getHomeInfo } from '../actions/home';
import { enterRoom } from '../../SearchRoom/actions/rooms';
import { getTeam } from '../../Team/actions/team';
import PopupDialog from '../../Common/components/PopupDialog';
import rulesIcon from '../../assets/exclamation.jpg';
import RoomView from '../../Common/components/RoomView';
import { joinRoom, setRoomInfo } from '../../SearchRoom/actions/rooms';
import { setError } from '../../Common/actions/errors';
import { getDomainOperator } from "../../Common/utils/domainMapper";

class HomeScreen extends React.Component {

    state = {
        roundsOpen: false,
        choosenRoom: null,
        showErrorPopup: false
    }

    componentDidMount() {    
      const { 
        getChampionshipInfo, 
        getHomeInfo,
        getRounds,
        currentRoundId 
      } = this.props;  

      getChampionshipInfo();
      const championshipId = window.localStorage.getItem("championshipId");
      getRounds(championshipId ? championshipId : 1)
      if (currentRoundId) 
        getHomeInfo(currentRoundId);
      else {
        getHomeInfo();
      }
    }

    onChooseRoom = room => {
      this.props.enterRoom(room);
      setTimeout(() => {
        if (this.props.userInfo.isRoundFinished) {
          this.props.navigation.navigate('Points');
          this.props.closeRound();
        }
        else {
          this.props.getTeam();
          this.props.navigation.navigate('Team');
          this.props.openRound();
        }
      }, 1000);
    }

    componentDidUpdate(prevProps, prevState) {
      localStorage.removeItem("autoFill");
      const { userInfo, navigation } = this.props;
      if (this.props.errorCode === 503) {        
        this.props.navigation.navigate('Maintenance');
        return;
      }

      if (userInfo) {
        if (!prevState.currentRound) {
          this.setState({ currentRound: {
            id: userInfo.currentRoundId,
            name: userInfo.currentRoundName,
          }});
          this.props.setCurrentRound(userInfo.currentRoundId);
        }
      }
      if (this.props.userInfo) {
        window.localStorage.setItem("championshipId", this.props.userInfo.championshipId)
      }
      if (prevProps.roomInfo !== this.props.roomInfo) {
        this.props.navigation.navigate('Team');
      }
      if (this.props.error !== '' && !this.state.showErrorPopup) {
        this.setState({showErrorPopup: true});
      }
      if (this.props.navigation !== prevProps.navigation) {
        getChampionshipInfo();
        getRounds(window.localStorage.getItem("championshipId"));
  
        if (this.props.currentRoundId) 
          getHomeInfo(this.props.currentRoundId);
        else {
          getHomeInfo();
        }
      }
    }

    openRounds = (event) => {
      event.preventDefault();
      this.setState({
        roundsOpen: true,
      });
    }

    closeRounds = round => {
      if (!round || !round.id) {
        this.setState({ roundsOpen: false });
        return;
      }
      this.setState({ currentRound: round, roundsOpen: false });
      this.props.getHomeInfo(round.id);      
      this.props.setCurrentRound(round.id);
    };

    joinFreeRoom = () => {
      this.setState({hideButton: true});
      this.props.setRoomInfo(this.props.userInfo.free_room);
      this.props.joinRoom(this.props.userInfo.free_room.id);      
    }

    render() {
        const { navigation, userInfo, rounds } = this.props;
        const { currentRound, hideButton } = this.state;
        
        if (!rounds || !userInfo || !currentRound) return <LoadingScreen />;
        const alreadyInFree = userInfo.free_room && userInfo.rooms.find(r => r.id === userInfo.free_room.id);

        return (
            <ScrollView style={styles.scrollView} contentContainerStyle={styles.container}>
                <Background />
                <PopupDialog isVisible={this.state.showErrorPopup} 
                            onDismiss={()=>this.setState({showErrorPopup: false, hideButton: false}) || this.props.setError('')} 
                            title="Грешка при влизане в стая">
                  <View style={{width: '100%', justifyContent: 'center', alignItems: 'center'}}>
                    <Text>{this.props.error}</Text>
                  </View>
                </PopupDialog>
                <RoundsDialog 
                open={this.state.roundsOpen} 
                onClose={this.closeRounds} 
                rounds={rounds.filter(r => r.id <= userInfo.lastRoundId)}/>

                <TouchableOpacity style={styles.headerView} onPress={this.openRounds}>
                    <View style={styles.roundContainer}>
                        <Text style={styles.headerText}>{currentRound.name}</Text>
                        <IosArrowDown fontSize="1.8em" color="black" style={{marginLeft: 10}} />
                    </View>
                </TouchableOpacity>

                <View style={styles.allRooms}>
                 {userInfo.rooms.map(room => 
                    <RoomView 
                    key={room.id} 
                    room={room} 
                    onChooseRoom={this.onChooseRoom}/>
                  )}
                 {(!userInfo.rooms || userInfo.rooms.length === 0) &&
                    <Text style={{fontWeight: 'bold', fontSize: 18, color: 'white', textAlign: 'center', width: '100%'}}>
                      Липсват стаи за този кръг!
                    </Text>
                 }
                </View>
                {!userInfo.isRoundFinished && 
                <>
                <TouchableOpacity style={styles.playButton} 
                        onPress={() => navigation.navigate("ChooseBet")}>
                    <Text style={styles.buttonText}>ВЛЕЗ В НОВА СТАЯ</Text>
                </TouchableOpacity>
                {!hideButton && !alreadyInFree && userInfo.free_room && <TouchableOpacity style={[styles.playButton, {backgroundColor: '#FDD954'}]} 
                        onPress={() => this.joinFreeRoom()}>
                    <Text style={styles.buttonText}>БЕЗПЛАТНА СТАЯ С НАГРАДЕН ФОНД 500 лв.</Text>
                </TouchableOpacity>}
                </>}
                <TouchableOpacity style={styles.rules} onPress={()=>navigation.navigate('Rules')}>
                  <Image source={rulesIcon} style={styles.image} />
                  <Text style={styles.rulesText}>правила на играта</Text>
                </TouchableOpacity>
            </ScrollView>
        );
    }
};

const mapDispatchToProps = (dispatch, ownProps) => {
  return {
    getTeam: () => dispatch(getTeam()),
    enterRoom: room => dispatch(enterRoom(room)),
    joinRoom: id => dispatch(joinRoom(id)),
    setRoomInfo: info => dispatch(setRoomInfo(info)),
    getRounds: (id) => dispatch(getRounds(id)),
    getChampionshipInfo: () => dispatch(getChampionshipInfo()),
    getHomeInfo: roundId => dispatch(getHomeInfo(roundId)),
    closeRound: () => dispatch(closeRound()),
    openRound: () => dispatch(openRound()),
    setCurrentRound: roundId => dispatch(setCurrentRound(roundId)),
    setError: error => dispatch(setError(error))
  }
}

const mapStateToProps = (state, ownProps) => {
  return {
    roomInfo: state.room.roomInfo,
    rounds: state.rounds.rounds,
    userInfo: state.userInfo.info,
    errorCode: state.errors.code,
    currentRoundId: state.rounds.currentRoundId,
    error: state.errors.error,
  }
}


export default connect(mapStateToProps, mapDispatchToProps)(HomeScreen);

const operator = getDomainOperator();

const design = operator.design;

const styles = StyleSheet.create({
  scrollView: {
    flex: 1,
    width: '100%',
  },
  container: {
    paddingBottom: 80,
    width: '100%',
    alignItems: 'center',
    justifyContent: 'center',
    minHeight: '100%',    
  },
  headerView: {
    backgroundColor: '#CCD4E1',
    paddingVertical: 5,
    paddingHorizontal: 30,
    width: '90%',
    borderRadius: 10,
    alignItems: 'center',
    justifyContent: 'center',
    marginVertical: 15
  },
  headerText: {
    fontWeight: 'bold',
    fontSize: 25,
    marginVertical: 5
  },
  headerInfo: {
    flexDirection: 'row'
  },
  headerInfoText: {
    marginHorizontal: 15,
    fontSize:20
  },
  roundContainer: {
      flexDirection: 'row',
      alignItems: 'center',
      paddingVertical: 10,
      paddingHorizontal: 15
  },
  playButton: {
    marginTop: 40,
    alignItems: 'center',
    justifyContent: 'center',
    borderRadius: 20,
    width: '70%',
    backgroundColor: design.secondaryColor,
    paddingVertical: 20,
    paddingHorizontal: 10
  },
  buttonText: {
    fontWeight: 'bold',
    fontSize: 22,
    textAlign: 'center'
  },
  allRooms: {
    width: '90%'
  },
  roomContainer: {
    width: '100%',
    marginVertical: 10
  },
  roomBasicInfo: {
    backgroundColor: 'white',
    borderRadius: 20,
    width: '100%',
    padding: 17,
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
    zIndex: 4,
  },
  basicInfoText: {
    fontWeight:'bold',
    fontSize: 15
  },
  circle: {
    marginHorizontal: 10,
    width: 30,
    height: 30,
    backgroundColor: '#347190',
    alignItems: 'center',
    justifyContent: 'center',
    borderRadius: 20
  },
  roomMoreInfo: {
    backgroundColor: '#0E2D49',
    width: '100%',
    flexDirection: 'row',
    padding: 10,
    alignItems: 'center',
    justifyContent: 'space-evenly',
    borderBottomLeftRadius: 20,
    borderBottomRightRadius: 20,
    paddingTop: 40,
    top: -20
  },
  moreInfoContainer: {
    alignItems: 'center',
    justifyContent: 'space-evenly',
    height: 60 
  },
  infoText: {
    color: '#8999A9',
    fontSize: 16
  },
  bold: {
    fontWeight:'bold'
  },
  rules: {
    width: '100%',
    alignItems: 'center',
    justifyContent: 'center',
    flexDirection: 'row',
    marginVertical: 20
  },
  rulesText: {  
    color: '#C3A050',
    fontSize: 18,
    margin: 10
  },
  image: {
    width: 30, 
    height: 30,
    resizeMode: 'contain'
  }
});