import React, { Component } from 'react';
import { View, Text, StyleSheet, Image, TouchableOpacity, ScrollView, ActivityIndicator } from 'react-native';
import moment from 'moment';
import { createStyles, maxWidth } from 'react-native-media-queries';
import DropDownDialog from '../../HomeTournaments/components/DropDownDialog';
import IosArrowDown from 'react-ionicons/lib/IosArrowDown';
import IosArrowUp from 'react-ionicons/lib/IosArrowUp';
import { getUserDashboard, getLeaderboard, setSelectedItems, deleteSelectedItems, getRoundSliderInfo } from '../actions/dashboard';
import { connect } from 'react-redux';
import DashboardCard from '../components/DashboardCard';
import infoIcon from '../../assets/images/info_purple.svg';
import TournamentFixtures from '../../TournamentDetails/components/TournamentFixtures';
import { getTournamentFixtures } from '../../TournamentDetails/actions/tournamentDetails';
import UserTeamsTable from '../components/UserTeamsTable';
import TournamentLeaderboard from '../components/TournamentLeaderboard';
import Playground from '../../Common/components/Playground';
import clock from '../../assets/images/clock_black.svg';
import Timer from '../../Common/components/Timer';
import { getTeamById } from '../../Team/actions/team';
import TournamentPrizes from '../../TournamentDetails/components/TournamentPrizes';
import { isMobile } from 'react-device-detect';
import LoadingScreen from '../../Common/components/LoadingScreen';
import * as translations from '../../Common/utils/translations';
import { setCaptain, setViceCaptain } from "../../Team/actions/players";
import { setPlayerCaptain, setPlayerViceCaptain } from '../../Team/actions/players';
import { getHomeTournamentsInfo } from '../../HomeTournaments/actions/tournaments';
import { PlayerOptionsDialog } from '../../EnterTournament/components/PlayerOptionsDialog';
import { ChangePlayerDialog } from '../../EnterTournament/components/ChangePlayerDialog';
import { clearTournamentForTransfer } from '../../EnterTournament/actions/enterTournament';
import InformationDialog from '../../HomeTournaments/components/InformationDialog';
import { IconInformationDialog } from '../../Common/components/IconInformationDialog';
import { logTrace } from '../../Common/utils/logger';
import { Pagination } from '../../Common/components/Pagination/Pagination';
import ReserveDialog from '../../Common/components/ReserveDialog';
import { editTeam } from '../../Team/actions/team';
import { formatPlayersName, numberWithCommas } from '../../Common/utils/utils';
import leftArrow from '../../assets/arrow-left.png';
import rightArrow from '../../assets/arrow-right.png';
import { getDomainOperator } from '../../Common/utils/domainMapper';

class Dashboard extends Component {

  constructor(props) {
    super(props);
    this.ref = React.createRef();
    this.mainRef = React.createRef();
    this.state = {
      gamesFilterOpen: false,
      selectedStatus: { id: 1, name: translations.tKey('str_dashboard_active'), value: 'active' },
      subgame: null,
      team: null,
      teamSelectDialogOpen: false,
      isScrolling: false,
      clientX: 0,
      scrollX: 0,
      hasScrolled: false,
      selectedPlayer: null,
      infoDialogOpen: false,
      infoDialogContent: '',
      isFirstScroll: false,
      hasLiftedMouse: false,
      // selectedRound: 9,
      reserveDialog: {
        open: false,
        reserve: null
      },
      captainActive: null,
      viceActive: null,
      capitanFilterOpen: false,
      viceFilterOpen: false,
      inputPixels: 0,
      teamIndex: 0
    };
  }

  componentWillReceiveProps(nextProps) {

    localStorage.removeItem("autoFill");
    localStorage.removeItem("playerPressed");
    if (this.props.dashboard != nextProps.dashboard || localStorage.getItem("teamChange")) {
      
      localStorage.removeItem("teamChange")
      if (!nextProps.dashboard || nextProps.dashboard.length == 0) {
        return;
      }
      let selectedGame = null;
      if (this.props.navigation.getParam('subId')) {
        
        if (nextProps.dashboard.find(t => t.sub_tournament_id == this.props.navigation.getParam('subId'))) {
          selectedGame = nextProps.dashboard.find(t => t.sub_tournament_id == this.props.navigation.getParam('subId'));
          this.props.setSelectedItems(selectedGame, selectedGame.user_teams && selectedGame.user_teams[0]);
        } else {
          selectedGame = nextProps.dashboard[0];
          this.props.setSelectedItems(selectedGame, selectedGame.user_teams && selectedGame.user_teams[0]);
        }
        // } else if (this.state.subgame) {
        //     selectedGame = this.state.subgame
        //     this.props.setSelectedItems(selectedGame, selectedGame.user_teams && selectedGame.user_teams[0])
      } else {
       
        let seasonalGame = nextProps.dashboard.filter(t => (t.is_seasonal_game == true && t.is_active == true));
        if (seasonalGame.length > 0) {
          selectedGame = seasonalGame[0];
        } else {
          selectedGame = nextProps.dashboard[0];
        }
        if (selectedGame?.is_seasonal_game) {
          const allRounds = nextProps.dashboard.filter(t => t.name == selectedGame.name);
          selectedGame = allRounds.find(t => t.is_active);
          if (!selectedGame) {
            allRounds.forEach(t => {
              if (t.user_teams && t.user_teams.length > 0) {
                selectedGame = t;
                return;
              }
            })
          }
        }
       
        this.props.setSelectedItems(selectedGame, selectedGame.user_teams && selectedGame.user_teams[0]);
        if (selectedGame.is_seasonal_game) {
          this.props.getRoundSliderInfo(selectedGame.sub_tournament_id, selectedGame.user_teams[0].user_team_id);
        }
      }
      if (this.ref && this.ref.current && !isMobile) {
        const scrollX = 320 * nextProps.dashboard.findIndex(t => t.sub_tournament_id == selectedGame.sub_tournament_id);
        this.ref.current.scrollLeft = scrollX;
        this.setState({ scrollX });
      }
      
      this.setState({ subgame: selectedGame, team: selectedGame.user_teams[0] });
      this.props.getTournamentFixtures(selectedGame.tournament_id);
      this.props.getLeaderboard(1, null, selectedGame.sub_tournament_id);
      if (selectedGame.user_teams.length > 0) {
        if (localStorage.getItem('addNewTeamDash')) {
          var largestId = -1;
          selectedGame.user_teams.forEach(team => {
            if (largestId < team.user_team_id) {
              largestId = team.user_team_id;
            }
          });
          let team = selectedGame.user_teams.find(t => t.user_team_id == largestId);
          
          this.props.getTeamById(selectedGame.sub_tournament_id, largestId);
          localStorage.removeItem('addNewTeamDash');
          this.setState({
            subgame: selectedGame,
            team: team
          });
        } else if (localStorage.getItem('editTeamFromGame')) {
          const teamToSelect = JSON.parse(localStorage.getItem('editTeamFromGame'));
          if (selectedGame.is_seasonal_game) {
            const currentTournament = this.props.roundSliderInfo?.find(t => t.user_team_id == teamToSelect.teamId);
            setTimeout(() => {
              this.selectTeam(currentTournament?.sub_tournament_id, currentTournament?.user_team_id);
              this.setState({
                selectedRound: currentTournament?.number
              });
            }, 2000)

          } else {
            
            this.props.getTeamById(selectedGame.sub_tournament_id, teamToSelect.teamId);
            this.setState({
              subgame: selectedGame,
              team: selectedGame.user_teams.find(t => t.user_team_id == teamToSelect.teamId)
            });
          }

          localStorage.removeItem('editTeamFromGame');
        } else {
          
          this.props.getTeamById(selectedGame.sub_tournament_id, selectedGame.user_teams[0].user_team_id);
        }

      }
    }
    if (this.props.leaderboard != nextProps.leaderboard) {
      if (this.mainRef && this.mainRef.current) {
        if (localStorage.getItem('shouldScroll')) {
          this.mainRef.current.scrollToEnd();
          localStorage.removeItem('shouldScroll');
        }
      }
    }
    if (!this.state.team && this.props.leaderboard != nextProps.leaderboard && nextProps.leaderboard && nextProps.leaderboard.data.length > 0) {
      
      this.setState({ teamSelectDialogOpen: false, team: nextProps.leaderboard.data[0] });
      this.props.getTeamById(this.state.subgame.sub_tournament_id, nextProps.leaderboard.data[0].user_team_id);
    }

    if (this.props.selectedTeam != nextProps.selectedTeam || this.props.selectedGame != nextProps.selectedGame) {
      let team;
      const teamToSelect = JSON.parse(localStorage.getItem('editTeamFromGame'));
      localStorage.removeItem('editTeamFromGame');
      if (teamToSelect) {
        team = teamToSelect;
      } else {
        team = nextProps.selectedTeam
      }
      
      this.setState({
        subgame: nextProps.selectedGame,
        team: team,
        selectedPlayer: null,
        selectedMatch: null,
        timeEnded: false
      });

      let selectedGame;
      if (!nextProps.selectedGame) {
        selectedGame = this.props?.selectedGame;
      } else {
        selectedGame = nextProps.selectedGame;
      }

      // if (selectedGame && !isMobile) {

      //   let filteredTournaments = this.props.dashboard.filter((t) => {
      //     if (t.is_active != true && t.is_seasonal_game) {
      //       return;
      //     }
      //     return t;
      //   });

      //   filteredTournaments.unshift(                      // add to the front of the array
      //     filteredTournaments.splice(                     // the result of deleting items
      //       filteredTournaments.findIndex(                // starting with the index where
      //         t => (t.is_active == true && t.is_seasonal_game == true)),
      //       1)[0]                             // and continuing for one item
      //   )

      //   console.log(filteredTournaments);
      //   console.log(selectedGame);
      //   const scrollX = 320 * filteredTournaments.findIndex(t => t.sub_tournament_id == selectedGame.sub_tournament_id);
      //   setTimeout(() => {
      //     if (this.ref && this.ref.current) {
      //       this.ref.current.scrollLeft = scrollX;
      //       this.setState({ scrollX });
      //     }
      //   }, 100)
      // }
    }
    if (this.props.navigation.getHistory().action == 'POP') {
      const state = JSON.parse(localStorage.getItem('Dashboard'));
      const changeTeam = JSON.parse(localStorage.getItem('changeTeam'));
      if (state && !changeTeam) {
        this.setState({
          ...state,
          gamesFilterOpen: false,
          teamSelectDialogOpen: false,
          selectedPlayer: null,
          isFirstScroll: false
        });

        this.props.getTournamentFixtures(state.subgame.tournament_id);
        this.props.getLeaderboard(1, null, state.subgame.sub_tournament_id);
      
        this.props.getTeamById(state.subgame.sub_tournament_id, state.team ? state.team.user_team_id : state.subgame.user_teams[0].user_team_id);
        localStorage.removeItem('Dashboard');
      }
    }

    // If seasonal game, set captain and vice to the state
    if (nextProps.userTeam?.is_seasonal_game) {

      nextProps.userTeam.groups.forEach(group => {
        group.players.forEach(player => {
          if (player && player.isCaptain) {
            this.setState({ capitan: player });
          }

          if (player && player.isViceCaptain) {
            this.setState({ vice: player });
          }
        });
      });
    }
    if (this.props.roundSliderInfo != nextProps.roundSliderInfo) {
      let activeRound = null;
      nextProps.roundSliderInfo.forEach(round => {
        if (round.is_active) {
          activeRound = round;
          return;
        }
      })
      if (activeRound) {
        const currentTournament = this.props.dashboard.find(t => t.tournament_id == activeRound.tournament_id);
        this.setState({
          activeRound: activeRound,
          selectedRound: activeRound.number,
          actualRoundSelected: activeRound.number,
        });

        if (currentTournament.matches_left == 0) {
          if (nextProps.roundSliderInfo.find(t => t.number == activeRound.number + 1)) {
          this.setState({
            selectedRound: activeRound.number + 1,
            actualRoundSelected: activeRound.numbesr + 1,
          });
        } else {
          this.setState({
            selectedRound: activeRound.number,
            actualRoundSelected: activeRound.numbesr,
          });
        }
          if(nextProps.roundSliderInfo.find(t => t.number == activeRound.number + 1)) {
            this.selectTeam(nextProps.roundSliderInfo.find(t => t.number == activeRound.number + 1).sub_tournament_id, nextProps.roundSliderInfo.find(t => t.number == activeRound.number + 1).user_team_id);
          } else {
            this.selectTeam(nextProps.roundSliderInfo.find(t => t.number == activeRound.number).sub_tournament_id, nextProps.roundSliderInfo.find(t => t.number == activeRound.number).user_team_id);
          }
          
          this.props.getLeaderboard(1, null, currentTournament.sub_tournament_id);
        }
      } else {
        this.setState({
          selectedRound: nextProps.roundSliderInfo[0].number
        });
      }
    }
    if (this.props.userTeam != nextProps.userTeam && localStorage.getItem("downloadTeam")) {
      const lastRoundWithTeam = JSON.parse(localStorage.getItem("downloadTeam"));
      localStorage.removeItem("downloadTeam");
      localStorage.setItem("teamChange", "true");
      this.props.navigation.navigate('EnterTournament', {
        id: lastRoundWithTeam.tournament_id,
        subId: lastRoundWithTeam.sub_tournament_id,
      });
    }

    // Update team points and position when the state is changed.
    if (this.state.team && nextProps.userTeam) {
      
      if (nextProps.userTeam?.points !== this.state.team?.points) {
        this.setState(prevState => {
          let team = Object.assign({}, prevState.team);
          team.total_points = nextProps.userTeam.total_points;
          team.total_place = nextProps.userTeam.total_place;  // creating copy of state variable team
          team.points = nextProps.userTeam.points;
          team.position = nextProps.userTeam.current_place;   // update the points and team properties, assign a new value                 
          return { team };                               // return new object team
        })
      }
    }
  }

  componentDidMount() {
    localStorage.removeItem('changeTeam');
    localStorage.removeItem('autoFill');
    localStorage.removeItem('playerPressed');
    // this.setState({
    //   selectedRound: 9
    // });
    const state = JSON.parse(localStorage.getItem('Dashboard'));
    if (!state || localStorage.getItem('addNewTeamDash')) {
      const id = this.props.navigation.getParam('subId', null);
      this.props.getUserDashboard(this.state.selectedStatus.value, id);
    }
    this.props.deleteSelectedItems();
    this.props.clearTournamentForTransfer();
    sessionStorage.removeItem('transfer');
    sessionStorage.removeItem('transferTeamId');
    localStorage.removeItem('addNewTeam');
  }

  onMouseDown = e => {
    e.preventDefault();
    this.setState({
      mouseDown: true,
      clientX: e.clientX
    });
  };

  onMouseUp = () => {
    this.setState({ ...this.state, isScrolling: false, mouseDown: false });
    if (this.state.isScrolling) {
      localStorage.setItem('hasLiftedMouse', 'true');
    }

  };

  onMouseMove = e => {
    if (!this.ref) return;
    const { clientX, scrollX } = this.state;

    if (this.state.mouseDown) {
      this.ref.current.scrollLeft = scrollX - e.clientX + clientX;
      this.setState({
        isScrolling: true,
        scrollX: scrollX - e.clientX + clientX,
        clientX: e.clientX
      });
    }
  };

  moveScrollView = (isMobile) => {
    const { teamIndex } = this.state;
    let offset;
    if(!isMobile) {
      offset = 335;
      if (!this.ref) return;
      this.ref.current.scrollLeft = teamIndex * offset;
    } else {
      offset = 355;
      this.scroll.scrollTo({ x: teamIndex * offset})
    }
    this.setState({
      scrollX: teamIndex * offset,
      clientX: teamIndex * offset,
    });
  }

  moveScrollLeft = (teamIndex, isMobile) => {
    const newIndex = teamIndex > 0 ? teamIndex - 1 : 0;
    this.setState({ teamIndex: newIndex }, () => this.moveScrollView(isMobile));
  }

  moveScrollRight = (teamIndex, isMobile) => {
    const newIndex = teamIndex < (this.props.dashboard.length - 1) ? teamIndex + 1 : this.props.dashboard.length - 1;
    this.setState({ teamIndex: newIndex }, () => this.moveScrollView(isMobile));
  }

  openGamesFilter = (event) => {
    event.preventDefault();
    event.stopPropagation();
    this.setState({ gamesFilterOpen: true });
  };

  closeGamesFilter = (item) => {
    this.setState({
      gamesFilterOpen: false,
      selectedStatus: item ? item : this.state.selectedStatus,
      subgame: item ? null : this.state.subgame,
      team: item ? null : this.state.team
    });
    if (item) {
      localStorage.setItem("changeGameFilter", true)
      this.props.getUserDashboard(item.value);
    }
  };

  openTeamSelectFilter = event => {
    this.setState({ teamSelectDialogOpen: true });
  };

  closeTeamSelectFilter = item => {
    this.setState({ teamSelectDialogOpen: false, team: item ? item : this.state.team });
    if (item) {
      this.props.getRoundSliderInfo(this.state.subgame.sub_tournament_id, item.user_team_id);
      this.props.getTeamById(this.state.subgame.sub_tournament_id, item.user_team_id);
    }
  };

  selectTeam = (id, user_team_id) => {
    // selectedGame and selectedTeam in redux
    if (localStorage.getItem('hasLiftedMouse') == 'true') {
      localStorage.setItem('hasLiftedMouse', 'false');
      return;
    }
    if (localStorage.getItem('addNewTeam')) {
      return;
    }

    let selectedGame = this.props.dashboard.find(t => t.sub_tournament_id == id);
    if (!selectedGame) {
      return;
    }

    if (user_team_id) {
      this.props.getTeamById(selectedGame.sub_tournament_id, user_team_id);
    } else {
      if (selectedGame.is_seasonal_game) {
        let activeRound = null;
        if (!this.props.roundSliderInfo) {
          return;
        }
        this.props.roundSliderInfo.forEach(round => {
          if (round.is_active) {
            activeRound = round;
            return;
          }
        })
        const currentTournament = this.props.dashboard.find(t => t.tournament_id == activeRound.tournament_id);
        if (currentTournament.matches_left == 0) {
          // Get next round
          this.setState({
            selectedRound: activeRound.number + 1
          });
          selectedGame = this.props.dashboard.find(t => t.sub_tournament_id == this.props.roundSliderInfo.find(t => t.number == activeRound.number + 1).sub_tournament_id);
        }
      }
      this.props.getTeamById(selectedGame.sub_tournament_id, selectedGame.user_teams[0].user_team_id);
    }
    this.props.getTournamentFixtures(selectedGame.tournament_id);
    this.props.getLeaderboard(1, null, selectedGame.sub_tournament_id);
    this.props.setSelectedItems(selectedGame, selectedGame.user_teams[0]);
  };

  transferPlayer = (player) => {
    const group = this.props.userTeam.groups.find(t => t.players.find(p => player.playerId == p.playerId));
    sessionStorage.setItem('currentGroup', JSON.stringify(group));
    sessionStorage.setItem('transfer', JSON.stringify(player));
    sessionStorage.setItem('transferTeamId', JSON.stringify(this.state.team.user_team_id));
    localStorage.setItem('Dashboard', JSON.stringify(this.state));
    this.props.navigation.navigate('SelectPlayers', {
      id: this.state.subgame.tournament_id,
      subId: this.state.subgame.sub_tournament_id
    });
  };

  setCaptain = (player) => {
    this.setState({ capitan: player, selectedPlayer: null });
    this.props.setCaptain(player.id, player.playerId, this.props.userTeam.teamId);
  };

  setViceCaptain = (player) => {
    this.setState({ vice: player, selectedPlayer: null });
    this.props.setViceCaptain(player.id, player.playerId, this.props.userTeam.teamId);
  };

  removePlayer = (player) => {
    let group = this.props.userTeam.groups.find(t => t.groupId == player.groupId);
    const playerIndex = group.players.findIndex(u => u && u.playerId == player.playerId);
    if (playerIndex >= 0) {
      group.players[playerIndex] = null;
      this.props.setGroup(group);
    }
    if (player.isCaptain) {
      this.setState({ capitan: null });
    } else if (player.isViceCaptain) {
      this.setState({ vice: null });
    }
  };

  showPlayerPopup = (player, isReserve) => {
    this.props.userTeam.groups.forEach(group => {
      group.players.forEach(p => {

        if (p && p.playerId == player.playerId) {
          player.groupId = group.groupId;
        }
      });
    });
    setTimeout(() => this.setState({ selectedPlayer: player, isSelectedReserve: isReserve }), 300);
  };

  hidePlayerPopup = () => {
    this.setState({ selectedPlayer: null });
  };

  onFixtureSelected = id => {
    const fixture = this.props.fixtures.fixtures.find(fixture => fixture.id === id);

    if (fixture && fixture.status !== 'upcoming') {
      this.setState({
        selectedMatch: this.state.selectedMatch === id
          ? null
          : id
      });
    } else {
      this.setState({
        infoDialogOpen: true,
        infoDialogContent: translations.tKey('str_details_fixtures_not_started')
      });
    }
  };

  closeInfoDialog = () => {
    this.setState({ infoDialogOpen: false });
  };

  shouldShowPlayerPoints = () => {
    let shouldShowPoints = false;

    if ((Date.parse(this.state.subgame.start_time) - Date.parse(new Date())) < 0) {
      logTrace('The game has already started. Showing points');

      return true;
    }

    if (!this.props.userTeam || !this.props.userTeam.groups || !this.props.fixtures || !this.props.fixtures.fixtures) {
      return false;
    }

    this.props.userTeam.groups.forEach(group => group.players.forEach(player => {
      this.props.fixtures.fixtures.forEach(fixture => {
        logTrace('Player', player);
        logTrace('Fixture', fixture);

        if (player) {
          if (player.teamId === fixture.host_team.id || player.teamId === fixture.guest_team.id) {
            if (fixture.status !== 'upcoming') {
              shouldShowPoints = true;
            }
          }
        }
      });
    }));


    logTrace('Should show points: ', shouldShowPoints);

    return shouldShowPoints;
  };

  onPointsPressed = () => {
    localStorage.setItem('Dashboard', JSON.stringify(this.state));

    this.props.navigation.navigate('TournamentScoring', {
      id: this.state.subgame.sub_tournament_id
    });
  };

  openReserveDialog = (reserve, reserves) => {
    this.setState({
      reserveDialog: {
        open: true,
        reserve: reserve,
        reserves: reserves
      }
    })
  }

  openCapitanFilter = () => {
    this.setState({ capitanFilterOpen: true, viceFilterOpen: false });
  };

  closeCapitanFilter = (player) => {
    this.setState({
      capitanFilterOpen: false
    });

    if (player) {
      this.setCaptain(player);
    }
  };

  openViceFilter = () => {
    this.setState({ capitanFilterOpen: false, viceFilterOpen: true });
  };

  closeViceFilter = (player) => {
    this.setState({ viceFilterOpen: false });

    if (player) {
      this.setViceCaptain(player)
    }
  };

  openChangePlayerDialog = (player, mode) => {
    this.setState({ selectedPlayerToBeChanged: player });
    this.setState({ openChangePlayerDialog: true });
    if (mode) {
      this.setState({ mode: 'swap' })
    }
  }

  hideChangePlayerDialog = () => {
    this.setState({ selectedPlayerToBeChanged: null });
    this.setState({ mode: null })
  }

  getCapitanPickerButton = () => {
    if (this.props.isTeamLoading) { return null };
    let shouldBeClickable = false
    if (this.props.selectedGame?.is_seasonal_game && this.props.roundSliderInfo) {
      const activeRound = this.props.roundSliderInfo?.find(t => t.is_active);
      if (activeRound) {
        const currentRound = this.props.roundSliderInfo?.find(round => round.number == this.state.selectedRound);
        if (currentRound?.number >= activeRound.number + 1) {
          shouldBeClickable = true;
        } else if (new Date(this.props.selectedGame.start_time) > new Date() && currentRound?.number == activeRound.number) {
          shouldBeClickable = true;
        }
      } else {
        shouldBeClickable = true;
      }
    } else {
      shouldBeClickable = true;
    }
    if (shouldBeClickable) {
      return <TouchableOpacity style={styles.captainVicePickerButton}>
        <View style={styles.pickerButtonContainer} onClick={this.openCapitanFilter}>
          <View style={styles.pickerButtonLeftRow}>
            <View style={styles.pickerButtonPurpleBadge}>
              <Text style={styles.pickerButtonPurpleBadgeText}>C</Text>
            </View>

            <Text
              style={styles.pickerButtonText}>{
                this.state.capitan
                  ? formatPlayersName(this.state.capitan, 'firstFormatted')
                  : translations.tKey('str_enter_game_set_captain')}
            </Text>
          </View>
          <IosArrowDown fontSize="1em" color="#FF4183" style={{ marginRight: '3%' }} />
        </View>
      </TouchableOpacity>
    } else {
      return <View style={styles.captainVicePickerButton}>
        <View style={styles.pickerButtonContainer}>
          <View style={styles.pickerButtonLeftRow}>
            <View style={styles.pickerButtonPurpleBadge}>
              <Text style={styles.pickerButtonPurpleBadgeText}>C</Text>
            </View>

            <Text
              style={styles.pickerButtonText}>{
                this.state.capitan
                  ? formatPlayersName(this.state.capitan, 'firstFormatted')
                  : translations.tKey('str_enter_game_set_captain')}
            </Text>
          </View>
          <IosArrowDown fontSize="1em" color="#FF4183" style={{ marginRight: '3%' }} />
        </View>
      </View>
    }
  }

  getVicePickerButton = () => {
    if (this.props.isTeamLoading) { return null };
    let shouldBeClickable = false
    if (this.props.selectedGame?.is_seasonal_game && this.props.roundSliderInfo) {
      const activeRound = this.props.roundSliderInfo.find(t => t.is_active);
      if (activeRound) {
        const currentRound = this.props.roundSliderInfo?.find(round => round.number == this.state.selectedRound);
        if (currentRound?.number >= activeRound.number + 1) {
          shouldBeClickable = true;
        } else if (new Date(this.props.selectedGame.start_time) > new Date() && currentRound?.number == activeRound.number) {
          shouldBeClickable = true;
        }
      } else {
        shouldBeClickable = true;
      }
    } else {
      shouldBeClickable = true;
    }
    if (shouldBeClickable) {
      return <TouchableOpacity style={styles.captainVicePickerButton}>
        <View style={styles.pickerButtonContainer} onClick={this.openViceFilter}>
          <View style={styles.pickerButtonLeftRow}>
            <View style={styles.pickerButtonPurpleBadge}>
              <Text style={styles.pickerButtonPurpleBadgeText}>VC</Text>
            </View>
            <Text
              style={styles.pickerButtonText}>{
                this.state.vice
                  ? formatPlayersName(this.state.vice, 'firstFormatted')
                  : translations.tKey('str_enter_game_set_vice')}</Text>
          </View>
          <IosArrowDown fontSize="1em" color="#FF4183" style={{ marginRight: '3%' }} />
        </View>
      </TouchableOpacity>
    } else {
      return <View style={styles.captainVicePickerButton}>
        <View style={styles.pickerButtonContainer} >
          <View style={styles.pickerButtonLeftRow}>
            <View style={styles.pickerButtonPurpleBadge}>
              <Text style={styles.pickerButtonPurpleBadgeText}>VC</Text>
            </View>
            <Text
              style={styles.pickerButtonText}>{
                this.state.vice
                  ? formatPlayersName(this.state.vice, 'firstFormatted')
                  : translations.tKey('str_enter_game_set_vice')}</Text>
          </View>
          <IosArrowDown fontSize="1em" color="#FF4183" style={{ marginRight: '3%' }} />
        </View>
      </View>
    }
  }

  schemesDisabled = () => {
    // check the startTime > now() and if so let them click
    let shouldBeClickable = false
    if (this.props.selectedGame?.is_seasonal_game && this.props.roundSliderInfo) {
      const activeRound = this.props.roundSliderInfo.find(t => t.is_active);
      if (activeRound) {
        const currentRound = this.props.roundSliderInfo?.find(round => round.number == this.state.selectedRound);
        if (currentRound?.number >= activeRound.number + 1) {
          shouldBeClickable = true;
        } else if (new Date(this.props.selectedGame.start_time) > new Date() && currentRound?.number == activeRound.number) {
          shouldBeClickable = true;
        }
      } else {
        shouldBeClickable = true;
      }
    } else {
      shouldBeClickable = true;
    }
    return !shouldBeClickable;
  }

  getDimmedCard = (item) => {
    if (!item) {
      return;
    }
    if (item.is_seasonal_game) {
      return this.state.subgame.is_seasonal_game != item.is_seasonal_game
    } else {
      return this.state.subgame.sub_tournament_id != item.sub_tournament_id
    }
  }

  refreshPage = () => {
    this.props.getTeamById(this.state.subgame.sub_tournament_id, this.state.team.user_team_id);
    this.props.getTournamentFixtures(this.state.subgame.tournament_id);
    this.props.getLeaderboard(1, null, this.state.subgame.sub_tournament_id);
  }

  render() {
    const { dashboard } = this.props;
    if (!dashboard || localStorage.getItem("downloadTeam") || localStorage.getItem("changeGameFilter")) {
      return <LoadingScreen />;
    }
   
    let selectedGame = null;
    let teamPoints = 0;

    if (!this.state.subgame && this.props.dashboard.length > 0) {

      if (this.props.navigation.getParam('subId')) {
        selectedGame = this.props.dashboard.find(t => t.sub_tournament_id == this.props.navigation.getParam('subId'));
      } else {
        selectedGame = this.props.dashboard[0];
      }
      if (selectedGame) {
        this.setState({ subgame: selectedGame, team: selectedGame.user_teams[0] });
      } else {
        return <LoadingScreen />;
      }

      return null;
    }
    if (this.props.dashboard.length > 0) {
      if (this.props.navigation.getParam('subId')) {
        selectedGame = this.props.dashboard.find(t => t.sub_tournament_id == this.props.navigation.getParam('subId'));
      } else {
        selectedGame = this.props.dashboard[0];
      }
      if (selectedGame && this.state.team) {
        let selectedTeamFromGame = selectedGame.user_teams.find(team => team.user_team_id == this.state.team.user_team_id);

        if (selectedTeamFromGame) {
          teamPoints = selectedTeamFromGame.points;
        }
      }
    }


    const dropDownStatusData = [
      {
        id: 1,
        value: 'active',
        name: translations.tKey('str_dashboard_active')
      },
      {
        id: 2,
        value: 'current',
        name: translations.tKey('str_dashboard_current')
      },
      {
        id: 3,
        value: 'past',
        name: translations.tKey('str_dashboard_past')
      },
      {
        id: 4,
        value: 'all',
        name: translations.tKey('str_dashboard_all')
      },
    ];

    const isSafari = navigator.vendor && navigator.vendor.indexOf('Apple') > -1 &&
      navigator.userAgent &&
      navigator.userAgent.indexOf('CriOS') == -1 &&
      navigator.userAgent.indexOf('FxiOS') == -1;
    ;
    let timeLeft = 0;
    if (this.state.subgame) {
      if (isSafari) {
        timeLeft = Math.abs((new Date().getTime() - (new Date(this.state.subgame.start_time.replace(' ', 'T')).getTime() + new Date().getTimezoneOffset() * 60 * 1000)) / 1000);
      } else {
        timeLeft = Math.abs((new Date().getTime() - new Date(this.state.subgame.start_time).getTime()) / 1000);
      }
    }

    let activeOrNextToActiveRound = null;
    let hasActive = false;
    dashboard.forEach(t => {
      if (t.is_seasonal_game && t.is_active) {
        hasActive = true;
        activeOrNextToActiveRound = t;
        return;
      }
    });
    if (!hasActive) {
      dashboard.forEach(t => {
        if (t.is_seasonal_game) {
          activeOrNextToActiveRound = t;
          return;
        }
      })
    }

    let filteredTournaments = dashboard.filter((t) => {
      if (t.is_active != true && t.is_seasonal_game) {
        return;
      }
      return t;
    });

    filteredTournaments.unshift(                      // add to the front of the array
      filteredTournaments.splice(                     // the result of deleting items
        filteredTournaments.findIndex(                // starting with the index where
          t => (t.is_active == true && t.is_seasonal_game == true)),
        1)[0]                             // and continuing for one item
    )

    const mappedDashboardCards = filteredTournaments.map((item, index) => {
      // if (item.is_seasonal_game && activeOrNextToActiveRound) return null;
      // if (!item.is_active && item.is_seasonal_game) return null;
      return (
        <DashboardCard
          key={index}
          tournament={item}
          dimmed={this.getDimmedCard(item)}
          viewTeams={this.selectTeam}
          addNewTeam={() => {
            if (this.state.subgame.sub_tournament_id != item.sub_tournament_id) {
              this.selectTeam(item.sub_tournament_id);
            } else {
              this.setState({ subgame: item });

              localStorage.setItem('addNewTeam', true);
              localStorage.setItem('addNewTeamDash', true);
              localStorage.setItem('Dashboard', JSON.stringify(this.state));
              if (item.is_seasonal_game) {
                const activeRound = this.props.roundSliderInfo.find(round => round.is_active);
                const roundToEnter = this.props.roundSliderInfo.find(t => t.number == activeRound.number + 1);
                const tournamentInDashboard = this.props.dashboard.find(t => t.sub_tournament_id == roundToEnter.sub_tournament_id);
                localStorage.setItem("SeasonalGame", tournamentInDashboard.name);
                this.props.navigation.navigate('EnterTournament', {
                  id: roundToEnter.tournament_id,
                  subId: roundToEnter.sub_tournament_id,
                  teamId: activeRound.user_team_id
                });
              } else {
                this.props.navigation.navigate('EnterTournament', {
                  id: this.state.subgame.tournament_id,
                  subId: this.state.subgame.sub_tournament_id
                });
              }
            }
          }}
        />
      );
    });

    return (
      <React.Fragment>
        <ReserveDialog
          open={this.state.reserveDialog.open}
          onClose={() => {
            this.setState({
              reserveDialog: {
                open: false,
                reserve: null
              }
            })
          }}
          reserve={this.state.reserveDialog.reserve}
          reserves={this.state.reserveDialog.reserves}
        />
        <ScrollView
          ref={this.mainRef}
          style={styles.scrollView}
          contentContainerStyle={styles.container}>
          {this.props.dashboard.length != 0 && <PlayerOptionsDialog
            isSeasonal={this.props.fixtures?.isSeasonal ? true : false}
            setAsCapitan={this.setCaptain}
            setAsVice={this.setViceCaptain}
            hideTshirt={this.props.fixtures?.isSeasonal ? true : false}
            hideDelete={this.props.fixtures?.isSeasonal ? true : false}
            hideTransfer={this.props.fixtures?.isSeasonal ? true : false}
            hideSetAsCaptain={(this.state.selectedPlayer?.preferencePosition != null) ? true : false}
            hideSetAsVise={(this.state.selectedPlayer?.preferencePosition != null) ? true : false}
            showChange={this.props.fixtures?.isSeasonal ? true : false}
            showChangePosition={(this.props.fixtures?.isSeasonal && (this.state.selectedPlayer?.preferencePosition != null)) ? true : false}
            playerInfo={(player) => {
              localStorage.setItem('Dashboard', JSON.stringify(this.state));
              this.props.navigation.navigate('PlayerInfoDialog', { id: player.playerId, isSeasonal: this.props.fixtures?.isSeasonal ? true : false });
            }}
            capitanActive={this.state.captainActive}
            viceActive={this.state.viceActive}
            changePlayer={this.openChangePlayerDialog}
            changePlayerPosition={this.openChangePlayerDialog}
            onTransferPlayer={this.transferPlayer}
            player={this.state.selectedPlayer}
            open={this.state.selectedPlayer != null}
            mode={'Dashboard'}
            onClose={this.hidePlayerPopup} />}
          <ChangePlayerDialog
            player={this.state.selectedPlayerToBeChanged}
            open={this.state.openChangePlayerDialog}
            onClose={this.hideChangePlayerDialog}
            team={this.props.userTeam}
            subTournament={this.props.selectedGame}
            mode={this.state.mode}
          />
          <DropDownDialog
            title={translations.tKey('str_select_players_status')}
            items={dropDownStatusData}
            open={this.state.gamesFilterOpen}
            onClose={this.closeGamesFilter}
          />
          {this.props.dashboard.length != 0 && <DropDownDialog
            title={translations.tKey('str_dashboard_select_team')}
            items={this.state.subgame.user_teams}
            open={this.state.teamSelectDialogOpen}
            onClose={this.closeTeamSelectFilter}
          />}
          {this.props.dashboard.length != 0 && <InformationDialog
            title={translations.tKey('str_information_dialog_title')}
            body={this.state.infoDialogContent}
            open={this.state.infoDialogOpen}
            onClose={this.closeInfoDialog}
          />}
          <View style={{
            width: '100%',
            backgroundColor: design.primaryColor,
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center'
          }}>
            <View style={{
              width: '90%',
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              paddingBottom: '20px'
            }}>
              <TouchableOpacity style={styles.pickerButton} onPress={this.openGamesFilter}>
                <View style={styles.pickerButtonContainer}>
                  <View style={styles.pickerButtonLeftRow}>
                    <Text numberOfLines={1} style={styles.pickerButtonText}>{this.state.selectedStatus.name}</Text>
                  </View>
                  <IosArrowDown fontSize="1em" color="#FF4183" style={{ marginRight: '3%' }} />
                </View>
              </TouchableOpacity>
              {this.props.dashboard.length == 0 ? <View
                style={{ width: '100%', display: 'flex', alignItems: 'center', justifyContent: 'center', marginTop: 40 }}>
                <Text style={{ fontSize: 20, color: 'white' }}>{translations.tKey('str_home_empty_state')}</Text>
              </View> :
                !isMobile ? <div
                  ref={this.ref}
                  onMouseDown={this.onMouseDown}
                  onMouseUp={this.onMouseUp}
                  onMouseMove={this.onMouseMove}
                  style={styles.smallImages}>
                  {mappedDashboardCards}
                </div>
                  :
                  <ScrollView
                    horizontal={true}
                    style={styles.scrollView}
                    showsHorizontalScrollIndicator={false}
                    ref={(node) => this.scroll = node}
                  >
                    {mappedDashboardCards}
                  </ScrollView>}
                  <TouchableOpacity style={[styles.myGamesNavigation, styles.myGamesNavigationLeft]} onClick={() => this.moveScrollLeft(this.state.teamIndex, isMobile)} >
                      <Image style={ styles.myGamesNavigationArrow } source={leftArrow}/>
                    </TouchableOpacity>
                    <TouchableOpacity style={[styles.myGamesNavigation, styles.myGamesNavigationRight]} onClick={() => this.moveScrollRight(this.state.teamIndex, isMobile)} >
                      <Image style={ styles.myGamesNavigationArrow} source={rightArrow}/>
                    </TouchableOpacity>
            </View>

          </View>
          {this.props.selectedGame?.is_seasonal_game && this.props.roundSliderInfo && this.props.userTeam && <Pagination
            count={31}
            currentPage={this.props.roundSliderInfo.find(u => u.is_active).number}
            currentRound={this.props.roundSliderInfo.find(u => u.is_active).number}
            actualRoundSelected={this.state.actualRoundSelected}
            isSeasonal={true}
            inputPixels={this.state.inputPixels}
            ContainerStyles={{
              margin: '10px 0'
            }}
            onLeftClick={() => {
              let pixels = this.state.inputPixels;
              pixels = pixels + 60;
              let diff = (this.props.roundSliderInfo.find(u => u.is_active).number) - 8;
              let maxLeft = diff * 60;
              if (pixels > maxLeft) {
                return;
              }
              this.setState({ inputPixels: pixels });
            }}

            onRightClick={() => {
              let pixels = this.state.inputPixels;
              pixels = pixels - 60;

              let diff = (this.props.roundSliderInfo.find(u => u.is_active).number) - 28;
              let maxRight = diff * 60;
              if (pixels < maxRight) {
                return;
              }
              this.setState({ inputPixels: pixels });
            }}
            onPageClick={(page) => {
              let round = this.props.roundSliderInfo.find(t => t.number == page);
              let activeRound = this.props.roundSliderInfo.find(u => u.is_active);
              if (!round) {
                round = this.props.roundSliderInfo[this.props.roundSliderInfo.length - 1];
              }
              if (!activeRound) {
                this.props.roundSliderInfo.forEach(t => {
                  if (t.user_team_id) {
                    activeRound = t;
                    return;
                  }
                })
              }
              if (round && round.user_team_id) {
                const tournamentInDashboard = this.props.dashboard.find(t => t.sub_tournament_id == round.sub_tournament_id);
                if (tournamentInDashboard.user_teams && tournamentInDashboard.user_teams.length > 0) {
                  this.selectTeam(round.sub_tournament_id, round.user_team_id);
                  this.setState({
                    selectedRound: page,
                    actualRoundSelected: round.number
                  });
                } else {
                  localStorage.setItem("SeasonalGame", tournamentInDashboard.name);
                  localStorage.setItem('Dashboard', JSON.stringify(this.state));
                  localStorage.setItem('changeTeam', true);
                  const roundToEnter = this.props.roundSliderInfo.find(t => t.number == activeRound.number + 1);
                  this.props.navigation.navigate('EnterTournament', {
                    id: roundToEnter.tournament_id,
                    subId: roundToEnter.sub_tournament_id,
                    teamId: activeRound.user_team_id
                  });
                }
              } else if (round && !round.user_team_id) {
                const tournamentInDashboard = this.props.dashboard.find(t => t.sub_tournament_id == round.sub_tournament_id);
                localStorage.setItem("SeasonalGame", tournamentInDashboard.name);
                localStorage.setItem('Dashboard', JSON.stringify(this.state));
                localStorage.setItem('changeTeam', true);
                const roundToEnter = this.props.roundSliderInfo.find(t => t.number == activeRound.number + 1);
                this.props.navigation.navigate('EnterTournament', {
                  id: roundToEnter.tournament_id,
                  subId: roundToEnter.sub_tournament_id,
                  teamId: activeRound.user_team_id
                });
              }
              const tournamentInDashboard = this.props.dashboard.find(t => t.sub_tournament_id == round.sub_tournament_id);
              if (tournamentInDashboard.matches_left == 0) {
                this.props.getLeaderboard(1, null, round.sub_tournament_id);
              } else {
                this.props.getLeaderboard(1, null, activeRound.sub_tournament_id);
              }
            }}
          />}


          {this.props.dashboard.length != 0 && <View style={styles.grayButtonContainer}>
            <TouchableOpacity style={styles.grayButton} onPress={() => {
              localStorage.setItem('Dashboard', JSON.stringify(this.state));
              this.props.navigation.navigate('EnterTournamentDetails',
                {
                  id: this.state.subgame.tournament_id,
                  subId: this.state.subgame.sub_tournament_id
                }
              );
            }}>
              <Image style={styles.grayButtonImage} source={infoIcon} />
              <Text style={styles.grayButtonText}>{translations.tKey('str_enter_game_details')}</Text>
            </TouchableOpacity>
            <TouchableOpacity style={styles.grayButton} onPress={() => {
              localStorage.setItem('Dashboard', JSON.stringify(this.state));
              this.props.navigation.navigate('TournamentScoring', { id: this.state.subgame.sub_tournament_id }
              );
            }}>
              <Text style={{ fontSize: 10 }}>⚽</Text>
              <Text style={styles.grayButtonText}>{translations.tKey('str_enter_game_scoring')}</Text>
            </TouchableOpacity>
          </View>}
          {this.props.dashboard.length != 0 && this.state.team && (this.state.subgame.user_teams.find(team => (team.user_team_id == this.state.team.user_team_id))) ?
              <View style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'center' }}>
                <Text style={{ fontWeight: 550, fontSize: 32, marginVertical: 16 }}>{this.props?.fixtures?.isSeasonal ? this.props.userTeam?.name : this.props.userTeam?.name}</Text>
              </View>
            : null}
          {this.props.dashboard.length != 0 && <View style={styles.purpleTeamContainer}>
            {!this.props.userTeam || this.props.isTeamLoading ? <ActivityIndicator size={'large'} color={'white'} /> : this.props.fixtures?.isSeasonal ?
              <View style={styles.pointsPlaceContainer}>
                <View style={[styles.pointsPlaceColumn, styles.pointsPlaceColumnHeadline]}>
                  <Text style={styles.pointsPlaceSmallText}>Точки</Text>
                  <Text style={styles.pointsPlaceSmallText}>Място</Text>

                </View>
                <View style={styles.pointsPlaceColumn}>
                  <Text style={[styles.pointsPlaceSmallText, styles.smallTextColor]}>Кръг</Text>
                  {this.state.team && (
                    <Text style={styles.pointsPlaceSmallText}>
                      {this.state.team.points}
                    </Text>
                  )}
                  <View style={styles.pointsPlaceRow}>
                    {this.state.team && <Text style={styles.pointsPlaceSmallText}>{this.state.team.position}</Text>}
                    {this.state.team && <Text style={[styles.pointsPlaceSmallText, styles.opacity]}>{` / ${this.state.subgame.total_places}`}</Text>}
                  </View>
                </View>
                <View style={styles.pointsPlaceColumn}>
                  <Text style={[styles.pointsPlaceSmallText, styles.smallTextColor]}>Общо</Text>
                  {(this.state.selectedRound == this.state.activeRound?.number) &&
                    <View>
                      <Text style={{...styles.pointsPlaceSmallText, textAlign: "center"}} >
                        {this.state.team.total_points}
                       
                      </Text>
                      <View style={styles.pointsPlaceRow}>
                        {this.state.team && <Text style={styles.pointsPlaceSmallText}>{this.state.team.total_place}</Text>}
                        {this.state.team && <Text
                          style={[styles.pointsPlaceSmallText, styles.opacity]}>{` / ${this.state.subgame.total_places}`}</Text>}
                      </View>
                    </View>
                  }
                  {(this.state.selectedRound != this.state.activeRound?.number) &&
                    <View>
                      <Text style={[styles.pointsPlaceSmallText, styles.totalPointsSeasonal]}>
                        {this.state.team.total_points}
                      </Text>

                      <View style={styles.pointsPlaceRow}>
                        {this.state.team && <Text style={styles.pointsPlaceSmallText}>{this.state.team.total_place}</Text>}
                        {this.state.team && <Text
                          style={[styles.pointsPlaceSmallText, styles.opacity]}>{` / ${this.state.subgame.total_places}`}</Text>}
                      </View>
                    </View>
                  }
                </View>
              </View>
            : null}
            {this.props.fixtures && !this.props.fixtures.isSeasonal && <View style={styles.pointsPlaceContainer}>
              <View style={styles.pointsPlaceColumn}>
                <Text style={styles.pointsPlaceSmallText}>{translations.tKey('str_dashboard_points')}</Text>

                {this.state.team && (
                  <Text style={styles.pointsPlaceBigText}>
                    {this.state.team.points}
                  </Text>
                )}
              </View>
              <View style={styles.pointsPlaceColumn}>
                <Text style={styles.pointsPlaceSmallText}>{translations.tKey('str_dashboard_place')}</Text>
                <View style={styles.pointsPlaceRow}>
                  {this.state.team && <Text style={styles.pointsPlaceBigText}>{this.state.team.position}</Text>}
                  {this.state.team && <Text
                    style={[styles.pointsPlaceBigText, styles.opacity]}>{` / ${this.state.subgame.total_places}`}</Text>}
                </View>
              </View>
            </View>}

            <View style={styles.divider} />
            {this.state.subgame.status == 'upcoming' && <View style={styles.timerContainer}>
              <View style={styles.deadlineContainer}>
                <Text style={styles.deadlineText}>{translations.tKey('str_enter_game_deadline')}</Text>
              </View>
              <View style={styles.timer}>
                <Image style={styles.clockImage} source={clock} />
                <Timer style={styles.timerText} time={timeLeft} onTimerEnded={() => {
                  this.setState({ timeEnded: true });
                }} />
              </View>
            </View>}
            {!!this.props.fixtures?.isSeasonal &&
              <View style={styles.captainViceButtons}>
                {this.getCapitanPickerButton()}

                {this.getVicePickerButton()}

              </View>}
            {this.props.userTeam &&
              <DropDownDialog
                title={translations.tKey('str_enter_game_choose_captain')}
                selected={this.state.capitan}
                items={this.props.userTeam.groups.flatMap(group => {
                  return group.players.map(t => t).filter(player => {
                    return !(!player);
                  }).map(t => {
                    return { ...t, name: formatPlayersName(t, 'firstFormatted') + ', ' + t.team };
                  });
                })}
                open={this.state.capitanFilterOpen}
                onClose={this.closeCapitanFilter}
              />}
            {this.props.userTeam && <DropDownDialog
              title={translations.tKey('str_enter_game_choose_vice')}
              selected={this.state.vice}
              items={this.props.userTeam.groups.flatMap(group => {
                return group.players.map(t => t).filter(player => {
                  return !(!player);
                }).map(t => {
                  return { ...t, name: formatPlayersName(t, 'firstFormatted') + ', ' + t.team };
                });
              })}
              open={this.state.viceFilterOpen}
              onClose={this.closeViceFilter}
            />}

            {!this.props.userTeam || this.props.isTeamLoading ? <ActivityIndicator size={'large'} color={'white'} /> :
              <View style={{ marginBottom: isMobile ? 0 : 50, width: '100%', marginTop: isMobile ? 0 : (this.props.fixtures?.isSeasonal ? 0 : 0) }}>
                <Playground
                  onSelectPlayer={(player) => {
                    if (this.props.fixtures?.isSeasonal || !!this.props.userTeam.reserves?.length > 0) {
                      this.setState({ selectedPlayer: player, captainActive: true, viceActive: true });
                    } else {
                      localStorage.setItem('Dashboard', JSON.stringify(this.state));
                      this.props.navigation.navigate('PlayerInfoDialog', { id: player.playerId,  isSeasonal: this.props.fixtures?.isSeasonal ? true : false });
                    }
                  }}
                  onSelectReserve={(player, reserves) => {
                    if (this.props.fixtures?.isSeasonal || !!this.props.userTeam.reserves?.length > 0) {
                      this.setState({ selectedPlayer: player, captainActive: true, viceActive: true });
                    }
                  }}
                  schemesDisabled={this.schemesDisabled()}
                  showPrice={false}
                  hidePercent={false}
                  showPoints={this.shouldShowPlayerPoints()}
                  onAddPlayer={() => {
                  }}
                  groups={this.props.userTeam.groups}
                  hideNulls={true}
                  isSeasonal={this.props.fixtures?.isSeasonal || !!this.props.userTeam.reserves?.length > 0}
                  reserves={this.props.userTeam.reserves}
                  userTeam={this.props.userTeam}
                  schemes={this.props.userTeam.allSchemes}
                  refresh={this.refreshPage}
                  backgroundStyle={{ backgroundColor: design.primaryColor }} />
              </View>
            }
            {/* <PlayerOptionsDialog
                  setAsCapitan={this.setCaptain}
                  setAsVice={this.setViceCaptain}
                  removePlayer={this.removePlayer}
                  // capitanActive={tournament.active_captains}
                  // viceActive={tournament.active_vice}
                  playerInfo={(player) => this.props.navigation.navigate('PlayerInfoDialog', {id: player.playerId})}
                  onTransferPlayer={this.transferPlayer}
                  player={this.state.selectedPlayer}
                  open={this.state.selectedPlayer != null}
                  onClose={this.hidePlayerPopup}
                  hideSetAsCaptain={this.props.fixtures?.isSeasonal ? true : false}
                  hideSetAsVise={this.props.fixtures?.isSeasonal ? true : false}
              /> */}
            {!this.props.isTeamLoading && (this.state.subgame.status == 'upcoming' && !this.props.selectedGame?.is_seasonal_game && !this.state.timeEnded && this.state.subgame.user_teams.find(team => team.user_team_id == this.state.team.user_team_id))
              && <TouchableOpacity
                onPress={() => {
                  //go to enter tournament with parameter
                  localStorage.setItem('Dashboard', JSON.stringify(this.state));
                  localStorage.setItem('changeTeam', true);
                  if (this.props.selectedGame?.is_seasonal_game) {
                    // pick the last round that has user_team_id
                    let lastRoundWithTeam = null;
                    this.props.roundSliderInfo.forEach(round => {
                      if (round.user_team_id) {
                        lastRoundWithTeam = round;
                      } else {
                        return;
                      }
                    })
                    localStorage.setItem('downloadTeam', JSON.stringify(lastRoundWithTeam));
                    this.setState({ load: true });
                    this.props.getTeamById(lastRoundWithTeam.sub_tournament_id, lastRoundWithTeam.user_team_id);
                    // this.props.navigation.navigate('EnterTournament', {
                    //   id: lastRoundWithTeam.tournament_id,
                    //   subId: lastRoundWithTeam.sub_tournament_id,  
                    // });
                  } else {
                    this.props.navigation.navigate('EnterTournament', {
                      id: this.state.subgame.tournament_id,
                      subId: this.state.subgame.sub_tournament_id
                    });

                  }


                }}
                style={[styles.changeTeamButton, this.props.selectedGame?.is_seasonal_game ? { marginTop: '25px' } : null]}>
                {(((this.props.selectedGame) && (this.props.selectedGame?.is_seasonal_game) == true) || (this.props.userTeam?.is_seasonal_game == true)) && <Text style={styles.changeTeamButtonText}>{translations.tKey('str_dashboard_transfer_team')}</Text>}
                {((!!this.props.selectedGame && !this.props.selectedGame.is_seasonal_game) || (this.props.userTeam?.is_seasonal_game == false)) && <Text style={styles.changeTeamButtonText}>{translations.tKey('str_dashboard_change_team')}</Text>}
              </TouchableOpacity>
            }


            {!this.props.isTeamLoading && (this.props.selectedGame?.is_seasonal_game && !this.state.timeEnded && this.state.subgame.user_teams.find(team => team.user_team_id == this.state.team.user_team_id) && (this.props.selectedGame?.is_seasonal_game && this.props.roundSliderInfo.find(t => t.number == this.props.roundSliderInfo.find(u => u.is_active)?.number + 1)))
              && <TouchableOpacity
                onPress={() => {
                  //go to enter tournament with parameter
                  localStorage.setItem('Dashboard', JSON.stringify(this.state));
                  localStorage.setItem('changeTeam', true);
                  if (this.props.selectedGame.is_seasonal_game) {

                    // pick the last round that has user_team_id
                    let lastRoundWithTeam = null;
                    this.props.roundSliderInfo.forEach(round => {
                      if (round.user_team_id) {
                        lastRoundWithTeam = round;
                      } else {
                        return;
                      }
                    })
                    localStorage.setItem('downloadTeam', JSON.stringify(lastRoundWithTeam));
                    this.setState({ load: true });
                    this.props.getTeamById(lastRoundWithTeam.sub_tournament_id, lastRoundWithTeam.user_team_id);
                    // this.props.navigation.navigate('EnterTournament', {
                    //   id: lastRoundWithTeam.tournament_id,
                    //   subId: lastRoundWithTeam.sub_tournament_id,  
                    // });
                  } else {
                    this.props.navigation.navigate('EnterTournament', {
                      id: this.state.subgame.tournament_id,
                      subId: this.state.subgame.sub_tournament_id
                    });

                  }


                }}
                style={[styles.changeTeamButton, this.props.selectedGame?.is_seasonal_game ? { marginTop: '25px' } : null]}>
                {(((this.props.selectedGame) && (this.props.selectedGame?.is_seasonal_game) == true) || (this.props.userTeam?.is_seasonal_game == true)) && <Text style={styles.changeTeamButtonText}>{translations.tKey('str_dashboard_transfer_team')}</Text>}
                {((!!this.props.selectedGame && !this.props.selectedGame.is_seasonal_game) || (this.props.userTeam?.is_seasonal_game == false)) && <Text style={styles.changeTeamButtonText}>{translations.tKey('str_dashboard_change_team')}</Text>}
              </TouchableOpacity>
            }

            {/* {(!this.props.userTeam?.is_seasonal_game) &&
              <View style={styles.prizepoolRow}>
                <Text style={styles.prizePoolText}>{translations.tKey('str_enter_game_prizepool')}</Text>
                <TouchableOpacity style={styles.prizepoolValueRow}
                  onPress={() => this.setState({ isExpanded: !this.state.isExpanded })}>
                  <Text
                    style={styles.prizepoolValueText}>{`${this.state.subgame.total_prize_pool.toFixed(2)}${translations.tKey('str_currency')}`}</Text>

                  {this.state.isExpanded ?
                    <IosArrowUp fontSize="1.5em" style={{ fontWeight: 'bold' }} color="white" />
                    :
                    <IosArrowDown fontSize="1.5em" style={{ fontWeight: 'bold' }} color="white" />
                  }
                </TouchableOpacity>

              </View>
            } */}

          </View>}
          {(this.state.isExpanded && this.props.dashboard.length != 0) &&
            <TournamentPrizes prizes={this.state.subgame.prize_pool} forDashboard={true} />}
          {this.props.dashboard.length != 0 && <View style={styles.fixturesContainer}>

            {!this.props.fixtures
              ? (
                <ActivityIndicator size={'large'} color={design.primaryColor} />
              ) : (
                <View style={styles.fixturesColumn}>
                  <Text style={{ ...styles.fixturesText, marginVertical: 15 }}>
                    {translations.tKey('str_details_fixtures')}
                  </Text>
                  {(this.props.fixtures.round) && <Text style={{ ...styles.fixturesText }}>
                    {translations.tKey('str_details_round')} {this.props.fixtures.round}
                  </Text>}


                  <TournamentFixtures
                    selected={this.state.selectedMatch}
                    fixtures={this.props.fixtures.fixtures}
                    onSelected={this.onFixtureSelected}
                    onPointsPressed={this.onPointsPressed}
                  />
                </View>
              )}

          </View>}
          {/* {this.props.dashboard.length != 0 && (
            <View style={styles.sectionHeader}>
              <Text style={styles.teamsText}>{translations.tKey('str_dashboard_teams')}</Text>
              <Text style={{fontSize: 16, fontWeight: "bold", position: "absolute", right: "41px"}}>{translations.tKey('str_legend')}</Text>
              <IconInformationDialog IconStyles={{ ...styles.legendIcon, ...styles.clickable }} isSeasonal={this.props.selectedGame?.is_seasonal_game}/>
            </View>
          )}
          {this.props.dashboard.length != 0 && <View style={styles.teamsContainer}>
            <UserTeamsTable
              onInfoClicked={() => this.setState({
                infoDialogOpen: true,
                infoDialogContent: translations.tKey('str_tooltip_points_pp')
              })}
              onTeamClicked={(team) => {
                this.props.getRoundSliderInfo(this.state.subgame.sub_tournament_id, team.user_team_id);

                this.setState({ teamSelectDialogOpen: false, team });
                this.props.getTeamById(this.state.subgame.sub_tournament_id, team.user_team_id);

              }}
              teams={this.state.subgame.user_teams}
              selected={this.props.userTeam}
              subgame={this.state.subgame}
            />
          </View>} */}
          {this.props.dashboard.length != 0 ? 
          <TournamentLeaderboard
            onInfoClicked={() => this.setState({
              infoDialogOpen: true,
              infoDialogContent: translations.tKey('str_tooltip_points_pp')
            })}
            onTeamClicked={(team) => {
              const teamInState = this.state.subgame.user_teams.find(t => t.user_team_id == team.user_team_id);
              if (this.state.subgame.status == 'upcoming') {
                if (teamInState) {
                  this.setState({ teamSelectDialogOpen: false, team: teamInState ? teamInState : team });
                } else {
                  this.setState({ infoDialogOpen: true, infoDialogContent: translations.tKey('str_info_team_before_start') });
                  return;
                }
              } else {
                
                this.setState({ teamSelectDialogOpen: false, team: teamInState ? teamInState : team });
              }
              
              this.props.getTeamById(this.state.subgame.sub_tournament_id, team.user_team_id);
            }
            }
            onNextRequest={(page, name, sort) => {
              localStorage.setItem('shouldScroll', true);
             
              if (this.state.activeRound && this.state.activeRound.is_active && this.state.subgame.is_seasonal_game) {
                this.props.getLeaderboard(page, name, this.state.activeRound.sub_tournament_id, sort.name, sort.sort);
              } else {
                this.props.getLeaderboard(page, name, this.state.subgame.sub_tournament_id, sort.name, sort.sort);
              }
            }}
            isSeasonal={this.props.selectedGame?.is_seasonal_game}
            selected={this.props.fixtures?.isSeasonal ? this.props.userTeam : this.props.userTeam}
            isLoading={this.props.isLeaderBoardLoading}
            tournamentId={this.state.subgame.sub_tournament_id}
            data={this.props.leaderboard} /> : null}
        </ScrollView>
      </React.Fragment>
    );
  }
}

const mapDispatchToProps = (dispatch, ownProps) => {
  return {
    getUserDashboard: (status, id) => dispatch(getUserDashboard(status, id)),
    getTournamentFixtures: id => dispatch(getTournamentFixtures(id)),
    getLeaderboard: (page, name, id, sortName, sortType) => dispatch(getLeaderboard(page, name, id, sortName, sortType)),
    getTeamById: (subTournamentId, teamId) => dispatch(getTeamById(subTournamentId, teamId)),

    getHomeTournamentsInfo: (status, championshipId) => dispatch(getHomeTournamentsInfo(status, championshipId)),
    clearTournamentForTransfer: () => dispatch(clearTournamentForTransfer()),
    setSelectedItems: (selectedGame, selectedTeam) => dispatch(setSelectedItems(selectedGame, selectedTeam)),
    deleteSelectedItems: () => dispatch(deleteSelectedItems()),
    editTeam: team => dispatch(editTeam(team)),
    setCaptain: (id, playerId, teamId) => dispatch(setCaptain(id, playerId, teamId)),
    setViceCaptain: (id, playerId, teamId) => dispatch(setViceCaptain(id, playerId, teamId)),
    setPlayerCaptain: (playerId) => dispatch(setPlayerCaptain(playerId)),
    setPlayerViceCaptain: (playerId) => dispatch(setPlayerViceCaptain(playerId)),
    getRoundSliderInfo: (subTournamentId, userTeamId) => dispatch(getRoundSliderInfo(subTournamentId, userTeamId))
  };
};

const mapStateToProps = (state, ownProps) => {
  return {
    dashboard: state.dashboard.dashboard,
    fixtures: state.tournamentDetails.fixtures,
    leaderboard: state.dashboard.leaderboard,
    selectedTeam: state.dashboard.selectedTeam,
    selectedGame: state.dashboard.selectedGame,
    userTeam: state.userTeam.userTeam,
    isLoading: state.ui.isLoading,
    isTeamLoading: state.ui.isTeamLoading,
    isLeaderBoardLoading: state.ui.isLeaderBoardLoading,
    homeInfo: state.homeTournaments.tournamentsInfo.tournaments,
    roundSliderInfo: state.dashboard.roundSliderInfo
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Dashboard);

const operator = getDomainOperator();

const design = operator.design;

const base = {
  clickable: {
    cursor: 'pointer'
  },

  sectionHeader: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    position: 'relative',
    width: '100%',
    marginVertical: 20
  },

  legendIcon: {
    position: 'absolute',
    right: 15,
    top: '50%',
    transform: 'translateY(-50%)'
  },

  scrollView: {
    flex: 1,
    width: '100%'
  },
  container: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    width: '100%'
  },
  buttonsRow: {
    width: '100%',
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
    marginVertical: '10px'
  },
  pickerButton: {
    width: '100%'
  },
  pickerButtonContainer: {
    display: 'flex',
    width: '100%',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
    border: `1px solid ${design.primaryColor}`,
    paddingVertical: '15px',
    borderRadius: 5,
    marginVertical: 20,
    backgroundColor: 'white'
  },
  pickerButtonLeftRow: {
    display: 'flex',
    flexDirection: 'row',
    width: '84%',
    marginLeft: '3%',
    alignItems: 'center',
    justifyContent: 'flex-start'
  },
  pickerButtonPurpleBadge: {
    backgroundColor: design.primaryColor,
    borderRadius: 4,
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'center',
    paddingVertical: '4px',
    paddingHorizontal: '5px',
    marginRight: '5px'
  },
  pickerButtonPurpleBadgeText: {
    color: 'white',
    fontSize: 8
  },
  pickerButtonText: {
    fontSize: 14,
    fontWeight: 550
  },
  grayButtonContainer: {
    display: 'flex',
    width: '90%',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
    marginVertical: '20px'
  },
  grayButton: {
    width: '48%',
    paddingVertical: '7px',
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'center',
    backgroundColor: '#EFEFEF',
    borderRadius: 4
  },
  grayButtonText: {
    fontWeight: 550,
    marginLeft: 5
  },
  grayButtonImage: {
    width: 12,
    height: 12,
    resizeMode: 'cover'
  },
  timer: {
    backgroundColor: design.tertiaryColor,
    padding: '5px',
    paddingLeft: '7px',
    paddingRight: '7px',
    width: '45%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-evenly',
    flexDirection: 'row'
  },
  deadlineContainer: {
    border: `1px solid ${design.tertiaryColor}`,
    padding: '5px',
    paddingLeft: '7px',
    paddingRight: '7px',
    width: '45%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-evenly',
    flexDirection: 'row'
  },
  deadlineText: {
    fontSize: 14,
    fontWeight: 'bold',
    color: '#FF085D'
  },
  timerText: {
    fontSize: 16,
    fontWeight: 550
  },
  clockImage: {
    marginTop: '1px',
    width: 13,
    height: 13,
    resizeMode: 'cover'
  },
  purpleTeamContainer: {
    width: '100%',
    backgroundColor: design.primaryColor,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    paddingVertical: 20
  },
  pointsPlaceContainer: {
    width: '80%',
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'center'
  },
  pointsPlaceColumn: {
    width: '50%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center'
  },
  seasonalTotalPointsContainer: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'center'
  },
  totalPointsSeasonal: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'center'
  },
  pointsPlaceColumnHeadline: {
    top: 10,
    left: 30,
    display: 'inline-grid'
  },
  pointsPlaceSmallText: {
    color: 'white',
    fontSize: 16
  },
  smallTextColor: {
    opacity: 0.3
  },
  pointsPlaceBigText: {
    color: 'white',
    fontSize: 26,
    fontWeight: 'bold'
  },
  opacity: {
    opacity: 0.3
  },
  pointsPlaceRow: {
    width: '100%',
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'center'
  },
  divider: {
    width: '100%',
    height: '1px',
    backgroundColor: 'white',
    opacity: 0.3,
    marginVertical: 20
  },
  timerContainer: {
    width: '60%',
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'center',
    marginBottom: 20
  },
  changeTeamButton: {
    width: '80%',
    backgroundColor: design.secondaryColor,
    borderRadius: 3,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    paddingVertical: 12,
    marginTop: 30
  },
  changeTeamButtonText: {
    fontWeight: 'bold',
    fontSize: 14
  },
  fixturesContainer: {
    width: '100%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center'
  },
  fixturesColumn: {
    width: '100%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    marginTop: '1em'
  },
  fixturesText: {
    fontWeight: 'bold',
    fontSize: 24
  },
  teamsText: {
    fontWeight: 'bold',
    fontSize: 24
  },
  teamsContainer: {
    marginBottom: 20,
    width: '100%'
  },
  prizepoolRow: {
    width: '70%',
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
    marginTop: 20
  },
  prizepoolValueRow: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'center'
  },
  prizepoolValueText: {
    color: 'white',
    fontSize: 20,
    fontWeight: 'bold'
  },
  prizePoolText: {
    color: 'white',
    fontSize: 16
  },
  arrowDown: {
    width: 20,
    height: 20,
    resizeMode: 'cover',
    backgroundColor: 'white'
  },
  smallImages: {
    width: '100%',
    height: 300,
    marginRight: '20px',
    display: 'flex',
    flexDirection: 'row',
    overflow: 'hidden',
    flex: 1
  },
  captainViceButtons: {
    width: '90%',
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
    marginVertical: '10px'
  },
  captainVicePickerButton: {
    width: '48%'
  },
  myGamesNavigation: {
    position: 'absolute',
    top: '210px',
    width: '32px',
    height: '32px',
  },
  myGamesNavigationArrow: {
    height: '100%',
    },
  myGamesNavigationLeft: {
    left: '8px',
  },
  myGamesNavigationRight: {
    right: '8px',
  }
};

const styles = createStyles(
  base,
  maxWidth(576, {
    changeTeamButton: {
      marginTop: 0
    }
  }),
  maxWidth(376, {
    changeTeamButton: {
      marginTop: 0
    }
  })
);