import React, { Component } from "react";
import arrowPrevPage from "../../assets/images/back_arrow.png";
import arrowPrevPageDisabled from "../../assets/images/back_arrow_disabled.png";
import arrowNextPage from "../../assets/images/forward_arrow.png";
import arrowNextPageDisabled from "../../assets/images/forward_arrow_disabled.png";
import arrowFirstPage from "../../assets/images/rewind_arrow.png";
import arrowFirstPageDisabled from "../../assets/images/rewind_arrow_disabled.png";
import arrowLastPage from "../../assets/images/fforward_arrow.png";
import arrowLastPageDisabled from "../../assets/images/fforward_arrow_disabled.png";
import { View, Text, StyleSheet, Image, TouchableOpacity, TextInput } from "react-native";
import { createStyles, maxWidth } from 'react-native-media-queries';
import * as translations from "../../Common/utils/translations"

class Pagination extends Component {

    render() {
        const { data, changePageTo } = this.props;
        if (!data) return null
        return (
            <View style={styles.container}>
                <TouchableOpacity disabled={data.current_page == 1 || data.last_page == 0} onPress={() => changePageTo(1)}>
                    <View style={data.current_page == 1 ? styles.imageButtonDisabled : styles.imageButton}>
                        <Image style={styles.image} source={data.current_page == 1 || data.last_page == 0 ? arrowFirstPageDisabled : arrowFirstPage} />
                    </View>
                </TouchableOpacity>
                <TouchableOpacity disabled={data.current_page == 1 || data.last_page == 0} onPress={() => changePageTo(+data.current_page - 1)}>
                    <View style={data.current_page == 1 || data.last_page == 0 ? styles.imageButtonDisabled : styles.imageButton}>
                        <Image style={styles.image} source={data.current_page == 1 ? arrowPrevPageDisabled : arrowPrevPage} />
                    </View>
                </TouchableOpacity>
                <View style={styles.textContainer}>
                    <Text style={styles.textCurrentpage}>{data.last_page == 0 ? 0 : data.current_page}</Text>
                    {/* <Text style={styles.textCurrentpage}>{` ${translations.tKey("str_pagination_from")} ${data.last_page}`}</Text> */}
                    <Text style={styles.textCurrentpage}>{` to ${data.last_page}`}</Text>
                </View>

                <TouchableOpacity disabled={data.current_page == data.last_page || data.last_page == 0} onPress={() => changePageTo(+data.current_page + 1)}>
                <View style={data.current_page == data.last_page || data.last_page == 0 ? styles.imageButtonDisabled : styles.imageButton}>
                        <Image style={styles.image} source={data.current_page == data.last_page || data.last_page == 0 ? arrowNextPageDisabled : arrowNextPage} />
                    </View>
                </TouchableOpacity>
                <TouchableOpacity disabled={data.current_page == data.last_page || data.last_page == 0} onPress={() => changePageTo(+data.last_page)}>
                <View style={data.current_page == data.last_page || data.last_page == 0 ? styles.imageButtonDisabled : styles.imageButton}>
                        <Image style={styles.image} source={data.current_page == data.last_page || data.last_page == 0 ? arrowLastPageDisabled : arrowLastPage} />
                    </View>
                </TouchableOpacity>
            </View>
        )
    }
}

export default Pagination;

const base = {
    container: {
        display: "flex",
        flexDirection: "row",
        alignItems: "center",
        justifyContent: "center"
    },
    textCurrentpage: {
        fontSize: 14,
        fontWeight: 'bold'
    },
    text: {
        fontSize: 14
    },
    textContainer: {
        display: 'flex',
        flexDirection: "row",
        alignItems: "center",
        justifyContent: "center",
        marginHorizontal: "10px"
    },
    image: {
        width: 11,
        height: 11,
        resizeMode: "contain"
    },
    imageButton: {
        marginHorizontal: "5px",
        padding: "10px",
        borderRadius: "5px",
        borderColor: "#EFEFEF",
        border: "1px solid"
    },
    imageButtonDisabled: {
        backgroundColor: "#EFEFEF",
        marginHorizontal: "5px",
        padding: "10px",
        borderRadius: "5px"
    }

};

const styles = createStyles(base);