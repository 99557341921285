import React, { useEffect } from 'react';
import PropTypes from 'prop-types';

import InformationDialog from '../../HomeTournaments/components/InformationDialog';
import { Text, TouchableOpacity, View } from 'react-native';
import { useDispatch } from 'react-redux';
import { authGetToken } from '../actions/token';
import { BASE_URL } from '../config/config';
import PlayerView from './PlayerView';
import { getDomainOperator } from '../utils/domainMapper';


export const ReserveDialog = (props) => {
  const dispatch = useDispatch();

  const [possibleChanges, setPossibleChanges] = React.useState();
  const [swapType, setSwapType] = React.useState('');

  const onClose = () => {
    setPossibleChanges(null);

    props.onClose();
  };

  const onSwapClick = async () => {
    const authToken = await dispatch(authGetToken());

    const res = await fetch(BASE_URL + `/users/team/player/${props.reserve?.id}/info`, {
      credentials: 'include',
      headers: {
        'Content-Type': 'application/json; charset=utf-8',
        'Authorization': authToken
      }
    });

    if (res.ok) {
      setPossibleChanges((await res.json()).data.possibleChanges);
    } else {
      onClose();

      alert('An error occurred while trying to fetch the reserve info');
    }
  };

  return (
    <InformationDialog
      title={props.reserve?.name}
      open={props.open}
      showIcon={false}
      showConfirmButton={false}
      shouldClearFixtures={false}
      onClose={onClose}
      style={{ maxWidth: '400px' }}
    >
      {!!possibleChanges && (
        <div>
          {possibleChanges.map(change => (
            <div
              onClick={async () => {
                const authToken = await dispatch(authGetToken());

                if (swapType === 'preference') {
                  await fetch(BASE_URL + '/users/team/preferenceSwap', {
                    credentials: 'include',
                    method: 'POST',
                    headers: {
                      'Content-Type': 'application/json; charset=utf-8',
                      'Authorization': authToken
                    },
                    body: JSON.stringify({
                      player_one_id: props.reserve?.id,
                      player_two_id: change.id
                    })
                  });
                } else {
                  await fetch(BASE_URL + '/users/team/swap', {
                    credentials: 'include',
                    method: 'POST',
                    headers: {
                      'Content-Type': 'application/json; charset=utf-8',
                      'Authorization': authToken
                    },
                    body: JSON.stringify({
                      player_in_id: props.reserve?.id,
                      player_out_id: change.id
                    })
                  });
                }

                onClose();
              }}
              style={{ padding: 10, backgroundColor: 'yellow', marginBottom: 10 }}
            >
              <p>Смени с {change.name}</p>
            </div>
          ))}
        </div>
      )}

      <TouchableOpacity
        onPress={onSwapClick}
        style={styles.button}
      >
        <Text style={styles.buttonText}>Смени с титуляр</Text>
      </TouchableOpacity>

      {!!props.reserve?.preferencePosition && (
        <TouchableOpacity
          onPress={() => {
            setSwapType('preference');

            setPossibleChanges(props.reserves?.filter(x => (x.id !== props.reserve.id && x.preferencePosition !== 0)).filter(x => x.positionId !== 0));
          }}
          style={styles.button}
        >
          <Text style={styles.buttonText}>Смени позиция</Text>
        </TouchableOpacity>
      )}
    </InformationDialog>
  );
};

ReserveDialog.propTypes = {
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,

  reserve: PropTypes.shape({
    id: PropTypes.number.isRequired,
    name: PropTypes.string.isRequired,
    preferencePosition: PropTypes.number.isRequired
  }).isRequired
};

const operator = getDomainOperator();

const design = operator.design;

const styles = {
  button: {
    width: '80%',
    backgroundColor: design.secondaryColor,
    borderRadius: 3,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    paddingVertical: 12,
    marginTop: 10
  },
  buttonText: {
    fontWeight: 'bold',
    fontSize: 14
  }

};

export default ReserveDialog;