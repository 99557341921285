import React from 'react';
import { StyleSheet, View } from 'react-native';
import { getDomainOperator } from '../utils/domainMapper';

const background = (props) => <View style={styles.container}/>;

export default background;

const operator = getDomainOperator();

const design = operator.design;

const styles = StyleSheet.create({
  container: {
    flex: 1,
    zIndex: 0,
    top: 0,
    left: 0,
    position: 'absolute',
    width: '100%',
    maxWidth: 700,
    height: '100%',
    backgroundColor: design.primaryColor
  }
});