import React from "react";
import { View, StyleSheet, Image } from "react-native";
import { withRouter } from "react-router-dom";
import topImage from '../assets/header_palmsbet.png';

const TopNav = ({ history }) => {
  return (
    <View style={styles.main}>
      <Image source={topImage} style={{
          width: '100%',
          height: 140}} />
    </View>
  );
};

const styles = StyleSheet.create({
  main: {
    backgroundColor: "#cccccc",
    width: '100%'
  }
});

export default withRouter(TopNav);
