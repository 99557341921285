import React from 'react';

import Dialog from '@material-ui/core/Dialog';
import { isMobile } from "react-device-detect";
import { View, Text, TouchableOpacity, } from "react-native";
import { createStyles, maxWidth, maxHeight } from 'react-native-media-queries';
import { connect } from 'react-redux';
import * as translations from "../../Common/utils/translations"
import { hideConfirmModal } from "../actions/confirmPopup";
import { getDomainOperator } from '../../Common/utils/domainMapper';

const operator = getDomainOperator();

const design = operator.design;

const base = {
    title: {
        backgroundColor: design.primaryColor,
        height: "64px",
        width: isMobile ? "80%" : "22%",
        display: 'flex',
        alignItems: "center",
        justifyContent: "center",
        position: "fixed",
        zIndex: 9999
    },
    titleText: {
        color: "white",
        fontSize: 20,
        fontWeight: 550
    },
    exitButton: {
        backgroundColor: "#FF085D",
        width: 22,
        height: 22,
        position: "absolute",
        paddingBottom: 5,
        top: 1,
        right: 1,
        display: 'flex',
        alignItems: 'center',
        justifyContent: "center",
        zIndex: 9999,
    },
    exitButtonText: {
        color: "white",
        fontWeight: 'bold',
        fontSize: 15
    },
    playButton: {
        width: "70%",
        backgroundColor: design.secondaryColor,
        paddingTop: "10px",
        paddingBottom: "10px",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        borderRadius: "4px"
    },
    playButtonText: {
        fontSize: 14,
        fontWeight: "bold"
    },
    bodyContainer: {
        marginTop: "70px",
        display: 'flex',
        alignItems: "center",
        justifyContent: 'center',
        width: "100%",
        padding: "20px"
    },
    infoCircle: {
        width: 68,
        height: 68,
        borderRadius: 32,
        border: `3px solid ${design.secondaryColors}`,
        display: 'flex',
        alignItems: "center",
        justifyContent: "center",
        marginBottom: 50
    },
    infoCircleText: {
        color: design.secondaryColor,
        fontSize: 45
    },
    messageText: {
        fontSize: isMobile ? 18 : 24,
        textAlign: "center",
        marginBottom: "40px",
        maxWidth: '100%'
    }
};

class ConfirmModal extends React.Component {

    state = {
        open: false
    };

    handleOpen = () => {
        this.setState({ open: true });
    };

    handleClose = () => {
        this.props.hideConfirmModal();
    };

    handleConfirm = () => {
        this.props.hideConfirmModal();
        if (this.props.confirmAction) {
            this.props.confirmAction();
        }
    };

    componentWillReceiveProps(nextProps) {
        this.setState({ open: nextProps.show });
    }

    render() {

        return (
            <Dialog
                onClose={this.props.handleClose}
                aria-labelledby="dialog-title"
                open={this.state.open}

                PaperProps={{
                    style: {
                        width: isMobile ? "80%" : "22%",
                    },
                }}

                style={{ maxHeight: 500 }}>
                <View style={styles.title}>
                    <TouchableOpacity style={styles.exitButton}
                        onPress={this.handleClose}>
                        <Text style={styles.exitButtonText}>x</Text></TouchableOpacity>
                    <Text style={styles.titleText}>{this.props.title ? this.props.title : ""}</Text>

                </View>

                <View style={styles.bodyContainer}>
                    <View style={styles.infoCircle}>
                        <Text style={styles.infoCircleText}>!</Text>
                    </View>
                    <Text style={styles.messageText}>{this.props.message}</Text>
                    <div style={styles.playButton}
                        onClick={this.handleConfirm}>
                        <TouchableOpacity  >
                            <Text style={styles.playButtonText}>{translations.tKey("str_info_got_it")}</Text>
                        </TouchableOpacity>
                    </div>
                </View>
            </Dialog>
        )
    }
}


const mapStateToProps = state => {
    return {
        show: state.confirmModal.show,
        message: state.confirmModal.message,
        title: state.confirmModal.title,
        confirmAction: state.confirmModal.confirmAction
    }
};

const mapDispatchToProps = dispatch => {
    return {
        hideConfirmModal: () => dispatch(hideConfirmModal()),
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(ConfirmModal);

const styles = createStyles(base);