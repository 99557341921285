import { GET_TOURNAMENTS_HOME_INFO, GET_ALL_CHAMPIONSHIPS } from "./actionTypes";
import { BASE_URL } from '../../Common/config/config';
import { authGetToken } from '../../Common/actions/token';
import { setError } from '../../Common/actions/errors';
import { uiStartLoading, uiStopLoading } from "../../Common/actions/ui";
import { showConfirmPopup } from "../../ConfirmPopup/actions/confirmPopup"
import * as translations from "../../Common/utils/translations";

export const getHomeTournamentsInfo = (status, championship_id, page) => {
  return dispatch => {
    dispatch(uiStartLoading())
    dispatch(authGetToken())
      .catch(err => {
        dispatch(uiStopLoading())
        console.log('No auth token exists!');
      })
      .then(token => {
        let params = '';
        if (!status) {
          status = "current";
        }
        params += `?status=${status}`;
        if (championship_id) {
          params += `&championship_id=${championship_id}`
        }
        params += `&page=${page}`
        return fetch(BASE_URL + '/home/tournaments' + params, {
          credentials: 'include',
          headers: {
            "Content-Type": "application/json; charset=utf-8",
            "Authorization": token
          }
        });
      })
      .catch(err => {
        dispatch(uiStopLoading())
        console.log(err);
      })
      .then(res => res.json())
      .then(res => {
        dispatch(uiStopLoading())
        if (res.has_error) {
          dispatch(showConfirmPopup(translations.tKey("str_error"), res.message, null));
        } else {
          res.data.tournaments = res.data.data;
            // res.data.data.forEach(tournament => {
            //     if(tournament.is_seasonal_game) {
            //       res.data.data.pop(tournament);
            //       res.data.data.unshift(tournament)
            //     }
            // })
          res.data.tournaments.forEach(item => item.status = status)
          res.data.counts = res.data.count
          dispatch(setHomeTournamentsInfo(res.data))
        }
      });
  };
}

export const setHomeTournamentsInfo = info => {
  return {
    type: GET_TOURNAMENTS_HOME_INFO,
    info
  };
};


export const getAllChampionShips = () => {
  return dispatch => {
    dispatch(authGetToken())
      .catch(err => {
        console.log('No auth token exists!');
      })
      .then(token => {
        return fetch(BASE_URL + '/championships/all', {
          credentials: 'include',
          headers: {
            "Content-Type": "application/json; charset=utf-8",
            "Authorization": token
          }
        });
      })
      .catch(err => console.log(err))
      .then(res => res.json())
      .then(res => {
        if (res.has_error) {
          dispatch(showConfirmPopup(translations.tKey("str_error"), res.message, null));
        } else {
          dispatch(setAllChampionships(res.data));
        }
      });
  };
}


export const setAllChampionships = championships => {
  return {
    type: GET_ALL_CHAMPIONSHIPS,
    championships
  };
};