import React from 'react';
import LoadingScreen from '../../components/LoadingScreen';
import { StyleSheet, View, Text } from 'react-native';
import { BASE_URL } from '../../config/config';
import { authGetToken } from '../../actions/token';
import { connect } from 'react-redux';
import { setError } from '../../actions/errors';

class Maintenance extends React.Component {

    componentDidMount() {
        this.id = setInterval(() => {
            fetch(BASE_URL + '/palms/home', {
                credentials: 'include',
                headers: {
                    "Content-Type": "application/json; charset=utf-8",
                    "Authorization": this.props.token || localStorage.getItem('token')
                }
            })
            .catch(err => {
                console.log(err);
            })
            .then(res => res.json())
            .then(res => {
                if (!res.has_error) {
                    this.props.turnMaintenanceOff();
                    this.props.navigation.navigate('Home')
                }
            })
        }, 2000);
    }

    componentWillUnmount() {
        clearInterval(this.id);
    }

    render() { 
        return ( 
            <View style={{flex: 1, justifyContent: 'space-around'}}>
                <LoadingScreen /> 
                <Text style={styles.headText}>В момента се извършва затваряне на текущия кръг!</Text>
                <Text style={[styles.headText, {marginTop: 60}]}>Ще бъдете прехвърлени автоматично към основния екран, след като изчисленията завършат!</Text>
            </View>
        );
    }
}

const styles = StyleSheet.create({
    headText: {        
        color: 'white',
        fontWeight: 'bold',
        textAlign: 'center',
        width: '100%',
        fontSize: 22,
        zIndex: 500
    }
});

const mapDispatchToProps = (dispatch, ownProps) => {
    return {
        turnMaintenanceOff: () => dispatch(setError(null, null))
    }
}

const mapStateToProps = (state, ownProps) => {
    return {
        token: state.auth.token
    }
}
export default connect(mapStateToProps, mapDispatchToProps)(Maintenance);