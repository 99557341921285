import React from 'react';
import { View, Text, StyleSheet, Image, TouchableOpacity, ScrollView } from 'react-native';
import { BASE_URL } from '../../Common/config/config';
import clock from '../../assets/images/clock_black.svg';
import { connect } from 'react-redux';
import Timer from '../../Common/components/Timer';
import { createStyles, maxWidth } from 'react-native-media-queries';
import { numberWithCommas } from '../../Common/utils/utils';
import * as translations from '../../Common/utils/translations';
import { isMobile } from 'react-device-detect';
import PrizePoolIcon from '../../assets/icons/ic_prizepool.svg';
import IcPrizepool from "../../Common/components/Icons/IcPrizepool";
import { getDomainOperator } from '../../Common/utils/domainMapper';

class DashboardCard extends React.Component {

  state = {
    openFixturesDialog: false
  };

  calculateProgressBarStyle = (subgame) => {

    var percentFilled = this.calculatePercent(subgame);

    const style = {};
    style.width = `${percentFilled}%`;
    style.backgroundColor = this.calculaterRGB(percentFilled / 100);
    style.height = '5px';
    style.borderRadius = '2px';
    return style;
  };

  calculatePercent = (subgame) => {
    var percentFilled = null;
    if (subgame.matches_left == 0) {
      percentFilled = 100;
    } else {
      percentFilled = ((subgame.all_matches - subgame.matches_left) / subgame.all_matches) * 100;
    }
    return percentFilled;
  };

  calculaterRGB = (pct) => {

    const minR = 55;
    const maxR = 255;
    const minG = 0;
    const maxG = 8;
    const minB = 60;
    const maxB = 93;
    const targetR = minR + ((maxR - minR) * pct);
    const targetG = minG + ((maxG - minG) * pct);
    const targetB = minB + ((maxB - minB) * pct);
    return 'rgb(' + [targetR, targetG, targetB].join(',') + ')';
  };


  render() {
    const {navigation, tournament} = this.props;
    if (!tournament) return null;

    const isSafari = navigator.vendor && navigator.vendor.indexOf('Apple') > -1 &&
      navigator.userAgent &&
      navigator.userAgent.indexOf('CriOS') == -1 &&
      navigator.userAgent.indexOf('FxiOS') == -1;
    ;
    let timeLeft = 0;
    if (isSafari) {
      timeLeft = Math.abs((new Date().getTime() - (new Date(tournament.start_time.replace(' ', 'T')).getTime() + new Date().getTimezoneOffset() * 60 * 1000)) / 1000);
    } else {
      timeLeft = Math.abs((new Date().getTime() - new Date(tournament.start_time).getTime()) / 1000);
    }

    const pricePool = tournament.total_prize_pool ? numberWithCommas(tournament.total_prize_pool.toFixed(2)) + translations.tKey('str_currency') : '';
    const isSeasonalGame = tournament.is_seasonal_game;
    return (

      <View style={{minHeight: '250px', width: '330px', marginHorizontal: 10, cursor: 'pointer'}}>
        <div style={styles.card} onClick={() => {
          this.props.viewTeams(tournament.sub_tournament_id);
        }}>
          {this.props.dimmed && <View style={{
            position: 'absolute',
            width: '100%',
            height: '90%',
            opacity: '0.5',
            backgroundColor: 'black',
            zIndex: 999
          }}/>}
          <View style={styles.content}>
            <View style={styles.flagNameRow}>
              <View style={{
                width: '20%',
                display: 'flex',
                flexDirection: 'row',
                alignItems: 'center',
                justifyContent: 'center'
              }}>
                <Image style={styles.image} source={BASE_URL + tournament.flag.url}/>
              </View>
              <View style={{
                width: '75%',
                display: 'flex',
                alignItems: 'flex-start',
                justifyContent: 'center',
                marginLeft: '5%'
              }}>
                <View style={{
                  width: '100%',
                  display: 'flex',
                  flexDirection: 'row',
                  alignItems: 'center',
                  justifyContent: 'flex-start'
                }}>
                  {tournament.is_seasonal_game  && (
                    <div style={styles.deadlineContainer}>
                      <p style={styles.deadlineText}>{translations.tKey('str_dashboard_card_in_progress')}</p>
                    </div>
                  )}

                  {tournament.status == 'upcoming' && !tournament.is_seasonal_game  && (
                    <div style={styles.deadlineContainer}>
                      <p style={styles.deadlineText}>{translations.tKey('str_enter_game_deadline')}</p>
                    </div>
                  )}

                  {tournament.status == 'upcoming' && !tournament.is_seasonal_game && <View style={styles.timer}>
                    <Image style={styles.clockImage} source={clock}/>
                    <Timer style={styles.timerText} time={timeLeft} onTimerEnded={() => {
                      this.setState({timeEnded: true});
                    }}/>
                  </View>}
                  {(tournament.status == 'past' || tournament.status == 'current') && !tournament.is_seasonal_game && 
                  <View style={{display: 'flex', width: '100%'}}>
                    <View style={{
                      width: '100%',
                      display: 'flex',
                      flexDirection: 'row',
                      alignItems: 'center',
                      justifyContent: 'space-between'
                    }}>
                      <Text style={{
                        fonstSize: 10,
                        fontWeight: 'bold'
                      }}>{tournament.status == 'current' ? translations.tKey('str_dashboard_card_in_progress') : translations.tKey('str_dashboard_card_finished')}</Text>
                      {tournament.status == 'current' &&
                      <Text style={{fonstSize: 10, fontWeight: 550}}>{`${tournament.matches_left} matches left`}</Text>}
                    </View>
                    {tournament.status != 'upcoming' &&
                    <View style={styles.progressBar}><View style={this.calculateProgressBarStyle(tournament)}/></View>}
                  </View>}
                </View>
                <Text style={styles.tournamentName}>{tournament.name}</Text>
                {tournament.championships.map((t, index) => {
                  return <Text key={index}>{t.name}</Text>;
                })}
              </View>
            </View>
            <View style={styles.detailsRow}>
              <View style={[styles.detailsColumn, {alignItems: 'flex-start'}]}>
                {tournament.entry_fee &&
                <Text style={styles.detailsGrayText}>{translations.tKey('str_overview_buy_in')}</Text>}
                {tournament.entry_fee && <Text
                  style={styles.detailsGreenText}>{`${tournament.entry_fee.toFixed(2)}${translations.tKey('str_currency')}`}</Text>}
              </View>

              {pricePool && pricePool != 0 && !isSeasonalGame &&
                <View style={styles.detailsColumn}>
                  <Text style={styles.detailsGrayText}>{translations.tKey('str_enter_game_prizepool')}</Text>
                  <Text style={styles.detailsGreenText}>{pricePool}</Text>
                </View>
              }

              {pricePool && pricePool != 0 && isSeasonalGame &&
                <View style={styles.detailsColumn}>
                  <Text style={styles.detailsGrayText}>{translations.tKey('str_enter_game_prizepool')}</Text>
                  <Text style={styles.detailsGreenText}>{20000} лв.</Text>
                </View>
              }


              <View style={[styles.detailsColumn, {alignItems: 'flex-end'}]}>
                <View>
                  <Text style={styles.detailsGrayText}>{translations.tKey('str_dashboard_your_teams')}</Text>
                  <View style={styles.entriesRow}>
                    <Text style={styles.entriesText}>{tournament.user_teams.length}</Text>
                  </View>
                </View>
              </View>

            </View>


            <div style={styles.playButton} onClick={() => {
              if (isSeasonalGame) return;
              if (tournament.status == 'upcoming' && !this.state.timeEnded && (tournament.max_entries_from_single_users < 0 || tournament.user_teams.length < tournament.max_entries_from_single_users)) {
                localStorage.setItem('setClearTeamState', true);

                this.props.addNewTeam();
              } else {
                this.props.viewTeams(tournament.sub_tournament_id);
              }
            }}>
              {isSeasonalGame ?  <TouchableOpacity>
                <Text style={styles.playButtonText}>{
                  translations.tKey('str_dashboard_card_view_my_teams')
                }</Text>
              </TouchableOpacity> :
              <TouchableOpacity>
                <Text style={styles.playButtonText}>{
                  translations.tKey(tournament.status == 'upcoming' && !this.state.timeEnded && (tournament.max_entries_from_single_users < 0 || tournament.user_teams.length < tournament.max_entries_from_single_users) ? 'str_dashboard_card_add_new_team' : 'str_dashboard_card_view_my_teams')
                }</Text>
              </TouchableOpacity>}
            </div>
          </View>
        </div>
      </View>
    );
  }
};

const mapDispatchToProps = (dispatch, ownProps) => {
  return {
    // getTournamentFixtures: id => dispatch(getTournamentFixtures(id)),
    // clearFixtures: () => dispatch(clearFixtures())
  };
};

const mapStateToProps = (state, ownProps) => {
  return {
    //homeInfo: state.homeTournaments.tournamentsInfo
  };
};


export default connect(mapStateToProps, mapDispatchToProps)(DashboardCard);

const operator = getDomainOperator();

const design = operator.design;

const base = {
  card: {
    minHeight: '250px',
    height: "90%",
    boxShadow: '0 4px 8px 0 rgba(0,0,0,0.2)',
    transition: '0.3s',
    backgroundColor: 'white',
    marginTop: '5px',
    marginBottom: '5px',
    border: `6px solid ${design.secondaryColor}`,
    borderRadius: '3px',
    marginHorizontal: '10px',
    width: '330px'
  },
  content: {
    padding: '20px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    minHeight: '250px',
    height: '100%'
  },
  dimmed: {
    border: '6px solid rgba(0, 59, 33)'
  },
  progressBar: {
    width: '100%',
    height: '5px',
    borderRadius: '2px',
    backgroundColor: '#EBE5EB',
    marginVertical: '5px'
  },
  image: {
    width: 50,
    height: 50,
    resizeMode: 'cover',
    borderRadius: 25,
    border: `2px solid ${design.tertiaryColor}`
  },
  flagNameRow: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'flex-start',
    width: '100%'
  },
  prizePoolText: {
    fontSize: 16,
    fontWeight: 'bold'
  },
  tournamentName: {
    fontSize: 16,
    fontWeight: 550
  },
  timer: {
    backgroundColor: design.tertiaryColor,
    padding: '5px',
    paddingLeft: '7px',
    paddingRight: '7px',
    width: '45%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-evenly',
    flexDirection: 'row'
  },
  deadlineContainer: {
    border: `1px solid ${design.tertiaryColor}`,
    padding: '5px',
    width: '45%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-evenly',
    flexDirection: 'row'
  },
  deadlineText: {
    fontSize: '13px',
    fontWeight: 'bold',
    color: '#FF085D',
    margin: 0,
    fontFamily: design.textFontFamily
  },
  timerText: {
    fontSize: 16,
    fontWeight: 550
  },
  progressBar: {
    width: '100%',
    height: '5px',
    borderRadius: '2px',
    backgroundColor: '#EBE5EB',
    marginTop: '8px'
  },
  playersText: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'flex-start'
  },
  grayText: {
    color: '#CABBCB'
  },
  clockImage: {
    marginTop: '1px',
    width: 13,
    height: 13,
    resizeMode: 'cover'
  },
  detailsRow: {
    display: 'flex',
    width: '100%',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'center',
    marginTop: '20px'
  },
  detailsColumn: {
    display: 'flex',
    width: '33%',
    alignItems: 'center',
    justifyContent: 'center'
  },
  detailsGrayText: {
    fontSize: 14,
    opacity: 0.4
  },
  detailsGreenText: {
    fontSize: 16,
    fontWeight: 'bold',
    color: '#02894E'
  },
  entriesRow: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'center'
  },
  entriesText: {
    fontSize: 16,
    fontWeight: 'bold'
  },
  entriesTotalText: {
    fontSize: 16,
    opacity: 0.4,
    fontWeight: 'bold'
  },
  playButton: {
    width: '100%',
    backgroundColor: design.secondaryColor,
    paddingTop: '10px',
    paddingBottom: '10px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    borderRadius: '4px'
  },
  playButtonText: {
    fontSize: 14,
    fontWeight: 'bold'
  }
};

const styles = createStyles(
  base,
  maxWidth(376, {
    deadlineText: {
      fontSize: 12,
      fontWeight: 'bold',
      color: '#FF085D'
    }
  })
);