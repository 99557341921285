import React from "react";
import { View, StyleSheet, Image } from "react-native";
import { withRouter } from "react-router-dom";
import bottomImage from '../assets/footer_palmsbet.png';

const BottomNav = ({ history }) => {
  return (
    <View style={styles.main}>
      <Image source={bottomImage} style={{
          width: '100%',
          height: 450}} />
    </View>
  );
};

const styles = StyleSheet.create({
  main: {
    backgroundColor: "#black",
    width: '100%',
    position: 'absolute',
    left:0,
    top: '100%'
  }
});

export default withRouter(BottomNav);
