import React from 'react';
import {
    View,
    TouchableOpacity,
    Image,
    Text,
    StyleSheet
} from 'react-native';
import EStyleSheet from 'react-native-extended-stylesheet';
import homeImage from '../../assets/homepage.png';
import homeImageHovered from '../../assets/homepage_hover.png';
import teamImage from '../../assets/team_tabbar.png';
import teamImageHovered from '../../assets/team_hover.png';
import transferImage from '../../assets/transfers.png';
import transferImageHovered from '../../assets/transfers_hover.png';
import resultsImage from '../../assets/results.png';
import resultsImageHovered from '../../assets/results_hover.png';
import statsImage from '../../assets/stats.png';
import statsImageHovered from '../../assets/stats_hover.png';
import pointsImage from '../../assets/points.png';
import pointsImageHovered from '../../assets/points_hover.png';
import * as translations from '../../Common/utils/translations';

import { connect } from 'react-redux';

class TabBar extends React.Component {

    state = {
        activeTabId: 1,
        tabs: [
            {
                id: 0,
                title: translations.tKey('str_top_bar_home'),
                path: 'Home',
                icon: homeImage,
                hoverIcon: homeImageHovered,
                seenOnClosedRound: true,
                seenOnOpenedRound: true
            },
            {
                id: 1,
                title: translations.tKey('str_top_bar_team'),
                path: 'Team',
                icon: teamImage,
                hoverIcon: teamImageHovered,
                seenOnClosedRound: false,
                seenOnOpenedRound: true
            },
            {
                id: 2,
                title: translations.tKey('str_top_bar_transfers'),
                path: 'Transfers',
                icon: transferImage,
                hoverIcon: transferImageHovered,
                seenOnClosedRound: false,
                seenOnOpenedRound: true
             },
             {
                id: 3,
                title: translations.tKey('str_top_bar_points'),
                path: 'Points',
                icon: pointsImage,
                hoverIcon: pointsImageHovered,
                seenOnClosedRound: true,
                seenOnOpenedRound: false
            },
            {
                id: 4,
                title: translations.tKey('str_top_bar_results'),
                path: 'Results',
                icon: resultsImage,
                hoverIcon: resultsImageHovered,
                seenOnClosedRound: true,
                seenOnOpenedRound: true
            },
            {
                id: 5,
                title: translations.tKey('str_top_bar_stats'),
                path: 'Stats',
                icon: statsImage,
                hoverIcon: statsImageHovered,
                seenOnClosedRound: false,
                seenOnOpenedRound: true
            }
        ]
    }

    changePage = id => {
        this.setState({activeTabId : id});
        const tab = this.state.tabs.find(t => t.id === id);
        this.props.navigation.navigate(tab.path);
    } 

    render() {
        const activeTab = this.state.tabs.find(
            t => window.location.href.indexOf(t.path.toLowerCase()) !== -1);
        const activeTabId = activeTab ? activeTab.id : 0;

        const { isRoundClosed } = this.props;
        const tabs = this.state.tabs.filter(tab => {
            if (isRoundClosed && tab.seenOnClosedRound) return true;
            if (!isRoundClosed && tab.seenOnOpenedRound) return true;
            return false;
        });
        return (
        <View style={styles.tabsContainer}>
        {tabs.map((tab,i) => (
            <View style={styles.tabContainer} key={i}>
                <TouchableOpacity 
                    style={[styles.tab, activeTabId === tab.id ? {backgroundColor: '#2053A4'} : null ]}
                    onPress={() => this.changePage(tab.id)}>
                    <Image source={activeTabId !== tab.id ? tab.icon : tab.hoverIcon} 
                    style={{ width: 30, height: 30, marginBottom: 10 }} />
                    <Text style={styles.tabTitle}>{tab.title}</Text>
                </TouchableOpacity>
            </View>
        ))}
        </View>);
    }
}

const mapStateToProps = (state, ownProps) => {
  return {
      isRoundClosed: state.userInfo.isRoundClosed
  }
}

export default connect(mapStateToProps)(TabBar);

const styles = EStyleSheet.create({
tabsContainer: {
    height: 120,
    width: '100%',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
    backgroundColor: 'black',
  },
  tabContainer: {
    height: 70,
    flex: 1,
    alignItems: 'center',
    justifyContent: 'center',
  },
  tab: {
    height: 70,
    width: 90, 
    padding: 5,
    borderRadius: 10,
    alignItems: 'center',
    justifyContent: 'center',
  },
  tabTitle: {
      color: 'white', 
      textAlign: 'center', 
      fontSize: 11
  }
});