import {
  SET_TEAM,
  SET_AVAILABLE_PLAYERS,
  REMOVE_AVAILABLE_PLAYER,
  RESTORE_AVAILABLE_PLAYER,
  SET_GROUP,
  RESET_TEAM,
  SET_TEAM_READY,
  MAKE_SWAP,
  SCHEME_CHANGED,
  SORT_PLAYERS,
  CHIP_USED,
  TRANSFER_PLAYER,
  EDIT_TEAM,
  AUTOFILL,
  CHANGE_PREFERENCE,
  WILD_CARD_USED,
  SET_REMAINING_BUDGET,
  SET_TOTAL_BUDGET
} from "./actionTypes";
import { setError } from '../../Common/actions/errors';
import { BASE_URL } from '../../Common/config/config';
import { authGetToken } from '../../Common/actions/token';
import { setBudget, makeUserReady } from '../../Home/actions/home';

import { getTeam as getTeamForTransfers } from '../../Transfers/actions/team';
import { teamStartLoading, teamStopLoading, uiStartLoading, uiStopLoading } from '../../Common/actions/ui';
import { setTeamEdit } from "../../EnterTournament/actions/enterTournament";

function processTeamIcons(userTeam) {
  if (userTeam) {

    userTeam.groups.forEach(g => {
      g.players.forEach(p => {
        if (p) {
          p.iconUrl = BASE_URL + p.iconUrl;
        }


      });
    });
    userTeam.reserves.forEach(p => {
      if (p) {
        p.iconUrl = BASE_URL + p.iconUrl;
        p.realPlayerId = p.id;
        // p.playerId = p.id;
      }

    });
  }
}

export const getTeamById = (subTournamentId, teamId) => {
  return dispatch => {
    dispatch(teamStartLoading());
    dispatch(authGetToken())
      .catch(err => {
        console.log('No auth token exists!');
      })
      .then(token => {
        return fetch(BASE_URL + `/users/team?sub_tournament_id=${subTournamentId}&user_team_id=${teamId}`, {
          credentials: 'include',
          headers: {
            "Content-Type": "application/json; charset=utf-8",
            "Authorization": token
          }
        });
      })
      .catch(err => {
        dispatch(teamStopLoading());
        console.log(err)
      }
        )
      .then(res => res.json())
      .then(res => {
        if (localStorage.getItem("addNewTeamDash") || localStorage.getItem("addNewTeamHome")) {
          localStorage.removeItem("addNewTeamHome")
          return;
        }
        processTeamIcons(res.data);

        dispatch(teamStopLoading());
        console.log(res.data)
        dispatch(setTeam(res.data));

      })
  };
}


export const getTeam = () => {
  return (dispatch, getState) => {
    dispatch(authGetToken())
      .catch(err => {
        console.log('No auth token exists!');
      })
      .then(token => {
        return fetch(BASE_URL + '/users/team', {
          credentials: 'include',
          headers: {
            "Content-Type": "application/json; charset=utf-8",
            "Authorization": token
          }
        });
      })
      .catch(err => console.log(err))
      .then(res => res.json())
      .then(res => {
        if (res.has_error && res.status === 503) {
          dispatch(setError('Кръга е затворен!', 503));
          return;
        }

        const userTeam = res.data;

        if (!userTeam.isReady) {
          const championshipInfo = getState().championship.championshipInfo;
          if (!championshipInfo) return;
          const {
            user_team_allowed_goalkeepers,
            user_team_allowed_defenders,
            user_team_allowed_half_defenders,
            user_team_allowed_attackers,
            user_team_max_players
          } = championshipInfo;
          const minPlayers = [user_team_allowed_goalkeepers, user_team_allowed_defenders, user_team_allowed_half_defenders, user_team_allowed_attackers];

          userTeam.unfilledCount = user_team_max_players;
          const names = ['Вратар', 'Защитник', 'Полузащитник', 'Нападател'];
          userTeam.groups.forEach((group, i) => {
            if (!group.players) group.players = [];
            group.name = names[i];
            while (group.players.length < minPlayers[i]) {
              group.players.push(null);
            }
          });
        } else {
          processTeamIcons(userTeam);
        }
        dispatch(setTeam(userTeam));
        // TODO: dispatch(getTeamForTransfers());
      });
  };
}

export const autoFillTeam = (data) => {
  return (dispatch, getState) => {
    dispatch(uiStartLoading());
    dispatch(authGetToken())
      .catch(err => {
        dispatch(uiStopLoading());
        console.log('No auth token exists!');
      })
      .then(token => {
        return fetch(BASE_URL + '/palms/team/autofill', {
          credentials: 'include',
          method: "POST",
          body: JSON.stringify(data),
          headers: {
            "Content-Type": "application/json; charset=utf-8",
            "Authorization": token,
          }
        });
      })
      .catch(err => {
        console.log(err);
        dispatch(uiStopLoading());
      })
      .then(res => res.json())
      .then(res => {
        dispatch(uiStopLoading());

        res.data.groups.forEach(g => {
          g.players.forEach(player => { if (player) player.iconUrl = BASE_URL + player.iconUrl });
        });
        dispatch(autofill(res.data));
        //dispatch(setBudget(getState().championship.championshipInfo.user_initial_budget - res.data.totalPrice));
      });
  };
}

export const autofill = (data) => {
  return {
    type: AUTOFILL,
    data
  }
}

export const makeTeamReady = () => {
  return (dispatch, getState) => {
    dispatch(authGetToken())
      .catch(err => {
        console.log('No auth token exists!');
      })
      .then(token => {
        return fetch(BASE_URL + '/users/team/ready', {
          credentials: 'include',
          method: "POST",
          headers: {
            "Content-Type": "application/json; charset=utf-8",
            "Authorization": token,
          },
          body: JSON.stringify(getState().userTeam.userTeam.groups)
        });
      })
      .catch(err => console.log(err))
      .then(res => res.json())
      .then(res => {
        const userTeam = res.data;
        userTeam.groups.forEach(g => {
          g.players.forEach(p => {
            p.iconUrl = BASE_URL + p.iconUrl;
            p.realPlayerId = p.id;
            // p.playerId = p.id;
          });
        });
        userTeam.reserves.forEach(p => {
          p.iconUrl = BASE_URL + p.iconUrl;
          p.realPlayerId = p.id;
          // p.playerId = p.id;
        });
        dispatch(setTeamReady(userTeam));
        // TODO: dispatch(makeUserReady());
        //TODO: dispatch(getTeamForTransfers());
      });
  };
}

export const setTeamReady = userTeam => {
  return {
    type: SET_TEAM_READY,
    userTeam
  };
}

export const resetTeam = () => {
  return {
    type: RESET_TEAM
  };
}

export const setTeam = (team, isAuto) => {
  return {
    type: SET_TEAM,
    team,
    isAuto
  };
};

export const getAvailablePlayers = data => {
  let filter = "";
  if (!data.sortBy) {
    data.sortBy = "price";
    data.sort = "desc";
  }
  if (data.sortBy && data.sort) {
    filter = `?sortBy=${data.sortBy}&sort=${data.sort}`
  }
 
  return dispatch => {
    dispatch(uiStartLoading());
    dispatch(authGetToken())
      .catch(err => {
        console.log('No auth token exists!');
      })
      .then(token => {
        return fetch(BASE_URL + '/palms/players/filter' + filter, {
          credentials: 'include',
          method: "POST",
          body: JSON.stringify(data),
          headers: {
            "Content-Type": "application/json; charset=utf-8",
            "Authorization": token
          }
        });
      })
      .catch(err => console.log(err))
      .then(res => res.json())
      .then(res => {
        res.data.data.forEach(player => { if (player) player.iconUrl = BASE_URL + player.iconUrl });
        console.log(res.data)
        dispatch(setAvailablePlayers(res.data));
        dispatch(uiStopLoading());
      });
  };
}

export const setAvailablePlayers = players => {
  return {
    type: SET_AVAILABLE_PLAYERS,
    players
  };
};

export const sortPlayers = criteria => {
  return {
    type: SORT_PLAYERS,
    criteria
  };
}

export const removeAvailablePlayer = playerId => {
  return {
    type: REMOVE_AVAILABLE_PLAYER,
    playerId
  };
};

export const restoreAvailablePlayer = player => {
  return {
    type: RESTORE_AVAILABLE_PLAYER,
    player
  };
};

export const setGroup = group => {
  return {
    type: SET_GROUP,
    group
  };
};


export const changePlayer = (playerIn, playerOut, team, subTournament) => {
  return dispatch => {
    dispatch(uiStartLoading());
    dispatch(authGetToken())
      .catch(err => {
        dispatch(uiStopLoading());
        console.log('No auth token exists!');
      })
      .then(token => {
        return fetch(BASE_URL + '/palms/team/swap', {
          credentials: 'include',
          method: 'POST',
          headers: {
            "Content-Type": "application/json; charset=utf-8",
            "Authorization": token
          },
          body: JSON.stringify({ 
            player_in_id: playerIn.id, 
            player_out_id: playerOut.id,
            teamId: team.teamId 
          })
        });
      })
      .catch(err => {
        dispatch(uiStopLoading());
        console.log(err)
      })
      .then(res => {
        dispatch(uiStopLoading());
        dispatch(swapPlayers(playerIn.id, playerOut.id));
        dispatch(getTeamById(subTournament.sub_tournament_id, team.teamId));
      });
  };
}

export const swapPlayers = (playerInId, playerOutId) => {
  return {
    type: MAKE_SWAP,
    playerInId,
    playerOutId
  };
};


export const preferenceSwapPlayer = (playerOne, playerTwo, team, subTournament) => {
  return dispatch => {
    dispatch(uiStartLoading());
    dispatch(authGetToken())
      .catch(err => {
        dispatch(uiStopLoading());
        console.log('No auth token exists!');
      })
      .then(token => {
        return fetch(BASE_URL + '/palms/team/preferences', {
          credentials: 'include',
          method: 'POST',
          headers: {
            "Content-Type": "application/json; charset=utf-8",
            "Authorization": token
          },
          body: JSON.stringify({ 
            player_one_id: playerOne.id, 
            player_two_id: playerTwo.id,
            teamId: team.teamId 
          })
        });
      })
      .catch(err => {
        dispatch(uiStopLoading());
        console.log(err)
      })
      .then(res => {
        dispatch(uiStopLoading());
        dispatch(preferenceSwap(playerOne.id, playerTwo.id));
        dispatch(getTeamById(subTournament.sub_tournament_id, team.teamId));
      });
  };
}

export const preferenceSwap = (playerOneId, playerTwoId) => {
  return {
    type: CHANGE_PREFERENCE,
    playerOneId,
    playerTwoId
  };
};

export const changeScheme = (id, teamId) => {
  return (dispatch, getState) => {
    dispatch(authGetToken())
      .catch(err => {
        console.log('No auth token exists!');
      })
      .then(token => {
        return fetch(BASE_URL + '/palms/schemes/' + id, {
          credentials: 'include',
          method: "POST",
          headers: {
            "Content-Type": "application/json; charset=utf-8",
            "Authorization": token,
          },
          body: JSON.stringify({
            teamId
          })
        });
      })
      .catch(err => console.log(err))
      .then(res => res.json())
      .then(res => {
        processTeamIcons(res.data);
        dispatch(setTeam(res.data));
        dispatch(schemeChanged(id));
        //TODO: dispatch(getTeamForTransfers());
      });
  };
}

export const schemeChanged = newSchemeId => {
  return {
    type: SCHEME_CHANGED,
    newSchemeId,
  };
};

export const transferPlayer = (teamId, subGameId, body) => {
  return dispatch => {
    dispatch(authGetToken())
      .catch(err => {
        console.log('No auth token exists!');
      })
      .then(token => {
        return fetch(BASE_URL + `/users/transfers?user_team_id=${teamId}&sub_tournament_id=${subGameId}`, {
          credentials: 'include',
          method: 'POST',
          headers: {
            "Content-Type": "application/json; charset=utf-8",
            "Authorization": token
          },
          body: JSON.stringify([].concat(body))
        });
      })
      .catch(err => console.log(err))
      .then(res => {
        dispatch(getTeamById(subGameId, teamId));
        setPlayerTransfered(body);
        //TODO: dispatch(getTeamForTransfers());
      });
  };
}

export const setPlayerTransfered = (data) => {
  return {
    type: TRANSFER_PLAYER,
    data
  };
};

export const editTeam = (team) => {

  return dispatch => {
    dispatch(setTeamEdit())
    dispatch(authGetToken())
      .catch(err => {
        console.log('No auth token exists!');
      })
      .then(token => {
        console.log(team);

        return fetch(BASE_URL + `/palms/users/teams`, {
          credentials: 'include',
          headers: {
            "Content-Type": "application/json; charset=utf-8",
            "Authorization": token
          },
          method: 'PUT',
          body: JSON.stringify(team)
        });
      })
      .catch(err => console.log(err))
      .then(res => res.json())
      .then(res => {
        processTeamIcons(res.data);
        dispatch(setTeamEditted(res.data));
      })
  };
}

export const setTeamEditted = (data) => {
  return {
    type: EDIT_TEAM,
    data
  }
}

export const useWildCard = (teamId) => {
  return dispatch => {
    dispatch(authGetToken())
      .catch(err => {
        console.log('No auth token exists!');
      })
      .then(token => {
        return fetch(BASE_URL + '/users/team/wildcards', {
          credentials: 'include',
          method: 'POST',
          headers: {
            "Content-Type": "application/json; charset=utf-8",
            "Authorization": token
          },
          body: JSON.stringify({
            user_team_id: teamId
          })
        });
      })
      .catch(err => console.log(err))
      .then(res => res.json())
      .then(res => {
        if (res.has_error && res.status === 503) {
          dispatch(setError('Кръга е затворен!', 503));
          return;
        }
        dispatch(wildCardUsed())
      });
  };
}

export const wildCardUsed = () => {
  return {
    type: WILD_CARD_USED,
  };
};


export const setRemainingBudget = (budget) => {
  return dispatch => {
    dispatch(remainingBudgetUpdated(budget));
    return;
  }
};

export const remainingBudgetUpdated = (budget) => {
  return {
    type: SET_REMAINING_BUDGET,
    data: budget
  };
};

export const setTotalBudget = (budget) => {
  return dispatch => {
    dispatch(totalBudgetUpdated(budget));
    return;
  }
};

export const totalBudgetUpdated = (budget) => {
  return {
    type: SET_TOTAL_BUDGET,
    data: budget
  };
};
