import {
  GET_SUBTOURNAMENT_SCORING,
  GET_SUBTOURNAMENT_DETAILS,
  GET_PLAYER_INFO,
  ENTER_SUB_TOURNAMENT,
  CLEAR_TOURNAMENT_ENTERED,
  GET_TOURNAMENT_FOR_TRANSFER,
  CLEAR_TOURNAMENT_FOR_TRANSFER,
  SET_TEAM_EDIT,
  CLEAR_TEAM_EDIT,
  SET_TEAM_ERRORS
} from "./actionTypes";

import { setTeam } from "../../Team/actions/team";
import { setHomeTournamentsInfo } from "../../HomeTournaments/actions/tournaments"
import { BASE_URL } from '../../Common/config/config';
import { authGetToken } from '../../Common/actions/token';
import { setError } from '../../Common/actions/errors';
import { uiStartLoading, uiStopLoading } from "../../Common/actions/ui";
import { showConfirmPopup } from "../../ConfirmPopup/actions/confirmPopup"
import { getUserDashboard } from '../../Dashboard/actions/dashboard';
import * as translations from "../../Common/utils/translations"

export const getScoring = () => {
  return dispatch => {
    dispatch(uiStartLoading())
    dispatch(authGetToken())
      .catch(err => {
        dispatch(uiStopLoading())
        console.log('No auth token exists!');

      })
      .then(token => {
        return fetch(BASE_URL + '/subTournament/scoring', {
          credentials: 'include',
          headers: {
            "Content-Type": "application/json; charset=utf-8",
            "Authorization": token
          }
        });
      })
      .catch(err => {
        dispatch(uiStopLoading())
        console.log(err);
      })
      .then(res => res.json())
      .then(res => {
        dispatch(uiStopLoading())
        if (res.has_error) {
          dispatch(showConfirmPopup(translations.tKey("str_error"), res.message, null));
        } else {
          dispatch(setScoring(res.data))
        }
      });
  };
}

export const setScoring = info => {
  return {
    type: GET_SUBTOURNAMENT_SCORING,
    info
  };
};

export const getSubTournamentDetails = (subTournamentId, userTeamId) => {
  return dispatch => {
    dispatch(uiStartLoading())
    dispatch(authGetToken())
      .catch(err => {
        dispatch(uiStopLoading())
        console.log('No auth token exists!');

      })
      .then(token => {
        let teamId = "";
        if (userTeamId) {
          teamId = `?user_team_id=${userTeamId}`;
        }
        return fetch(BASE_URL + `/subTournament/details/${subTournamentId}${teamId}`, {
          credentials: 'include',
          headers: {
            "Content-Type": "application/json; charset=utf-8",
            "Authorization": token
          }
        });
      })
      .catch(err => {
        dispatch(uiStopLoading())
        console.log(err);
      })
      .then(res => res.json())
      .then(res => {
        dispatch(uiStopLoading())
        if (res.has_error) {
          dispatch(showConfirmPopup(translations.tKey("str_error"), res.message, null));
        } else {
          dispatch(setSubtournametDetails(res.data))
        }
      });
  };
}

export const setSubtournametDetails = data => {
  return {
    type: GET_SUBTOURNAMENT_DETAILS,
    data
  };
};

export const getPlayerInfo = id => {
  return dispatch => {
    dispatch(uiStartLoading())
    dispatch(authGetToken())
      .catch(err => {
        dispatch(uiStopLoading())
        console.log('No auth token exists!');

      })
      .then(token => {
        return fetch(BASE_URL + `/palms/player/${id}/info`, {
          credentials: 'include',
          headers: {
            "Content-Type": "application/json; charset=utf-8",
            "Authorization": token
          }
        });
      })
      .catch(err => {
        dispatch(uiStopLoading())
        console.log(err);
      })
      .then(res => res.json())
      .then(res => {
        dispatch(uiStopLoading())
        if (res.has_error) {
          dispatch(showConfirmPopup(translations.tKey("str_error"), res.message, null));
        } else {
          dispatch(setPlayerInfo(res.data))
        }
      });
  };
}

export const setPlayerInfo = data => {
  return {
    type: GET_PLAYER_INFO,
    data
  }
}

export const enterSubTournament = (team, subId, teamId, teamName) => {
  return dispatch => {
    dispatch(uiStartLoading())
    dispatch(authGetToken())
      .catch(err => {
        dispatch(uiStopLoading())
        console.log('No auth token exists!');

      })
      .then(token => {
        let url = `/palms/subTournament/join/${subId}`;
          url += `?team_name=${teamName}`;
        if (teamId) {
          url += `&user_team_id=${teamId}`
        }
        return fetch(BASE_URL + url, {
          credentials: 'include',
          method: "PUT",
          body: JSON.stringify(team.groups),
          headers: {
            "Content-Type": "application/json; charset=utf-8",
            "Authorization": token
          }
        });
      })
      .catch(err => {
        dispatch(uiStopLoading())
        console.log(err);
      })
      .then(res => res.json())
      .then(res => {
        dispatch(uiStopLoading());

        if (res.has_error) {
          dispatch(showConfirmPopup(translations.tKey("str_error"), res.message, null));
        } else {
          dispatch(setTournamentEntered(res.data))
          //dispatch(getUserDashboard(null, null));
        }
      });
  };
}

export const startLoading = () => {
  return dispatch => {
    setTimeout(() => {
      dispatch(uiStartLoading());
    }, 100);
  }
}

export const stopLoading = () => {
  return dispatch => {
    setTimeout(() => {
      dispatch(uiStartLoading());
    }, 100);
  }
}

export const setTournamentEntered = (data) => {
  return {
    type: ENTER_SUB_TOURNAMENT
  }
}

export const clearTournamentEntered = () => {
  return {
    type: CLEAR_TOURNAMENT_ENTERED
  }
}

export const getTournamentForTransfer = (id) => {
  return dispatch => {
    dispatch(uiStartLoading())
    dispatch(authGetToken())
      .catch(err => {
        dispatch(uiStopLoading())
        console.log('No auth token exists!');

      })
      .then(token => {
        return fetch(BASE_URL + `/tournament/${id}`, {
          credentials: 'include',
          headers: {
            "Content-Type": "application/json; charset=utf-8",
            "Authorization": token
          }
        });
      })
      .catch(err => {
        dispatch(uiStopLoading())
        console.log(err);
      })
      .then(res => res.json())
      .then(res => {
        dispatch(uiStopLoading())
        if (res.has_error) {
          dispatch(showConfirmPopup(translations.tKey("str_error"), res.message, null));
        } else {
          dispatch(setTournamentForTransfer(res.data))
        }
      });
  };
}

export const setTournamentForTransfer = (data) => {
  return {
    type: GET_TOURNAMENT_FOR_TRANSFER,
    data
  }
}

export const clearTournamentForTransfer = () => {
  return {
    type: CLEAR_TOURNAMENT_FOR_TRANSFER
  }
}

export const setTeamEdit = () => {
  return {
    type: SET_TEAM_EDIT
  }
}
export const clearTeamEditted = () => {
  return {
    type: CLEAR_TEAM_EDIT
  }
}

export const restoreState = (team, homeInfo, transfer) => {
  return dispatch => {
    dispatch(setHomeTournamentsInfo(homeInfo));
    dispatch(setTournamentForTransfer(transfer));
    dispatch(setTeam(team, true));
  }
}

export const setTeamErrors = (errorName, errorTeam, errorCaptain, errorVice) => {
  return {
    type: SET_TEAM_ERRORS,
    errorName,
    errorTeam,
    errorCaptain,
    errorVice
  }
}