import React from 'react';
import { StyleSheet, Text, View, Image, Button, TouchableOpacity, ScrollView, Linking } from 'react-native';
import MdArrowDropdown from 'react-ionicons/lib/MdArrowDropdown';
import EStyleSheet from 'react-native-extended-stylesheet';

import HeaderWithBack from '../../Common/components/HeaderWithBack';
import Background from '../../Common/components/Background';
import Playground from '../../Common/components/Playground';
import TabBar from '../../Common/components/TabBar';
import TopBar from '../../Common/components/TopBar';
import BudgetContainer from '../../Common/components/BudgetContainer';
import LoadingScreen from '../../Common/components/LoadingScreen';

import PlayerPopup from '../components/PlayerPopup';
import {SchemeDialog} from '../components/SchemeDialog';

import autoFillImage from '../../assets/generate-team.svg.png';
import resetTeamIcon from '../../assets/close-popup.svg.png';

import { connect } from 'react-redux';
import {
  getTeam,
  autoFillTeam,
  resetTeam,
  makeTeamReady,
  changePlayer,
  changeScheme,
} from '../actions/team';
import { setBudget } from '../../Home/actions/home';

class Team extends React.Component {

  state = {
    teamAutoGenerated: false,
    selectedPlayer: null,
    selectedChip: null,
    schemeDialogOpened: false
  }

  static navigationOptions = {
    title: 'Отбор',
  };

  componentDidMount() {
    this.props.getTeam();
  }

  componentDidUpdate(prevProps) {
    if (prevProps.userTeam !== this.props.userTeam) {
      this.setState({ loadingTeam: false });
    }
    if (prevProps.roomInfo !== this.props.roomInfo) {
      this.setState({ loadingTeam: true });
      this.props.getTeam();
    }
    if (this.props.errorCode === 503) {
      this.props.navigation.navigate('Maintenance');
      return;
    }
    if (this.props.navigation !== prevProps.navigation) {
      this.props.getTeam();
    }
  }

  showPlayerPopup = (player, isReserve) => {
    this.setState({ selectedPlayer: player, isSelectedReserve: isReserve })
  }

  hidePlayerPopup = () => {
    this.setState({ selectedPlayer: null })
  }

  addPlayerToTeam = (index, group) => {
    sessionStorage.setItem('currentGroup', JSON.stringify(group));
    this.props.navigation.navigate('SelectPlayer');
  }

  autoFillTeam = () => {
    this.props.autoFillTeam();
    this.setState({ teamAutoGenerated: true });
  }

  resetTeam = () => {
    this.props.setBudget(this.props.roomInfo.budget);
    this.props.resetTeam();
    this.setState({ teamAutoGenerated: false });
  }

  setTeamReady = () => {
    if (this.props.budget < 0) {
      alert("Разполагаемият бюджет е по-малък от 0!");
      return;
    }
    if (this.getUnfilledCount() > 0)
      return;
    this.props.makeTeamReady();
  }

  makeChange = player => {
    if (this.state.isSelectedReserve)
      this.props.changePlayer(this.state.selectedPlayer.playerId, player.playerId);
    else
      this.props.changePlayer(player.playerId, this.state.selectedPlayer.playerId);
    this.hidePlayerPopup();
  }

  changeScheme = id => {
    if (id) {
      this.props.changeScheme(id);
    }
    this.setState({ schemeDialogOpened: false });
  }

  getUnfilledCount = () => {
    let unfilledCount = 0;
    this.props.userTeam.groups.forEach(g => {
      g.players.forEach(p => {
        if (p === null) unfilledCount++;
      });
    });
    return unfilledCount;
  }

  showSchemeDialog = () => this.setState({ schemeDialogOpened: true });

  render() {
    if (!this.props.roomInfo) {
      this.props.navigation.navigate('Home');
      return null;
    }

    if (!this.props.userTeam || this.state.loadingTeam) {
      return <LoadingScreen />;
    }


    const unfilledCount = this.getUnfilledCount();
    const confirmButtonTeamStyles = [styles.confirmButton];
    if (unfilledCount > 0)
      confirmButtonTeamStyles.push({ backgroundColor: 'rgba(225,203,77, 0.6)' });

    return (
      <React.Fragment>
        <ScrollView contentContainerStyle={styles.container} style={styles.scrollView}>
          <Background />

          <SchemeDialog open={this.state.schemeDialogOpened}
            onClose={({ key }) => this.changeScheme(key)}
            schemes={this.props.userTeam.allSchemes} />

          <TopBar navigation={this.props.navigation} />
          {this.state.selectedPlayer &&
            <PlayerPopup
              onDismiss={this.hidePlayerPopup}
              onSelectPlayerForChange={this.makeChange}
              navigation={this.props.navigation}
              {...this.state.selectedPlayer} />
          }
          <View style={styles.headerView}>
            {this.props.userTeam.isReady ?
              <React.Fragment>
                <Text style={styles.headerText}>{"КРЪГ " + this.props.userTeam.currentRound}</Text>
                <TouchableOpacity style={styles.schemeContainer} onPress={this.showSchemeDialog}>
                  <Text style={styles.headerText}> {this.props.userTeam.currentScheme} </Text>
                  <MdArrowDropdown fontSize="17px" style={{ marginRight: 5, marginTop: 3 }} />
                </TouchableOpacity>
              </React.Fragment>
              :
              <React.Fragment>
                <Text style={{ flex: 3 }}>Остават още {unfilledCount} играча</Text>
                <BudgetContainer budget={this.props.budget} />
              </React.Fragment>
            }
          </View>

          {!this.props.userTeam.isReady &&
            <React.Fragment>
              {this.state.teamAutoGenerated &&
                <TouchableOpacity style={styles.autoCleanContainer}
                  onPress={this.resetTeam}>
                  <Text style={styles.autoFillText}>ИЗЧИСТИ</Text>
                  <Image source={resetTeamIcon} style={styles.autoFillImage} />
                </TouchableOpacity>}
              <TouchableOpacity style={styles.autoFillContainer} onPress={this.autoFillTeam}>
                <Text style={styles.autoFillText}>АВТОМАТИЧНО ИЗБИРАНЕ НА ОТБОР</Text>
                <Image style={styles.autoFillImage} source={autoFillImage} />
              </TouchableOpacity>
            </React.Fragment>}

          <Playground
            onSelectPlayer={this.showPlayerPopup}
            showPrice={!this.props.userTeam.isReady}
            onAddPlayer={this.addPlayerToTeam}
            {...this.props.userTeam} />

          {!this.props.userTeam.isReady &&
            <TouchableOpacity onPress={this.setTeamReady} style={confirmButtonTeamStyles}>
              <Text style={styles.buttonText}>СЪЗДАЙ ОТБОР</Text>
            </TouchableOpacity>}
          <TabBar navigation={this.props.navigation} />
        </ScrollView>

      </React.Fragment>
    );
  }
}


const mapDispatchToProps = dispatch => {
  return {
    getTeam: () => dispatch(getTeam()),
    autoFillTeam: () => dispatch(autoFillTeam()),
    resetTeam: () => dispatch(resetTeam()),
    makeTeamReady: () => dispatch(makeTeamReady()),
    changePlayer: (playerInId, playerOutId) => dispatch(changePlayer(playerInId, playerOutId)),
    changeScheme: id => dispatch(changeScheme(id)),
    setBudget: budget => dispatch(setBudget(budget)),
  }
}

const mapStateToProps = state => {

  return {
    isAuto: state.userTeam.isAuto,
    userTeam: state.userTeam.userTeam,
    championshipInfo: state.championship.championshipInfo,
    roomInfo: state.room.roomInfo,
    budget: state.userInfo.budget,
    errorCode: state.errors.code
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(Team);

const styles = EStyleSheet.create({
  container: {
    width: '100%',
    alignItems: 'center',
    justifyContent: 'space-between',
  },
  scrollView: {
    width: '100%',
    flex: 1,
    minHeight: '100%',
  },
  headerView: {
    height: 60,
    width: '100%',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
    paddingLeft: 10,
    backgroundColor: '#CCD4E1',
    zIndex: 2
  },
  headerText: {
    fontSize: 17
  },
  schemeContainer: {
    flexDirection: 'row',
    width: 100
  },
  chipsContainer: {
    position: 'absolute',
    top: 70,
    zIndex: 5,
    width: '100%',
    flexDirection: 'row',
    backgroundColor: 'transparent',
    alignItems: 'center',
    justifyContent: 'center',
    marginHorizontal: 20
  },
  useChipButton: {
    backgroundColor: '#2053A4',
    borderWidth: 1,
    borderColor: '#3474D5',
    paddingHorizontal: '0.2rem',
    marginHorizontal: 5,
    borderRadius: 3,
    height: 50,
    justifyContent: 'center',
    alignItems: 'center'
  },
  chipText: {
    color: 'white',
    fontSize: 10
  },
  disabledChip: {
    opacity: 0.5
  },
  activeText: {
    color: '#63CE76'
  },
  autoFillContainer: {
    width: '50%',
    marginTop: 45,
    borderRadius: 10,
    alignItems: 'center',
    justifyContent: 'center',
    flexDirection: 'row',
    backgroundColor: '#2053A4',
    paddingVertical: 7,
    position: 'absolute',
    zIndex: 3,
    top: 130
  },
  autoCleanContainer: {
    width: '50%',
    marginTop: 40,
    borderRadius: 10,
    alignItems: 'center',
    justifyContent: 'center',
    flexDirection: 'row',
    backgroundColor: '#B75C5D',
    paddingVertical: 7,
    position: 'absolute',
    zIndex: 3,
    top: 95
  },
  autoFillImage: {
    width: 20,
    height: 20
  },
  autoFillText: {
    fontSize: 11,
    color: 'white',
    width: '70%',
    textAlign: 'center'
  },
  confirmButton: {
    justifyContent: 'center',
    alignItems: 'center',
    width: '50%',
    height: 50,
    backgroundColor: '#E1CB4D',
    borderRadius: 10,
    marginTop: 10,
    marginLeft: '5%',
    marginBottom: 10
  },
  buttonText: {
    fontWeight: 'bold',
    fontSize: 14
  }
});
