import React from 'react';
import {
  StyleSheet, Text, View, Image,
  Button, TouchableOpacity, ScrollView
} from 'react-native';
import Playground from '../../Common/components/Playground';
import LoadingScreen from '../../Common/components/LoadingScreen';
import Background from '../../Common/components/Background';
import RefreshContainer from '../../Common/components/RefreshContainer';
import PlayerProfilePopup from '../../Team/components/PlayerProfilePopup';
import HeaderWithBack from '../../Common/components/HeaderWithBack';

import transferIcon from '../../assets/transfers-arrow.svg.png';
import budgetIcon from '../../assets/budget.svg.png';
import { connect } from 'react-redux';
import { getTeam } from '../actions/team';
import { getRounds } from '../actions/rounds';
import TabBar from '../../Common/components/TabBar';
import TopBar from '../../Common/components/TopBar';
import { getPlayerPointsColor } from "../../Common/utils/utils.js";

class Points extends React.Component {

  state = {
    selectedPlayerId: null,
    refreshing: false
  }

  static navigationOptions = {
    title: 'Точки',
  };

  showPopup = player => {
    this.setState({
      selectedPlayerId: player.realPlayerId
    });
  }

  hidePopup = () => {
    this.setState({
      selectedPlayerId: null
    });
  }

  _onRefresh = () => {
    this.setState({ refreshing: true });
    const roomId = this.props.roomInfo.room_id || this.props.roomInfo.id;
    const userId = this.props.navigation.getParam('userId', null);
    this.props.getTeam(null, roomId, userId);
    this.setState({ loadingTeam: true });
  }

  componentDidMount() {
    if (!this.props.roomInfo) {
      this.props.navigation.navigate('Home');
      return;
    }
    this._onRefresh();
    this.props.getRounds(window.localStorage.getItem("championshipId"));
  }

  componentDidUpdate(prevProps) {
    if (prevProps.userTeam !== this.props.userTeam) {
      this.setState({ loadingTeam: false });
    }
    if (this.state.refreshing && prevProps.userTeam !== this.props.userTeam) {
      this.setState({ refreshing: false });
    }
    if (prevProps.roomInfo !== this.props.roomInfo || (this.props.navigation !== prevProps.navigation)) {
      this._onRefresh();
    }

  }

  render() {
    if (!this.props.userTeam || !this.props.rounds || this.state.loadingTeam)
      return <LoadingScreen />;
    const userId = this.props.navigation.getParam('userId', null);
    const { roomInfo } = this.props;

    let allRounds = [], currentRound = { key: 1, label: "КРЪГ 1" };
    allRounds = this.props.rounds.map(round => {
      return {
        key: round.id,
        label: round.name
      }
    });
    currentRound = allRounds.find(r => r.key === this.props.currentRoundId);

    return (
      <RefreshContainer
        refreshing={this.state.refreshing}
        onRefresh={this._onRefresh}>
        <Background />
        <TopBar navigation={this.props.navigation} />
        {userId && <HeaderWithBack title={"Tочки"} navigation={this.props.navigation} />}
        <PlayerProfilePopup
          isVisible={this.state.selectedPlayerId}
          onDismiss={this.hidePopup}
          playerId={this.state.selectedPlayerId}
          roundId={currentRound.key}
          forPoints={true} />

        <View style={styles.headerView}>
          <View style={styles.statContainer}>
            <View style={styles.statWithIcon}>
              <Image source={budgetIcon} style={styles.headerIcon} />
              <Text style={styles.statText}>{roomInfo.prize} лв.</Text>
            </View>
            <Text style={styles.statDescription}>Максимална печалба</Text>
          </View>
        </View>
        <View style={[styles.pointsHeaderView, userId ? { marginTop: 50 } : null]}>
          <View style={styles.roundContainer}>
            <Text style={styles.roundText}> {currentRound.label} </Text>
          </View>

          <View style={styles.pointsCointainer}>
            <Text style={[styles.points, getPlayerPointsColor(this.props.userTeam.playerPoints || 0)]}>{this.props.userTeam.playerPoints || 0}</Text>
            <Text style={styles.pointsText}>точки</Text>
          </View> 

          <View style={styles.pointsCointainer}>
            <Text style={styles.username}>{this.props.userTeam.teamName}</Text>
            <Text style={styles.pointsText}>{this.props.userTeam.userFullName}</Text>
          </View>
        </View>

        <Playground
          onSelectPlayer={this.showPopup}
          {...this.props.userTeam}
          isReady={true} />
        <TabBar navigation={this.props.navigation} />
      </RefreshContainer>
    );
  }
}

const mapDispatchToProps = (dispatch, ownProps) => {
  return {
    getTeam: (roundId, roomId, userId) => dispatch(getTeam(roundId, roomId, userId)),
    getRounds: (id) => dispatch(getRounds(id))
  }
}

const mapStateToProps = (state, ownProps) => {
  return {
    userTeam: state.points.userTeam,
    rounds: state.rounds.rounds,
    roomInfo: state.room.roomInfo,
    currentRoundId: state.rounds.currentRoundId
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(Points);

const styles = StyleSheet.create({
  container: {
    alignItems: 'center',
    justifyContent: 'space-between',
    minHeight: '60%'
  },
  scrollView: {
    width: '100%',
    height: '100%'
  },
  headerView: {
    height: 70,
    width: '100%',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-around',
    backgroundColor: '#CCD4E1'
  },
  headerText: {
    fontSize: 17
  },
  headerIcon: {
    width: 20,
    height: 20,
    marginRight: 2
  },
  statWithIcon: {
    flexDirection: 'row',
  },
  statContainer: {
    marginVertical: 7,
    justifyContent: 'center',
    alignItems: 'center'
  },
  statText: {
    fontWeight: 'bold',
    fontSize: 20
  },
  statDescription: {
    fontSize: 14
  },
  pointsHeaderView: {
    height: 70,
    width: '100%',
    backgroundColor: 'rgba(0,0,0,0.5)',
    position: 'absolute',
    top: 140,
    zIndex: 400,
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-around'
  },
  roundContainer: {
    flexDirection: 'row',
    width: 90
  },
  roundText: {
    color: 'white',
    fontSize: 20
  },
  points: {
    color: '#72E86C',
    fontWeight: 'bold',
    fontSize: 25
  },
  pointsText: {
    color: 'white',
    fontSize: 15
  },
  pointsCointainer: {
    justifyContent: 'center',
    alignItems: 'center'
  },
  username: {
    color: 'white',
    fontSize: 18,
    fontWeight: 'bold'
  }
});
