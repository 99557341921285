import React, { Component } from 'react';
import { View, StyleSheet, TouchableOpacity, Image, Text } from 'react-native';
import EStyleSheet from 'react-native-extended-stylesheet';
import back from '../../assets/back-arrow.svg.png';

const headerWithBack = props => {
    const { title, navigation, onBackPressed } = props;

    return (
        <View style={styles.container}>
            <TouchableOpacity onPress={() => {
                onBackPressed && onBackPressed();
                navigation.goBack();
            }}>
                <Image source={back} style={styles.back} />
            </TouchableOpacity>

            <Text style={styles.title}>{title}</Text>
        </View>
    )
}

const styles = EStyleSheet.create({
    container: {
        width: '100%',
        height: '3rem',
        minHeight: 50,
        backgroundColor: 'white',
        alignItems: 'center',
        flexDirection: 'row'
    },
    back: {
        width: 60,
        height: 35,
        resizeMode: 'contain',
        marginRight: 10
    },
    title: {
        fontWeight: 'bold',
        fontSize: 20
    }
});
 
export default headerWithBack;