import React from "react";
import { View, Text, StyleSheet, Image, TouchableOpacity, ScrollView, ActivityIndicator } from "react-native";
import LoadingScreen from '../../Common/components/LoadingScreen';
import { connect } from 'react-redux';
import {
    getTournamentOverview,
    getTournamentRules,
    getTournamentFixtures,
    getTournamentEntries,
    getTournamentPrizes
} from "../actions/tournamentDetails";
import PurpleBackground from '../../Common/components/PurpleBackground';
import { createStyles, maxWidth, maxHeight } from 'react-native-media-queries';
import { BASE_URL } from "../../Common/config/config";
import TournamentOverview from "../components/TournamentOverview";
import TournamentRules from "../components/TournamentRules";
import TournamentFixtures from "../components/TournamentFixtures"
import TournamentEntries from "../components/TournamenEntries";
import TournamentPrizes from "../components/TournamentPrizes"
import InformationDialog from "../../HomeTournaments/components/InformationDialog";
import * as translations from "../../Common/utils/translations"
import moment from "moment"
import { getDomainOperator } from "../../Common/utils/domainMapper";

export const OVERVIEW = 1;
export const FIXTURES = 2;
export const ENTRIES = 3;
export const PRIZES = 4;
export const RULES = 5;

class TournamenDetails extends React.Component {

    state = {
        selectedSubgame: null,
        selectedId: 1,
        infoDialogOpen: false,
        infoDialogContent: "",
        isGameStarted: false
    }

    componentDidMount() {
        //check if action is pop and if so get from localstorage and delete
        const state = JSON.parse(localStorage.getItem("TournamentDetails"));
        if (state) {
            this.setState(state);
            localStorage.removeItem("TournamentDetails")
        } else {
            this.props.getTournamentOverview(this.props.navigation.getParam("id", "0"));
        }
    }

    componentWillReceiveProps(nextProps) {
        if (nextProps.overView && !this.state.selectedSubgame || (nextProps.overView != this.props.overView && (!this.state.selectedSubgame || !nextProps.overView.subgames.find(t => t.id == this.state.selectedSubgame.id)))) {
            this.setState({
                selectedSubgame: nextProps.overView.subgames[0],
                isGameStarted: moment(nextProps.overView.subgames[0].overview.start_time).isBefore(moment()) 
            })
        }
    }

    getNewData = (id, seledtedSubGameId) => {
        switch (id) {
            case OVERVIEW: {
                this.props.getTournamentOverview(this.props.navigation.getParam("id", "0"));
                break;
            }
            case RULES: {
                this.props.getTournamentRules(this.props.navigation.getParam("id", "0"))
                break;
            }
            case FIXTURES: {
                this.props.getTournamentFixtures(this.props.navigation.getParam("id", "0"))
                break;
            }
            case ENTRIES: {
                this.props.getTournamentEntries(seledtedSubGameId, null, 1)
                break;
            }
            case PRIZES: {
                this.props.getTournamentPrizes(seledtedSubGameId)
                break;
            }
        }
    }

    getButtonComponent = (text, id) => {

        return <div style={styles.tabDimentions}
            onClick={() => {
                this.getNewData(id, this.state.selectedSubgame.id);
                this.setState({ selectedId: id })
            }}>
            <TouchableOpacity
                style={[id == this.state.selectedId ? styles.selectedTab : styles.nonSelectedTab]}>
                <Text style={id == this.state.selectedId ? styles.selectedTabText : styles.nonSelectedTabText}>{text}</Text>
            </TouchableOpacity>
        </div>
    }

    getContentComponent = () => {
        switch (this.state.selectedId) {
            case OVERVIEW: {
                if (!this.props.overView.subgames.find(t => t.id == this.state.selectedSubgame.id) || this.props.isLoading) {
                    return <ActivityIndicator size={"large"} color={design.primaryColor} />;
                }
                return <TournamentOverview overView={this.props.overView.subgames.find(t => t.id == this.state.selectedSubgame.id).overview} />
            }
            case RULES: {
                if (!this.props.rules) return <ActivityIndicator size={"large"} color={design.primaryColor} />;
                return <TournamentRules
                    navigation={this.props.navigation}
                    onScoringClicked={(id) => {
                        //put state in localstorage
                        localStorage.setItem("TournamentDetails", JSON.stringify(this.state));
                        this.props.navigation.navigate("TournamentScoring", { id })
                    }
                    }
                    subgameId={this.state.selectedSubgame.id}
                    rules={this.props.rules.subgames.find(t => t.id == this.state.selectedSubgame.id).rules} />
            }
            case FIXTURES: {

                if (!this.props.fixtures) return <ActivityIndicator size={"large"} color={design.primaryColor} />;
                return <TournamentFixtures fixtures={this.props.fixtures.fixtures} />
            }
            case ENTRIES: {
                return <TournamentEntries
                    onInfoClicked={() => this.setState({ infoDialogOpen: true, infoDialogContent: translations.tKey("str_tooltip_points_pp") })}
                    entries={this.props.entries}
                    isLoading={this.props.isLoading}
                    onNextRequest={(page, name, sort) => {
                        this.props.getTournamentEntries(this.state.selectedSubgame.id, name, page, sort)
                    }}
                />
            }
            case PRIZES: {
                if (!this.props.prizes) return <ActivityIndicator size={"large"} color={design.primaryColor} />
                return <TournamentPrizes prizes={this.props.prizes} />
            }
            default: return null
        }
    }

    closeInfoDialog = () => {
        this.setState({ infoDialogOpen: false })
    }

    hasLoginToPalms = () => {
        let accountId, sessionId;
        if (window.location.search.indexOf('session_id') !== -1) {
            accountId = window.location.search.slice(1).split('&').find(p => p.split('=')[0] === 'ica').split('=')[1];
            sessionId = window.location.search.slice(1).split('&').find(p => p.split('=')[0] === 'session_id').split('=')[1];

            window.sessionStorage.setItem('accountId', accountId);
            window.sessionStorage.setItem('sessionId', sessionId);
        } else {
            accountId = window.sessionStorage.getItem('accountId');
            sessionId = window.sessionStorage.getItem('sessionId');
        }
        return !(!accountId) && accountId != "demo_session" && !(!sessionId);
    }

    render() {
        const { navigation, overView, rules } = this.props;
        const { selectedSubgame } = this.state;
        if (!overView || !selectedSubgame) return <LoadingScreen />;

        let headerButtonSize = null;
        //TODO: Handle this hacky solution from the API, remove the unnecessary subgames
        if(overView.subgames.length > 1) {
            overView.subgames = overView.subgames.slice(0, 1);
          }
        if (overView.subgames.length == 1) {
            headerButtonSize = styles.headerButtonSizeLarge;
        } else if (overView.subgames.length == 2) {
            headerButtonSize = styles.headerButtonSizeMedium;
        } else {
            headerButtonSize = styles.headerButtonSizeSmall;
        }
        return (
            <div style={styles.container}>
                <InformationDialog
                    title={translations.tKey("str_information_dialog_title")}
                    body={this.state.infoDialogContent}
                    open={this.state.infoDialogOpen}
                    onClose={this.closeInfoDialog}
                />

                <View style={styles.purpleHeader}>
                    <TouchableOpacity
                      style={styles.exitButton}
                      onPress={() => navigation.navigate("Home")}
                    >
                        <Text style={{ color: "white", fontWeight: 'bold', fontSize: 15 }}>x</Text>
                    </TouchableOpacity>

                    <View style={styles.headerContainer}>
                        <Image style={styles.image} source={BASE_URL + overView.flag.url} />
                        <Text style={styles.headerText}>{overView.name}</Text>
                        <View style={styles.headerButtonsContainer}>
                            {/* TODO: Handle the subgame from api, this will become unnecessary I suppose. */}
                            {/* {overView.subgames.map(subgame => {
                                return <div style={headerButtonSize} onClick={() => {
                                    this.setState({ selectedSubgame: subgame });
                                    this.getNewData(this.state.selectedId, subgame.id);
                                }}>
                                    <TouchableOpacity
                                        style={[selectedSubgame.id == subgame.id ? styles.headerPressedButton : styles.headerNonPressedButton]}>
                                        <Text style={selectedSubgame.id == subgame.id ? styles.headerPressedButtonText : styles.headerNonPressedButtonText}>
                                            {subgame.entry_fee ? `${subgame.entry_fee.toFixed(2)}${translations.tKey("str_currency")}` : translations.tKey("str_details_free")}
                                        </Text>
                                    </TouchableOpacity>
                                </div>
                            })} */}
                        </View>
                    </View>
                </View>
                <div style={styles.mainContainer}>
                    <View style={styles.tabsContainer}>
                        {this.getButtonComponent(translations.tKey("str_details_overview"), OVERVIEW)}
                        {this.getButtonComponent(translations.tKey("str_details_fixtures"), FIXTURES)}
                        {this.getButtonComponent(translations.tKey("str_details_entries"), ENTRIES)}
                        {this.getButtonComponent(translations.tKey("str_details_prizes"), PRIZES)}
                        {this.getButtonComponent(translations.tKey("str_details_rules"), RULES)}
                    </View>
                    <ScrollView style={styles.mainContentDimentions} contentContainerStyle={styles.mainContent}>
                        {this.getContentComponent()}
                    </ScrollView>
                </div>

                <div style={styles.enterGameButtons}>
                    {overView.subgames.map(t => (
                      <View style={[styles.subGameColumn, headerButtonSize]}>
                          {this.state.isGameStarted ?
                            <div onClick={() => {
                                if (!this.hasLoginToPalms()) {
                                    localStorage.setItem("DashboardPending", JSON.stringify({ subId: t.id }));
                                } else {
                                    navigation.navigate("Dashboard", { subId: t.id });
                                }
                            }}>
                                <TouchableOpacity style={styles.viewButton} >
                                    <Text style={styles.playButtonText}>{translations.tKey("str_tcard_action_view")}</Text>
                                </TouchableOpacity>
                            </div>
                            : <div onClick={() => { navigation.navigate("EnterTournament", { id: this.props.navigation.getParam("id", "0"), subId: selectedSubgame.id }) }}>
                                <TouchableOpacity style={styles.playButton} >
                                    <Text style={styles.playButtonText}>
                                        {
                                            t.entry_fee
                                              ? `${translations.tKey("str_action_play")} ${t.entry_fee.toFixed(2)}${translations.tKey("str_currency")}`
                                              : translations.tKey("str_action_play_free")
                                        }
                                    </Text>
                                </TouchableOpacity>
                            </div>}
                      </View>
                    ))}
                </div>
            </div>
        );
    }
};

const mapDispatchToProps = (dispatch, ownProps) => {
    return {
        getTournamentOverview: (id) => dispatch(getTournamentOverview(id)),
        getTournamentRules: id => dispatch(getTournamentRules(id)),
        getTournamentFixtures: id => dispatch(getTournamentFixtures(id)),
        getTournamentEntries: (id, name, page, sort) => dispatch(getTournamentEntries(id, name, page, sort)),
        getTournamentPrizes: id => dispatch(getTournamentPrizes(id))
    }
}

const mapStateToProps = (state, ownProps) => {
    return {
        overView: state.tournamentDetails.overView,
        rules: state.tournamentDetails.rules,
        fixtures: state.tournamentDetails.fixtures,
        entries: state.tournamentDetails.entries,
        prizes: state.tournamentDetails.prizes,
        isLoading: state.ui.isLoading
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(TournamenDetails);

const operator = getDomainOperator();

const design = operator.design;

const base = {
    container: {
        flex: 1,
        width: '100%',
        display: "flex",
        alignItems: 'center',
        justifyContent: 'center',
        flexDirection: 'column',
        position: 'relative'
    },
    purpleHeader: {
        width: "100%",
        minHeight: "300px",
        backgroundColor: design.primaryColor,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        position: 'relative'
    },
    headerContainer: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        width: '60%'
    },
    image: {
        width: 50,
        height: 50,
        resizeMode: 'cover',
        borderRadius: 25,
        border: `2px solid ${design.tertiaryColor}`,
        marginBottom: "10px",
        marginTop: "5px"
    },
    headerText: {
        color: 'white',
        fontSize: 17,
        fontWeight: 550
    },
    headerButtonsContainer: {
        display: 'flex',
        flexDirection: "row",
        alignItems: 'center',
        justifyContent: "space-evenly",
        marginVertical: "10px",
        width: "100%"
    },
    headerButtonSizeLarge: {
        width: "100%"
    },
    headerButtonSizeMedium: {
        width: "48.5%",
    },
    headerButtonSizeSmall: {
        width: "32.5%",
    },
    headerPressedButton: {
        backgroundColor: "white",
        borderRadius: "5px",
        paddingVertical: "7px",
        textAlign: "center"
    },
    headerPressedButtonText: {
        color: design.primaryColor,

        fontWeight: 'bold'
    },
    headerNonPressedButtonText: {
        color: "white",
    },
    headerNonPressedButton: {
        backgroundColor: "#552659",
        borderRadius: "5px",
        paddingVertical: "7px",
        textAlign: "center"
    },
    mainContainer: {
        width: "90%",
        margin: '20px 5%',
        minHeight: '500px',
        display: "flex",
        flexDirection: 'column'
    },
    tabsContainer: {
        width: "100%",
        display: "flex",
        flexDirection: "row",
        alignItems: 'center',
        justifyContent: "space-evenly"
    },
    enterGameButtons: {
        width: '90%',
        margin: '15px 5%',
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'space-between',
    },
    tabDimentions: {
        width: "19.5%",
        textAlign: "center"
    },
    selectedTab: {
        backgroundColor: "#963CFF",
        paddingTop: "10px",
        paddingBottom: "10px",
        borderRadius: "5px",
    },
    selectedTabText: {
        color: "white",
        fontWeight: 550,
    },
    nonSelectedTab: {
        backgroundColor: "#EFEFEF",
        fontWeight: 550,
        fontSize: 14,
        paddingTop: "10px",
        paddingBottom: "10px",
        borderRadius: "5px",
    },
    nonSelectedTabText: {
        color: "#B6B6B6",
        fontSize: 14
    },
    playButton: {
        width: "100%",
        backgroundColor: design.secondaryColor,
        paddingTop: "10px",
        paddingBottom: "10px",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        borderRadius: "4px"
    },
    viewButton: {
        backgroundColor: design.tertiaryColor,
        width: "100%",
        paddingTop: "10px",
        paddingBottom: "10px",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        borderRadius: "4px"
    },
    playButtonText: {
        fontSize: 14,
        fontWeight: "bold"
    },
    mainContentDimentions: {
        width: "100%"
    },
    mainContent: {
        paddingVertical: "20px",
    },
    exitButton: {
        backgroundColor: "#FF085D",
        width: 22,
        height: 22,
        position: "absolute",
        top: 0,
        right: 0,
        display: 'flex',
        alignItems: 'center',
        justifyContent: "center",
        zIndex: 9999
    }
};

const styles = createStyles(
    base,
    maxWidth(576, {
        playButtonText: {
            fontSize: 11
        },
        selectedTabText: {
            fontSize: 12
        },
        nonSelectedTabText: {
            fontSize: 12
        },
    }),
    maxWidth(376, {
        selectedTabText: {
            fontSize: 10
        },
        nonSelectedTabText: {
            fontSize: 10
        },
        playButtonText: {
            fontSize: 12
        },
    })
)

