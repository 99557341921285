import { getDomainOperator } from './domainMapper';
import { logTrace } from './logger';

const operator = getDomainOperator();

const design = operator.design;

export const getPlayerPointsColor = (points, isBackground) => {
  if (points <= 0) {
    return isBackground ? {backgroundColor: '#A50039'} : {color: '#A50039'};
  }
  if (points >= 1 && points <= 3) {
    return isBackground ? {backgroundColor: '#FF085D'} : {color: '#FF085D'};
  }
  if (points >= 4 && points <= 5) {
    return isBackground ? {backgroundColor: '#EBFF00'} : {color: '#EBFF00'};
  }
  if (points >= 6 && points <= 9) {
    return isBackground ? {backgroundColor: design.secondaryColor} : {color: design.secondaryColor};
  }
  return isBackground ? {backgroundColor: '#02894E'} : {color: '#02894E'};
};

export const numberWithCommas = (x) => {
  return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
};

export const isSafariIOS = () => {
  const ua = window.navigator.userAgent;

  const iOS = !!ua.match(/iP(ad|hone)/i);
  const webkit = !!ua.match(/WebKit/i);

  return iOS && webkit && !ua.match(/CriOS/i);
};

export const isDev = process.env.NODE_ENV === 'development';

export const warn = (msg) => {
  if (isDev) {
    console.warn(msg);
  }
};

/***
 * Formats the player name in the required format
 *
 * @param player
 * @param {('firstAndLast' | 'firstFormatted' | 'last' | 'first')} format
 *
 * @returns {string} The formatted name
 */
export const formatPlayersName = (player, format) => {
  if (!player.firstName && !player.lastName) {
    return player.name;
  }

  if (!player.firstName.trim()) {
    format = 'last';
  }

  const firstName = player.firstName.trim();
  const lastName = player.lastName.trim();

  switch (format) {
    case 'firstAndLast':
      return `${firstName} ${lastName}`;
    case 'firstFormatted':
      return `${firstName[0].toUpperCase()}. ${lastName}`;
    case 'first':
      return firstName;
    case 'last':
      return lastName;
    default:
      return formatPlayersName(player, 'firstFormatted');
  }
};

export const getHostParams = (params) => {
  let publicId = params.get('public_id') ? params.get('public_id') : window.localStorage.getItem('publicId') ? window.localStorage.getItem('publicId') : '';
  window.localStorage.setItem('publicId', publicId);

  if(operator.login !== 'sp') {
    return [publicId];
  }

  let host = params.get('host') ? params.get('host') : window.localStorage.getItem('host') ? window.localStorage.getItem('host') : '';
  let lang = params.get('lang') ? params.get('lang') : window.localStorage.getItem('lang') ? window.localStorage.getItem('lang') : '';
  window.localStorage.setItem('host', host);
  window.localStorage.setItem('lang', lang);
  
  return [publicId, host, lang];
}