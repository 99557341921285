import React from 'react';
import { connect } from 'react-redux';
import { View, Text, StyleSheet } from 'react-native';
import Background from '../../Common/components/Background';
import HeaderWithBack from '../../Common/components/HeaderWithBack';


export default class Rules extends React.Component {
	render() {
		return (
			<React.Fragment>
				<HeaderWithBack title={'Правила'} navigation={this.props.navigation}/>
				<View style={{height: 300}}>
					<Background style={{height: 700, minHeight: 700, maxHeight: 700}}/>
					<View style={{margin: 20, flex: 1, justifyContent: 'flex-end'}}>
						<Text style={styles.mainText}>Официални правила на FPL</Text>
						<Text style={styles.mainText}>Fantasy Manager</Text>
					</View>
				</View>
				<div style={{zIndex: 4, backgroundColor: 'white', overflow: 'scroll', maxHeight: '700px'}}>
					{renderHTML()}
				</div>
			</React.Fragment>);
	}
}


const styles = StyleSheet.create({
	mainText: {
		fontSize: 25,
		color: 'white',
		fontWeight: 'bold',
		zIndex: 5
	}
});


const renderHTML = () => {
	return (
		<section style={{maxHeight: '100%', overflow: 'auto'}}>
			<div class="kc-row-container  kc-container">
				<div class="kc-wrap-columns">
					<div class="kc-elm kc-css-518657 kc_col-sm-12 kc_column kc_col-sm-12">
						<div class="kc-col-container">
							<div class="kc-elm kc-css-784944 kc_text_block">
								<h3>Размер на отбора</h3>
								<p>Отборът ти от 15 човека трябва да е съставен от:</p>
								<ul>
									<li>2 Вратари</li>
									<li>5 Защитника</li>
									<li>5 Полузащитника</li>
									<li>3 Нападателя</li>
								</ul>
								<h3>Бюджет</h3>
								<p>Бюджетът, с който ще стартираш играта, е&nbsp;<strong><u>10 000 лева</u></strong></p>
								<h3>Играчи от отбор</h3>
								<p>Може да избираш до 3-ма играчи от всеки отбор от Първа професионална лига.</p>
								<h3>Кръгове</h3>
								<p>В FPL Fantasy Manager са включени редовните 26 кръга от първенството и плейофната фаза; не са
									включени баражите за изпадане.</p>
								<h3>Избери стартовите 11</h3>
								<p>От твоите 15 играча ти трябва да избереш 11 титуляри, които ще стартират кръга.</p>
								<p>Всичките ти точки за кръга ще бъдат отбелязани/натрупани от тези 11 играчи, но ако един или повече не
									играят, те могат автоматично да бъдат заменени.</p>
								<p>Твоят отбор може да играе в която и да е формация, при условие, че във всеки един момент тя включва:
									1 вратар, поне 3-ма защитници и поне 2-ма нападатели.</p>
								<p><u>Възможни схеми:</u></p>
								<p>4-4-2, 5-3-2, 3-5-2, 4-3-3, 3-4-3</p>
								<p><u>Допълнителни платени схеми:</u></p>
								<p>4-5-1, 5-4-1, 2-5-3, 5-2-3</p>
								<h3>Капитан и Вицекапитан</h3>
								<p>От стартовите 11 трябва да избереш Капитан и Вицекапитан. Точките на твоя Капитан ще бъдат
									удвоени.</p>
								<p>Ако твоя Капитан не играе в кръга, той ще бъде сменен от Вицекапитана.</p>
								<p>Ако и Вицекапитана не играе в кръга, тогава никой от играчите ти няма да получи удвоени точки.</p>
								<h3>Правила за автоматични смени</h3>
								<p>Твоите смени ти дават сигурност при непредвидени събития като контузии. Смените ти автоматично
									заменят стартовите играчи, които не играят в кръга.</p>
								<p>В зависимост от поредността на резервата, която си определил преди кръга, автоматичните смени се
									обработват в края на кръга, както следва:</p>
								<ul>
									<li>Ако Вратарят ти не играе в кръга, той ще бъде сменен от резервния вратар.</li>
									<li>Ако и двамата Вратари не играят в кръга, няма да получиш точки.</li>
									<li>Ако някой от полевите играчите не играе в кръга, той ще бъде заменен от първата възможна резерва
										на идентична позиция, която играе в кръга&nbsp;(например, ако стартовият ти отбор има 3-ма
										защитници, защитник може да бъде заменен само от друг защитник).
									</li>
								</ul>
								<h3>Трансфери</h3>
								<p>След като избереш своя отбор, можеш да купуваш и продаваш играчи на трансферния пазар.</p>
								<p>След края на всеки Кръг ще получиш един безплатен трансфер за предстоящия Кръг. Всеки допълнителен
									трансфер, който ще направиш в рамките на един и същ Кръг, ще приспадне 4 точки от общия ти
									резултат&nbsp;Генерален Резултат.</p>
								<p>Ако не използваш безплатния трансфер, можеш да направиш допълнителен безплатен трансфер в следващия
									Кръг. Ако не използваш този спестен безплатен трансфер в следващия Кръг, той ще бъде пренесен, докато
									не го направиш.&nbsp;<strong>Никога не можеш да имаш повече от 1 запазен трансфер&nbsp;</strong>(максисмум
									два свободни трансфера на кръг).</p>
								<h3>Неограничени свободни трансфери&nbsp;(Wildcards)</h3>
								<p>Опцията Неограничени свободни трансфери (Wildcards) ти дава възможност за неограничен брой трансфери.
									След като&nbsp;<strong>активираш Wildcard</strong>, ти имаш право да го използваш в периода на
									трансферния прозорец преди Кръга, като след затварянето му Wildcard ще е изчерпан.</p>
								<p>Неограничени свободни трансфери&nbsp;(Wildcards)&nbsp;<strong>може да се използва два пъти през
									сезона</strong>&nbsp;– 1) преди зимната пауза в първенството до&nbsp;
									<strong>09/12/2017</strong>&nbsp;и 2) до първия мач от плейофната фаза.</p>
								<h3>Точки</h3>
								<p>През сезона твоите футболисти ще получат точки въз основа на тяхното представяне в реалните мачове на
									отборите си в Първа Професионална Лига, както следва:</p>
								<table class="points" border="0">
									<tbody>
									<tr>
										<th>СЪБИТИЕ</th>
										<th>ТОЧКИ</th>
									</tr>
									<tr>
										<td>За игра до 60-та минута в мача</td>
										<td style={{textAlign: 'center'}}><strong>1</strong></td>
									</tr>
									<tr>
										<td>За игра 60 минути или повече в мача</td>
										<td style={{textAlign: 'center'}}><strong>2</strong></td>
									</tr>
									<tr>
										<td>За всеки вкаран гол от ВРАТАР</td>
										<td style={{textAlign: 'center'}}><strong>10</strong></td>
									</tr>
									<tr>
										<td>За всеки вкаран гол от ЗАЩИТНИК</td>
										<td style={{textAlign: 'center'}}><strong>6</strong></td>
									</tr>
									<tr>
										<td>За всеки вкаран гол от ПОЛУЗАЩИТНИК</td>
										<td style={{textAlign: 'center'}}><strong>5</strong></td>
									</tr>
									<tr>
										<td>За всеки вкаран гол от НАПАДАТЕЛ</td>
										<td style={{textAlign: 'center'}}><strong>4</strong></td>
									</tr>
									<tr>
										<td>За всяка асистенция</td>
										<td style={{textAlign: 'center'}}><strong>3</strong></td>
									</tr>
									<tr>
										<td>За Чиста мрежа от ВРАТАР</td>
										<td style={{textAlign: 'center'}}><strong>5</strong></td>
									</tr>
									<tr>
										<td>За Чиста мрежа от ЗАЩИТНИК</td>
										<td style={{textAlign: 'center'}}><strong>4</strong></td>
									</tr>
									<tr>
										<td>За Чиста мрежа от ПОЛУЗАЩИТНИК</td>
										<td style={{textAlign: 'center'}}><strong>1</strong></td>
									</tr>
									<tr>
										<td>За всеки три спасявания от ВРАТАР</td>
										<td style={{textAlign: 'center'}}><strong>1</strong></td>
									</tr>
									<tr>
										<td>За всяка спасена дузпа от ВРАТАР</td>
										<td style={{textAlign: 'center'}}><strong>5</strong></td>
									</tr>
									<tr>
										<td>За всяка изпусната дузпа</td>
										<td style={{textAlign: 'center'}}><strong>-2</strong></td>
									</tr>
									<tr>
										<td>За всеки 2-ри допуснат гол от ВРАТАР или ЗАЩИТНИК</td>
										<td style={{textAlign: 'center'}}><strong>-1</strong></td>
									</tr>
									<tr>
										<td>За всеки жълт картон</td>
										<td style={{textAlign: 'center'}}><strong>-1</strong></td>
									</tr>
									<tr>
										<td>За всеки червен картон</td>
										<td style={{textAlign: 'center'}}><strong>-3</strong></td>
									</tr>
									<tr>
										<td>За всеки автогол</td>
										<td style={{textAlign: 'center'}}><strong>-2</strong></td>
									</tr>
									</tbody>
								</table>
								<h3>Бонус Система</h3>
								<p>Футболистите получават също допълнителни точки в зависимост от тяхното представяне. Тримата с
									най-добър общ резултат на базата на нашата бонус системата прибавят съответно<b> 3т., 2т. или
										1т.</b>&nbsp;към реалните точки постигнати в мача. Бонус системата се формира по следния начин:</p>
								<table class="points" border="0">
									<tbody>
									<tr>
										<th>СЪБИТИЕ</th>
										<th>ТОЧКИ</th>
									</tr>
									<tr>
										<td>Гол НАПАДАТЕЛ</td>
										<td style={{textAlign: 'center'}}><b>6</b></td>
									</tr>
									<tr>
										<td>Гол ПОЛУЗАЩИТНИК</td>
										<td style={{textAlign: 'center'}}><strong>5</strong></td>
									</tr>
									<tr>
										<td>Гол ЗАЩИТНИК</td>
										<td style={{textAlign: 'center'}}><b>4</b></td>
									</tr>
									<tr>
										<td>Гол ВРАТАР</td>
										<td style={{textAlign: 'center'}}><b>4</b></td>
									</tr>
									<tr>
										<td>Асистенция</td>
										<td style={{textAlign: 'center'}}><strong>3</strong></td>
									</tr>
									<tr>
										<td>Чиста мрежа ПОЛУЗАЩИТНИК&nbsp;(титуляр 60 минути)</td>
										<td style={{textAlign: 'center'}}><strong>0.5</strong></td>
									</tr>
									<tr>
										<td>Чиста мрежа ЗАЩИТНИК&nbsp;(титуляр 60 минути)</td>
										<td style={{textAlign: 'center'}}><strong>2</strong></td>
									</tr>
									<tr>
										<td>Чиста мрежа ВРАТАР&nbsp;(титуляр 60 минути)</td>
										<td style={{textAlign: 'center'}}><strong>3</strong></td>
									</tr>
									<tr>
										<td>1 спасяване</td>
										<td style={{textAlign: 'center'}}><strong>0.5</strong></td>
									</tr>
									<tr>
										<td>Жълт картон</td>
										<td style={{textAlign: 'center'}}><strong>-1</strong></td>
									</tr>
									<tr>
										<td>Червен картон</td>
										<td style={{textAlign: 'center'}}><b>-3</b></td>
									</tr>
									<tr>
										<td>Автогол</td>
										<td style={{textAlign: 'center'}}><b>-3</b></td>
									</tr>
									<tr>
										<td>В игра до 60-та минута</td>
										<td style={{textAlign: 'center'}}><b>0</b></td>
									</tr>
									<tr>
										<td>В игра след 60-та минута</td>
										<td style={{textAlign: 'center'}}><b>1</b></td>
									</tr>
									<tr>
										<td>Спасена дузпа</td>
										<td style={{textAlign: 'center'}}><b>3</b></td>
									</tr>
									<tr>
										<td>Изпусната дузпа</td>
										<td style={{textAlign: 'center'}}><b>-2</b></td>
									</tr>
									<tr>
										<td>Играч на мача в Gong.bg</td>
										<td style={{textAlign: 'center'}}><strong>3</strong></td>
									</tr>
									<tr>
										<td>Победа на отбор до 60-та минута</td>
										<td style={{textAlign: 'center'}}><b>0.5</b></td>
									</tr>
									<tr>
										<td>Загуба на отбор до 60-та минута</td>
										<td style={{textAlign: 'center'}}><b>-0.5</b></td>
									</tr>
									<tr>
										<td>2-ри допуснат гол от ЗАЩИТНИК</td>
										<td style={{textAlign: 'center'}}><strong>-1</strong></td>
									</tr>
									<tr>
										<td>2-ри допуснати гол от ВРАТАР</td>
										<td style={{textAlign: 'center'}}><strong>-0.5</strong></td>
									</tr>
									</tbody>
								</table>
								<h3>Чиста Мрежа</h3>
								<p>Чиста мрежа се присъжда за недопуснат гол, докато играча е на терена и играе&nbsp;<strong>най-малко
									60 минути.</strong></p>
								<p>Ако даден играч бъде заменен след 60-тата минута и отборът му допусне гол, това няма да се отрази на
									бонуса за Чиста мрежа.</p>
								<h3>Червен Картон</h3>
								<p>Ако играч получи червен картон, той ще продължи да бъде наказан за головете, допуснати от неговия
									отбор в текущия мач.</p>
								<p>Ако играч получи два жълти картона и съответно червен, ти ще получиш минус точки само за червения
									картон.</p>
								<p>Ако играч получи жълт картон и след него директен червен, ти ще получиш минус точки както за жълтия,
									така и за червения картон.</p>
								<h3>Играч на мача</h3>
								<p><b>Точките за Играч на Мача са част от нашата бонус система</b> и се взeмат от спечелелия играч при
									гласуването на сайта&nbsp;<a class="faqlink" href="https://gong.bg/" target="_blank"
																							 rel="noopener">Gong.bg</a></p>
								<h3>Асистенции</h3>
								<ul>
									<li>Асистенциите се присъждат на играча от отбора, който прави последния пас преди да бъде отбелязан
										гол. Асистенция се присъжда независимо дали при подаването има рикошет, избиване или докосване на
										противников играч.
									</li>
									<li>Ако даден удар към вратата е блокиран от противник, спасен от вратаря или удари гредата и при
										добавката се отбележе гол, се присъжда Асистенция на играчът, отправил последния удар.
									</li>
									<li>Ако изстрел на играч принуди противника да си вкара&nbsp;<strong>АВТОГОЛ</strong>, тогава се
										присъжда Асистенция на играча, отправил удара.
									</li>
									<li>В случай на&nbsp;<strong>ДУЗПА</strong>&nbsp;или&nbsp;<strong>СВОБОДЕН УДАР</strong>, играчът,
										който печели дузпата или свободния удар, получава Асистенция. Ако даден гол е отбелязан от играча
										спечелил дузпата или наказателния удар,&nbsp;<strong>НЕ СЕ</strong>&nbsp;присъжда Асистенция.
									</li>
								</ul>
								<h3>Статистики</h3>
								<p>Точките за асистент, присъдени във&nbsp;<strong>FPL Fantasy Manager</strong>, се изчисляват като се
									използват допълнителни статистически данни, които могат да се различават от другите сайтове. Например,
									някои други сайтове няма да показват Асистенции, когато играч е спечелил дузпа или свободен удар.</p>
								<p>Точките за всички останали статистики се взeмат от&nbsp;<a class="faqlink" href="http://fpleague.bg/"
																																							target="_blank"
																																							rel="noopener">fpleague.bg</a>&nbsp;. Това
									е официален сайт на Български Футболен Съюз и информацията в него се попълва и проверява от делегатите
									на БФС на мачовете.</p>
								<p>За да се избегнат съмнения, точките, присъдени в играта, могат да се променят до един час след
									последния сигнал на последния мач от който и да е ден. След като всички точки бъдат актуализирани в
									този ден, няма да бъдат направени допълнителни корекции на точките.</p>
								<h3>Безплатни Бонуси</h3>
								<p>Бонусите могат да бъдат използвани за подобряване на ефективността на отбора ти през сезона.</p>
								<p>В един Кръг може да се играе само по един бонус. Наличните бонуси са както следва:</p>
								<table class="points" border="0">
									<tbody>
									<tr>
										<th>ИМЕ</th>
										<th>ЕФЕКТ</th>
									</tr>
									<tr>
										<td>Троен Капитан</td>
										<td>Точките на Капитана се утрояват(вместо да се удвояват)&nbsp;в Кръга, в който е избран Бонуса.
										</td>
									</tr>
									<tr>
										<td>Пълен Отбор</td>
										<td>Вземат се точките на всички играчи от отбора ти, включително и резервите, в Кръга, за който е
											избран Бонуса.
										</td>
									</tr>
									<tr>
										<td>Двоен Вицекапитан</td>
										<td>Точките на Вицекапитан се удвояват като тези на Капитана в Кръга, за който е избран Бонуса.</td>
									</tr>
									</tbody>
								</table>
								<h3>Платени Бонуси</h3>
								<p>Имаш право да си закупиш допълнително всеки от безплатните бонуси само веднъж през целия шампионат на
									следните цени:</p>
								<table class="points" border="0">
									<tbody>
									<tr>
										<th>ИМЕ</th>
										<th>ЕФЕКТ</th>
										<th>ЦЕНА</th>
									</tr>
									<tr>
										<td>Троен Капитан</td>
										<td>Точките на Капитана се утрояват&nbsp;(вместо да се удвояват)&nbsp;в Кръга, в който е избран
											Бонуса.
										</td>
										<td>9 лв</td>
									</tr>
									<tr>
										<td>Пълен Отбор</td>
										<td>Вземат се точките на всички играчи от отбора ти, включително и резервите, в Кръга, за който е
											избран Бонуса.
										</td>
										<td>9 лв</td>
									</tr>
									<tr>
										<td>Двоен Вицекапитан</td>
										<td>Точките на Вицекапитан се удвояват като тези на Капитана в Кръга, за който е избран Бонуса.</td>
										<td>9 лв</td>
									</tr>
									</tbody>
								</table>
								<p><strong>Пакетната цена</strong>&nbsp;за закупуване на описаните по-горе три Бонуса е&nbsp;20 лв</p>
								<h3>Краен срок за промени в отбора преди Кръг</h3>
								<p><strong>Всички промени в отбора ти&nbsp;</strong>(стартови 11, трансфери, промени в капитана,
									използване на Бонуси)&nbsp;трябва да бъдат направени&nbsp;<strong>преди крайния срок</strong>&nbsp;на
									стартиране на Кръга, за да влязат в сила за мачовете.</p>
								<p>Крайните срокове подлежат на промяна и ще бъдат&nbsp;<strong>1</strong>&nbsp;час преди началното
									време за първия мач на Кръга, както следва:</p>
								<table class="points" border="0">
									<tbody>
									<tr>
										<th>КРЪГ</th>
										<th>КРАЕН СРОК</th>
									</tr>
									<tr>
										<td>Кръг 1</td>
										<td>20/07/2018</td>
									</tr>
									<tr>
										<td>Кръг 2</td>
										<td>27/07/2018</td>
									</tr>
									<tr>
										<td>Кръг 3</td>
										<td>29/07/2018</td>
									</tr>
									<tr>
										<td>Кръг 4</td>
										<td>03/08/2018</td>
									</tr>
									<tr>
										<td>Кръг 5</td>
										<td>10/08/2018</td>
									</tr>
									<tr>
										<td>Кръг 6</td>
										<td>18/08/2018</td>
									</tr>
									<tr>
										<td>Кръг 7</td>
										<td>01/09/2018</td>
									</tr>
									<tr>
										<td>Кръг 8</td>
										<td>15/09/2018</td>
									</tr>
									<tr>
										<td>Кръг 9</td>
										<td>22/09/2018</td>
									</tr>
									<tr>
										<td>Кръг 10</td>
										<td>29/09/2018</td>
									</tr>
									<tr>
										<td>Кръг 11</td>
										<td>06/10/2018</td>
									</tr>
									<tr>
										<td>Кръг 12</td>
										<td>20/10/2018</td>
									</tr>
									<tr>
										<td>Кръг 13</td>
										<td>27/10/2018</td>
									</tr>
									<tr>
										<td>Кръг 14</td>
										<td>03/11/2018</td>
									</tr>
									<tr>
										<td>Кръг 15</td>
										<td>10/11/2018</td>
									</tr>
									<tr>
										<td>Кръг 16</td>
										<td>24/11/2018</td>
									</tr>
									<tr>
										<td>Кръг 17</td>
										<td>01/12/2018</td>
									</tr>
									<tr>
										<td>Кръг 18</td>
										<td>08/12/2018</td>
									</tr>
									<tr>
										<td>Кръг 19</td>
										<td>15/12/2018</td>
									</tr>
									<tr>
										<td>Кръг 20</td>
										<td>16/02/2019</td>
									</tr>
									<tr>
										<td>Кръг 21</td>
										<td>23/02/2019</td>
									</tr>
									<tr>
										<td>Кръг 22</td>
										<td>02/03/2019</td>
									</tr>
									<tr>
										<td>Кръг 23</td>
										<td>09/03/2019</td>
									</tr>
									<tr>
										<td>Кръг 24</td>
										<td>16/03/2019</td>
									</tr>
									<tr>
										<td>Кръг 25</td>
										<td>30/03/2019</td>
									</tr>
									<tr>
										<td>Кръг 26</td>
										<td>06/04/2019</td>
									</tr>
									</tbody>
								</table>
								<h3>Лиги</h3>
								<p>След като изградиш отбора си, можеш да се присъединиш към вече съществуващи лиги или да създадеш
									собствена лига.</p>
								<p><strong><u>Частни лиги</u></strong></p>
								<p>Частните лиги са сърцето и душата на играта, където се състезаваш срещу приятелите си. Просто
									създаваш лига и след това изпращаш уникалния код, за да позволиш на приятелите си да се
									присъединят.</p>
								<p>Можеш да се състезаваш в до 15 частни лиги. В една лига могат да участват неограничен брой
									отбори.</p>
								<p><strong><u>Публични (общи) лиги</u></strong></p>
								<p>Автоматично влизаш в следните общи лиги:</p>
								<ul>
									<li>Общата лига, включваща всички регистрирани отбори;</li>
									<li>Лига за привърженици на любимия ти отбор в Първа Професионална Лига;</li>
									<li>Лига за мениджъри, които стартират от същия Кръг като теб.</li>
								</ul>
								<h3>Резултати и класиране</h3>
								<p>В лига&nbsp;<strong>с Генералния Резултат&nbsp;</strong>отборите се класират въз основа на общия брой
									точки в играта.</p>
								<p>В случай на равенство на точките между два или повече отбора, се вземат предвид точките от последния
									Кръг</p>
								<h3>Цени на играчите</h3>
								<p>Цените на играчите не се променят, докато сезона не започне.<br/>Цените на играчите се променят през
									сезона в зависимост от популярността на играча на трансферния пазар и представянето им в реалните
									мачове.</p>
								<p><span>Цените на играчите се променят със следния алгоритъм:</span><br/><strong>(+)
									повишаване:</strong> цената на играч може да нарастне с максимум +50 000 лв.</p>
								<ul>
									<li>при закупуване на даден играч от 15% до 30% от всички играчи в играта: +10 000 лв. (над
										първоначалната му цена);
									</li>
									<li>при закупуване на даден играч от 30% до 40% от всички играчи в играта: +20 000 лв. (над
										първоначалната му цена);
									</li>
									<li>при закупуване на даден играч от 40% до 50% от всички играчи в играта: +30 000 лв. (над
										първоначалната му цена);
									</li>
									<li>при закупуване на даден играч над 50% от всички играчи в играта: +50 000 лв. (над първоначалната
										му цена).
									</li>
								</ul>
								<p><strong>(-) намаляване:</strong> цената на играч може да спадне с максимум -50 000 лв.</p>
								<ul>
									<li>ако даден играч не играе в 5 поредни срещи, в които е включен в групата (това значи, че може да е
										резерва): -10 000 лв. Цената може да падне до максимум -50 000 лв. (това значи, че ако не играе в 25
										поредни мача, а остане резерва при 26-тия, цената му остава перманентно с 50 000 лв. под
										първоначалната);
									</li>
									<li>ако даден играч получи картон (жълт или червен) в 5 поредни мача, в които играе: -10 000 лв.;</li>
									<li>ако даден играч не е купен от до 5% от участниците в играта в продължение на 5 поредни кръга: -10
										000 лв.;
									</li>
									<li>ако даден играч не е купен от никого в продължение на 5 поредни кръга: -20 000 лв;</li>
								</ul>
								<p>Цените на играчите започват да се повишават или намаляват след 1-вия Кръг на шампионата.</p>
							</div>
						</div>
					</div>
				</div>
			</div>
		</section>
	);
};