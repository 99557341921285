import React from "react";
import {
    StyleSheet,
    TouchableOpacity,
    Text,
    View,
    Image
} from "react-native";
import PlayerView from '../../Common/components/PlayerView';
import PopupDialog from '../../Common/components/PopupDialog';
import hand from '../../assets/hand.svg.png';

import { connect } from 'react-redux';
import { getPlayerShortInfo } from '../actions/players';
import { getPlayerPointsColor } from "../../Common/utils/utils.js";

class PlayerPopup extends React.Component {
    showFullProfile = () => {
        this.props.navigation.navigate('PlayerProfile', {
            playerId: this.props.playerId
        });
    }

    componentDidMount() {
        if (this.props.playerId) {
            this.props.getPlayerShortInfo(this.props.playerId);
        }
    }

    render() {
        if (!this.props.playerId || !this.props.playerInfo) return null;

        return (
            <PopupDialog isVisible={true} onDismiss={this.props.onDismiss}>
                <View style={styles.headerContainer}>
                    <Image source={{ uri: this.props.playerInfo.image }} style={styles.playerImage} />
                    <View style={styles.playerInfoContainer}>
                        <Text style={styles.name}>{this.props.playerInfo.name}</Text>
                        <Text>{this.props.playerInfo.position} / {this.props.playerInfo.team}</Text>
                        <Text style={styles.points}>{this.props.playerInfo.totalPoints} Точки </Text>
                        <TouchableOpacity onPress={this.showFullProfile}
                            style={styles.fullProfileButton}>
                            <Text style={styles.profileButtonText}>Пълен Профил</Text>
                        </TouchableOpacity>
                    </View>
                </View>
                <View style={styles.programInfo}>
                    <View style={styles.roundsContainer}>
                        <View style={[styles.roundRow, { borderBottomWidth: 1 }]}>
                            {this.props.playerInfo.previousMatches.length > 1 &&
                                <View style={styles.roundDetails}>
                                    <Text>{this.props.playerInfo.previousMatches[1].roundName}</Text>
                                    <View style={styles.teamInfo}>
                                        <Text>{this.props.playerInfo.previousMatches[1].teamShort}</Text>
                                        <Image style={styles.teamIcon} source={{ uri: this.props.playerInfo.previousMatches[1].teamLogo }} />
                                    </View>
                                    <View style={[styles.pointsContainer, getPlayerPointsColor(this.props.playerInfo.previousMatches[1].points, true)]}>
                                        <Text>{this.props.playerInfo.previousMatches[1].points} т.</Text>
                                    </View>
                                </View>}
                            {this.props.playerInfo.nextMatches.length > 0 &&
                                <View style={styles.roundDetails}>
                                    <Text>{this.props.playerInfo.nextMatches[0].roundName}</Text>
                                    <View style={styles.teamInfo}>
                                        <Text>{this.props.playerInfo.nextMatches[0].teamShort}</Text>
                                        <Image style={styles.teamIcon} source={{ uri: this.props.playerInfo.nextMatches[0].teamLogo }} />
                                        {//<Text>{this.props.playerInfo.nextMatches[0].isHost ? "(Д)" : '(Г)'}</Text>
                                        }
                                    </View>
                                    <Text>{this.props.playerInfo.nextMatches[0].date}</Text>
                                </View>}
                        </View>
                        <View style={styles.roundRow}>
                            {this.props.playerInfo.previousMatches.length > 0 &&
                                <View style={styles.roundDetails}>
                                    <Text>{this.props.playerInfo.previousMatches[0].roundName}</Text>
                                    <View style={styles.teamInfo}>
                                        <Text>{this.props.playerInfo.previousMatches[0].teamShort}</Text>
                                        <Image style={styles.teamIcon} source={{ uri: this.props.playerInfo.previousMatches[0].teamLogo }} />
                                    </View>
                                    <View style={[styles.pointsContainer, getPlayerPointsColor(this.props.playerInfo.previousMatches[0].points, true)]}>
                                        <Text>{this.props.playerInfo.previousMatches[0].points} т.</Text>
                                    </View>
                                </View>}
                            {this.props.playerInfo.nextMatches.length > 1 &&
                                <View style={styles.roundDetails}>
                                    <Text>{this.props.playerInfo.nextMatches[1].roundName}</Text>
                                    <View style={styles.teamInfo}>
                                        <Text>{this.props.playerInfo.nextMatches[1].teamShort}</Text>
                                        <Image style={styles.teamIcon} source={{ uri: this.props.playerInfo.nextMatches[1].teamLogo }} />
                                        {//<Text>{this.props.playerInfo.nextMatches[1].isHost ? "(Д)" : '(Г)'}</Text>
                                        }
                                    </View>
                                    <Text>{this.props.playerInfo.nextMatches[1].date}</Text>
                                </View>}
                        </View>
                    </View>
                    {this.props.onConfirm && <TouchableOpacity
                        style={styles.buttonContainer}
                        onPress={this.props.onConfirm}>
                        <Text style={styles.buttonText}>
                            {this.props.buttonText}
                        </Text>
                        <Image source={hand} style={styles.icon} />
                    </TouchableOpacity>}
                </View>
            </PopupDialog>
        );
    }
};


const mapDispatchToProps = (dispatch, ownProps) => {
    return {
        getPlayerShortInfo: playerId => dispatch(getPlayerShortInfo(playerId))
    }
}

const mapStateToProps = (state, ownProps) => {
    return {
        playerInfo: state.playerInfo.playerShortInfo,
    }
}


export default connect(mapStateToProps, mapDispatchToProps)(PlayerPopup);

const styles = StyleSheet.create({
    playerImage: {
        height: '5rem',
        width: '5rem',
        borderRadius: 10
    },
    headerContainer: {
        flexDirection: 'row',
        width: '100%',
        padding: 10,
    },
    name: {
        fontWeight: 'bold',
        fontSize: '0.8rem',
        marginBottom: 5
    },
    points: {
        fontWeight: 'bold',
        color: '#2053A4',
        marginTop: 5,
        marginBottom: 2,
        fontSize: '0.8rem',
    },
    fullProfileButton: {
        alignItems: 'center',
        justifyContent: 'center',
        backgroundColor: '#2053A4',
        paddingVertical: 5,
        borderRadius: 5
    },
    profileButtonText: {
        color: 'white',
        fontSize: 12
    },
    playerInfoContainer: {
        marginLeft: 10
    },
    buttonContainer: {
        width: '100%',
        height: 50,
        paddingHorizontal: 5,
        alignItems: 'center',
        backgroundColor: 'black',
        justifyContent: 'center',
        borderBottomLeftRadius: 10,
        borderBottomRightRadius: 10,
        flexDirection: 'row'
    },
    buttonText: {
        textAlign: 'center',
        color: 'white',
        fontSize: 15,
        fontWeight: 'bold'
    },
    icon: {
        width: 30,
        height: 30
    },
    captainContainer: {
        flexDirection: 'row',
        backgroundColor: '#D7DFF7',
        alignItems: 'center',
        justifyContent: 'space-between',
        paddingHorizontal: 10,
        paddingVertical: 5
    },
    radioGroup: {
        flexDirection: 'row',
        alignItems: 'center'
    },
    radio: {
        borderWidth: 2,
        borderColor: '#838384',
        borderRadius: 12,
        backgroundColor: 'white',
        width: 25,
        height: 25,
        marginHorizontal: 4,
        alignItems: 'center',
        justifyContent: 'center'
    },
    radioTitle: {
        fontWeight: 'bold',
        fontSize: '0.75rem'
    },
    radioSelection: {
        backgroundColor: '#F6C146',
        width: 15,
        height: 15,
        borderRadius: 10
    },
    roundsContainer: {
        backgroundColor: '#E5EAF4',
        width: '100%',
        padding: 10
    },
    roundRow: {
        flexDirection: 'row',
        paddingVertical: 6
    },
    roundDetails: {
        flexDirection: 'row',
        width: '50%',
        alignItems: 'center',
        justifyContent: 'space-between'
    },
    teamInfo: {
        flexDirection: 'row'
    },
    teamIcon: {
        width: 15,
        height: 15,
        marginHorizontal: 5,
        marginVertical: 3
    },
    pointsContainer: {
        backgroundColor: '#E4A647',
        alignItems: 'center',
        justifyContent: 'center',
        paddingHorizontal: 4,
        paddingVertical: 2,
        borderRadius: 3,
        borderWidth: 1,
        borderColor: 'white',
        marginRight: 10
    },
    iconStyle: {
        width: '100%',
        height: 90
    }
});
